import React, { useEffect } from 'react';
import { Input, Modal, Table, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	handleSelectedApprovalId,
	rejectDisbursement,
} from '../../features/approve/approveSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';
import ApprovalDetailsDrawer from '../../pages/approval/drawer/ApprovalDetailsDrawer';

const ApprovalPayTable = ({ data, show, handleFetch }) => {
	const [modal, contextHolder] = Modal.useModal();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { approveLoading } = useSelector((state) => state.approve);

	const [rejectReason, setrejectReason] = useState('');

	const location = window.location.href;
	const pathname = location.substring(location.lastIndexOf('/') + 1);

	function handleConfirmApproval(approvalObj) {
		dispatch(handleSelectedApprovalId(approvalObj));
	}

	function handleRejectPayment(item) {
		confirm(item);
	}

	async function handleReject(rejectId) {
		console.log(rejectId, rejectReason);
		const usrObj = {
			usrId: user?.usrId,
			blkuRejectedReason: rejectReason,
			blkuId: rejectId,
			blkuApprovalLevel:
				pathname === 'approval-one' ? 0 : pathname === 'approval-two' ? 1 : 2,
		};
		const res = await dispatch(rejectDisbursement(usrObj));

		if (res?.payload?.success) {
			await handleFetch()
			message.success(res?.payload?.messages?.message);
		} else {
			message.info(res?.payload?.messages?.message);
		}
	}

	const confirm = (item) => {
		modal.confirm({
			title: 'Confirm Rejection',
			icon: <ExclamationCircleOutlined />,
			content: (
				<>
					<div>
						<p>
							Would you like to reject payment transfer of{' '}
							<span className='!font-bold'>KES {item?.blkuTotalamount}</span>{' '}
							from wallet account {item?.bkluWalCode}?
						</p>

						<Input
							placeholder='Enter rejection reason (Optional)'
							className='w-full my-3 !placeholder-[#000]'
							type='text'
							name='blkuRejectedReason'
							onChange={(e) => setrejectReason(e.target.value)}
						/>
					</div>
				</>
			),
			okText: 'Confirm',
			cancelText: 'Cancel',
			confirmLoading: approveLoading,
			onOk: () => {
				handleReject(item?.blkuId);
			},
			okButtonProps: {
				style: {
					background: '#10101E',
					color: '#fff',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
			cancelButtonProps: {
				style: {
					background: '#D3D3D9',
					color: '#212143',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
		});
	};

	const columns = [
		{
			title: 'Date',
			key: 'blkuCreatedDate',
			dataIndex: 'blkuCreatedDate',
			render: (item) => (
				<span>{moment(item).format('MMM DD YYYY, h:mm A')}</span>
			),
		},
		{
			title: 'Source Account',
			key: 'bkluWalCode',
			dataIndex: 'bkluWalCode',
		},
		{
			title: 'Reason for transfer',
			dataIndex: 'blkuFileDescription',
			key: 'blkuFileDescription',
		},
		{
			title: 'Total Amount (KES)',
			dataIndex: 'blkuTotalamount',
			key: 'blkuTotalamount',
		},
		{
			title: 'Payment Mode',
			dataIndex: 'blkuPaymentMode',
			key: 'blkuPaymentMode',
			render: (item) => <span>{item ? item : 'BANK'}</span>,
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (item) => (
				<>
					{show ? (
						<>
							<button
								onClick={() => handleRejectPayment(item)}
								className='p-1'
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
								}}>
								Reject
							</button>
							<button
								onClick={() => handleConfirmApproval(item)}
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								className='ml-2 p-1'>
								Approve
							</button>
						</>
					) : null}
				</>
			),
		},
	];

	useEffect(() => {}, [rejectReason, data]);

	return (
		<>
			<div className='dash-inner-page max-w-full w-full overflow-x-auto'>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={data}
					loading={approveLoading}
					sortDirections={['descend']}
				/>
			</div>

			{contextHolder}

			<ApprovalDetailsDrawer />
		</>
	);
};

export default ApprovalPayTable;
