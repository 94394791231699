import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import DashChart from "../dashboard/DashChart";
import DashRecentTransactions from "../dashboard/DashRecentTransactions";
import AccountTable from "../../components/global/AccountTable";

export default function SubAccount() {

  return (
    <>
      <BreadCrumb
        root={"Accounts"}
        current={"Disbursement Account"}
        url={"accounts/sub-account"}
      />

      <div className="flex items-center justify-between mt-3">
        <h3 className="table-heads">Disbursement Account</h3>
      </div>

      <div className="grid grid-cols-4 mt-10">
        <div className="!border-l-8 border-l-[#00B3FF] border-2 border-[#E6E9EE] w-[237px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]">
          <span className="text-[#AFAFAF] text-[16px]">Total Balance</span>
          <span className="text-[#15223C] text-[20px] font-[600]">
            KES 3,000,000
          </span>
        </div>

        <div className="!border-l-8 border-l-[#FA8B3A] border-2 border-[#E6E9EE] w-[237px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]">
          <span className="text-[#AFAFAF] text-[16px]">Outgoing</span>
          <span className="text-[#15223C] text-[20px] font-[600]">
            KES 88,78387
          </span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <DashChart />
        </div>

        <DashRecentTransactions />
      </div>

      <div className="mt-10 w-[50%]">
        <AccountTable />
      </div>
    </>
  );
}
