import { React, useEffect, useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo from '../../assets/web.svg';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { approveCleanUp } from '../../features/approve/approveSlice';
import { logout } from '../../features/auth/authSlice';
import { branchCleanUp } from '../../features/branch/branchSlice';
import { collectionCleanUp } from '../../features/collections/collectionSlice';
import { globalCleanUp } from '../../features/global/globalSlice';
import { navCleanUp } from '../../features/nav/navSlice';
import { orgCleanUp } from '../../features/organization/organizationSlice';
import { paymentCleanUp } from '../../features/payment/paymentSlice';
import { receiptCleanUp } from '../../features/receipt/receiptSlice';
import { reportCleanUp } from '../../features/reports/reportsSlice';
import { walletCleanUp } from '../../features/wallet/walletSlice';

const LandingHeader = ({
	handleAbout,
	handleFeature,
	handleWhy,
	toggleMargin,
}) => {
	const dispatch = useDispatch();
	const [scroll, setScroll] = useState(false);
	const [hide, sethide] = useState(true);

	function handleToggle() {
		sethide(!hide);
		toggleMargin();
	}

	async function handleLogin(e) {
		e.preventDefault();
		sessionStorage.clear();
		localStorage.clear();
		await dispatch(approveCleanUp());
		await dispatch(logout());
		await dispatch(branchCleanUp());
		await dispatch(collectionCleanUp());
		await dispatch(globalCleanUp());
		await dispatch(navCleanUp());
		await dispatch(orgCleanUp());
		await dispatch(paymentCleanUp());
		await dispatch(receiptCleanUp());
		await dispatch(reportCleanUp());
		await dispatch(walletCleanUp());
		await setTimeout(() => {
			window.location.href = './login';
		}, 500);
	}

	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<Navbar
			className={`lg:mx-[120px] landingHeader ${scroll ? 'bg-light' : ''}`}
			collapseOnSelect
			expand='lg'
			fixed='top'
		>
			<Container fluid>
				<div className='flex justify-between items-center w-full'>
					<Navbar.Brand href='/home'>
						<img src={Logo} alt='header-logo' width='150' height='60' />
					</Navbar.Brand>
					<Navbar.Toggle
						onClick={() => handleToggle()}
						aria-controls='responsive-navbar-nav'
					/>
				</div>
				{hide && window.innerWidth >= 300 && window.innerWidth <= 991 ? null : (
					<div className='flex justify-between items-center w-full'>
						<div id='responsive-navbar-'>
							<Nav className='ms-auto lg:align-items-center mr-0 lg:mr-[130px]'>
								<Nav.Link
									className='nav-item w-[116px]'
									onClick={() => handleAbout()}
								>
									About us
								</Nav.Link>
								<Nav.Link
									className='nav-item !mx-0 lg:mx-[40px]'
									onClick={() => handleFeature()}
								>
									Features
								</Nav.Link>

								<Nav.Link
									className='nav-item w-[116px]'
									onClick={() => handleWhy()}
								>
									Why us
								</Nav.Link>
							</Nav>
						</div>
						<div id='responsive-navbar-nav'>
							<Nav className='ms-auto align-items-center'>
								<Nav.Link as='div'>
									<Link
										to='/sign-up'
										className='btn border-1 border-[#212529] rounded-[6px] px-3'
									>
										Join now
									</Link>
								</Nav.Link>
								<Nav.Link as='div'>
									<a
										href='/login'
										// onClick={(e) => handleLogin(e)}
										className='btn btn-dark text-white px-4'
									>
										Login
									</a>
								</Nav.Link>
							</Nav>
						</div>
					</div>
				)}
			</Container>
		</Navbar>
	);
};
export default LandingHeader;
