import { React, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Radio } from 'antd';
import { Link, useNavigate } from 'react-router-dom';


const OrgTable = ({ data }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState(1);

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNavigate = (branch) => {
		navigate(`/setup/${branch}/branches`);
	};

	return (
		<div className='dash-inner-page mt-7'>
			<section>
				<Table responsive striped className='commonTable'>
					<thead>
						<tr>
							<th>Name</th>
							<th>Location</th>
							<th>Email</th>
							<th>Phone No.</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((item) => {
							return (
								<>
									<tr key={item}>
										<td>
											<div className='flex items-center space-x-4'>
												<div className='flex-shrink-0'>
													<img
														className='w-[45px] bg-red h-[37px] rounded-full object-contain'
														src='https://static.thenounproject.com/png/3445380-200.png'
														alt='Neil'
													/>
												</div>
												<div className=''>
													<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
														{item?.orgName ? item?.orgName : "N/A"}
													</p>
												</div>
											</div>
										</td>
										<td>{item?.orgCounty ? item?.orgCounty : "N/A"}</td>
										<td>{item?.orgEmail ? item?.orgEmail : "N/A"}</td>
										<td>{item?.orgMobile ? item?.orgMobile : "N/A"}</td>
										<td>
											<div>
												<IconButton
													id='long-button'
													onClick={() => handleNavigate(item?.orgName)}
												>
													<VisibilityIcon className='text-[#000] -mt-1' />
												</IconButton>
											</div>
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</Table>

				{/* <div className='flex justify-end'>
					<Pagination defaultCurrent={6} total={data?.length} />
				</div> */}
			</section>
		</div>
	);
};
export default OrgTable;
