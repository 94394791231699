import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	receiptLoading: false,
	receipts: [],
};

export const fetchReceipts = createAsyncThunk(
	'receiptSlice/fetchReceipts',
	(data) => {
		return axios
			.get(`${url}/web/fetchReceipts.action?usrId=${data?.usrId}`, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data);
	},
);

export const receiptSlice = createSlice({
	name: 'receipt',
	initialState,
	reducers: {
		receiptCleanUp: (state) => {
			state.receiptLoading = false;
			state.receipts = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchReceipts.pending, (state) => {
				state.receiptLoading = true;
			})
			.addCase(fetchReceipts.fulfilled, (state, action) => {
				state.receiptLoading = false;
				state.receipts = action?.payload?.data?.result;
			})
			.addCase(fetchReceipts.rejected, (state) => {
				state.receiptLoading = false;
			});
	},
});

export default receiptSlice.reducer;
export const {receiptCleanUp} = receiptSlice.actions;
