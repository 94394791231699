import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	walletLoading: false,
	wallets: [],
	disbursementWallets: [],
	collectionWallets: [],
	branchWallets: [],
	activeWallet: {},
};

export const fetchWallets = createAsyncThunk(
	'walletSlice/fetchWallets',
	(usrObj) => {
		return axios
			.get(`${url}/web/fetch_wallets.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}`)
			.then((res) => res.data);
	},
);

export const saveWallet = createAsyncThunk(
	'walletSlice/saveWallet',
	(walObj) => {
		return axios
			.post(`${url}/web/saveWallet.action`, walObj)
			.then((res) => res.data);
	},
);

export const fetchBranchWallets = createAsyncThunk(
	'walletSlice/fetchBranchWallets',
	(walObj) => {
		return axios
			.get(
				`${url}/web/fetch_branch_wallets.action?usrId=${walObj?.usrId}&brnId=${walObj?.brnId}`,
			)
			.then((res) => res.data);
	},
);

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setActiveWallet: (state, action) => {
			console.log(action.payload);
			state.activeWallet = action.payload;
		},
		removeActiveWallet: (state) => {
			state.activeWallet = {};
		},
		walletCleanUp: (state) => {
			state.walletLoading = false;
			state.wallets = [];
			state.disbursementWallets = [];
			state.collectionWallets = [];
			state.branchWallets = [];
			state.activeWallet = {};
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchWallets.pending, (state) => {
				state.walletLoading = true;
			})
			.addCase(fetchWallets.fulfilled, (state, action) => {
				state.walletLoading = false;
				if (action?.payload?.success) {
					state.wallets = action.payload?.data?.result;
					state.disbursementWallets = action.payload?.data?.result?.filter(
						(item) => item.walType === 'DISBURSEMENT',
					);
					state.collectionWallets = action.payload?.data?.result?.filter(
						(item) => item.walType === 'COLLECTION',
					);
				} else {
					state.wallets = [];
					state.disbursementWallets = [];
					state.collectionWallets = [];
				}
			})
			.addCase(fetchWallets.rejected, (state) => {
				state.walletLoading = false;
			})

			.addCase(saveWallet.pending, (state) => {
				state.walletLoading = true;
			})
			.addCase(saveWallet.fulfilled, (state, action) => {
				state.walletLoading = false;
			})
			.addCase(saveWallet.rejected, (state) => {
				state.walletLoading = false;
			})

			.addCase(fetchBranchWallets.pending, (state) => {
				state.walletLoading = true;
			})
			.addCase(fetchBranchWallets.fulfilled, (state, action) => {
				state.walletLoading = false;
				if (action?.payload?.success) {
					state.branchWallets = action.payload?.data?.result;
				} else {
					state.branchWallets = [];
				}
			})
			.addCase(fetchBranchWallets.rejected, (state) => {
				state.walletLoading = false;
			});
	},
});

export default walletSlice.reducer;
export const { setActiveWallet, removeActiveWallet, walletCleanUp } = walletSlice.actions;
