import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import PayBulkModal from "./modals/PayBulkModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBulkUpload } from "../../features/payment/paymentSlice";
import BulkUploadTable from "../../components/global/BulkUploadTable";
import BulkPayDetailsDrawer from "./drawer/BulkPayDetailsDrawer";

export default function BulkPayment() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { bulkUploads } = useSelector((state) => state.payment);
  const { wallets } = useSelector((state) => state.wallet);
  const { globalBrnId } = useSelector((state) => state.global);

  const usrObj = {
    usrId: user?.usrId,
    blkuMethod: "BULK",
    brnId: globalBrnId ?? user?.brnId,
  };

  const [open, setopen] = useState(false);

  function handleOpenOne() {
    setopen(true);
  }

  function closeModalOne() {
    setopen(false);
  }

  async function prefetchData() {
    await dispatch(fetchBulkUpload(usrObj));
  }

  useEffect(() => {
    prefetchData();
  }, [globalBrnId, user]);

  useEffect(() => {}, [wallets, bulkUploads]);



  useEffect(() => {}, [user]);

  return (
    <>
      <BreadCrumb
        root={"Payments"}
        current={"Bulk Payments"}
        url={"payments/bulk-payments"}
      />

      <div className="flex items-center justify-between mt-3">
        <h3 className="table-heads">Bulk Payments</h3>
        <button
          onClick={handleOpenOne}
          className="bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]"
          type="button"
        >
          Initiate Payment
        </button>
      </div>

      <div className="mt-7 w-full">
        <h3 className="table-sub-heads my-3">Activities</h3>

        <BulkUploadTable data={bulkUploads} />
      </div>

      <div className="my-10 w-full">
        <BulkPayDetailsDrawer />
      </div>

      <PayBulkModal
        prefetchData={prefetchData}
        open={open}
        closeModalOne={closeModalOne}
      />
    </>
  );
}
