import { Dropdown } from 'antd';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import PayFundModalOne from './modals/PayFundModalOne';
import { useEffect, useState } from 'react';
import PayFundModalTwo from './modals/PayFundModalTwo';
import PayFundModalThree from './modals/PayFundModalThree';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchBanks,
	fetchBulkPaymentsUploads,
} from '../../features/payment/paymentSlice';
import DirectTable from './tables/DirectTable';

export default function DirectPay() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { wallets } = useSelector((state) => state.wallet);
	const { directPays } = useSelector((state) => state.payment);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		blkuMethod: 'DIRECT',
		brnId: globalBrnId ?? user?.brnId,
	};

	async function prefetchData() {
		await dispatch(fetchBulkPaymentsUploads(usrObj));
	}

	const [open, setopen] = useState(false);
	const [open2, setopen2] = useState(false);
	const [open3, setopen3] = useState(false);

	async function handleFetchbanks() {
		await dispatch(fetchBanks(usrObj));
	}

	function handleOpenOne(e) {
		e.preventDefault();
		setopen(true);
	}

	function handleOpenThree(e) {
		e.preventDefault();
		setopen3(true);
	}

	function closeModalOne() {
		setopen(false);
		setopen2(false);
		setopen3(false);
	}

	const items = [
		{
			label: (
				<a onClick={(e) => handleOpenOne(e)} href='#to'>
					Bank Payment
				</a>
			),
			key: '0',
		},
		{
			label: (
				<a onClick={(e) => handleOpenThree(e)} href='#to'>
					Mobile Payment
				</a>
			),
			key: '2',
		},
	];

	useEffect(() => {
		handleFetchbanks();
		prefetchData();
	}, [globalBrnId, user]);

	useEffect(() => {}, [wallets, directPays]);


	return (
		<>
			<BreadCrumb
				root={'Payments'}
				current={'Direct Payments'}
				url={'payments/direct-payments'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Direct Payments</h3>
				<Dropdown
					menu={{
						items,
					}}
					trigger={['click']}>
					<button
						className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
						type='button'>
						Initiate Payment
					</button>
				</Dropdown>
			</div>

			<div className='mt-7 mb-[5rem] w-full'>
				<h3 className='table-sub-heads mt-3'>Activities</h3>
				<DirectTable data={directPays} />
			</div>

			<PayFundModalOne
				prefetchData={prefetchData}
				open={open}
				closeModalOne={closeModalOne}
			/>
			{/* <PayFundModalTwo
				prefetchData={prefetchData}
				open2={open2}
				closeModalOne={closeModalOne}
			/> */}
			<PayFundModalThree
				prefetchData={prefetchData}
				open3={open3}
				closeModalOne={closeModalOne}
			/>
		</>
	);
}
