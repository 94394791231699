import * as React from 'react';
import { useEffect } from 'react';
import { Navbar, Container, NavDropdown, Nav } from 'react-bootstrap';
import Logo from '../../assets/web.svg';
import DeveloperIcon from '../../assets/img/sellicon.svg';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge } from '@mui/material';
import { approveCleanUp } from '../../features/approve/approveSlice';
import {
	branchCleanUp,
	fetchBranches,
} from '../../features/branch/branchSlice';
import { collectionCleanUp } from '../../features/collections/collectionSlice';
import {
	globalCleanUp,
	setGlobalBrnId,
} from '../../features/global/globalSlice';
import { navCleanUp } from '../../features/nav/navSlice';
import { orgCleanUp } from '../../features/organization/organizationSlice';
import { paymentCleanUp } from '../../features/payment/paymentSlice';
import { receiptCleanUp } from '../../features/receipt/receiptSlice';
import { reportCleanUp } from '../../features/reports/reportsSlice';
import { walletCleanUp } from '../../features/wallet/walletSlice';
import { Select } from 'antd';

const DashboardHeader = ({ isAccountType }) => {
	const { user } = useSelector((state) => state.auth);
	const { branches } = useSelector((state) => state.branch);
	const { globalBrnId } = useSelector((state) => state.global);

	const dispatch = useDispatch();

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
	};

	async function handleGlobalBrnIdChange(id) {
		await dispatch(setGlobalBrnId(id));
	}

	async function handleFetch() {
		await dispatch(fetchBranches(usrObj));
	}

	async function logUserOut(e) {
		e.preventDefault();
		await dispatch(approveCleanUp());
		await dispatch(branchCleanUp());
		await dispatch(collectionCleanUp());
		await dispatch(globalCleanUp());
		await dispatch(orgCleanUp());
		await dispatch(paymentCleanUp());
		await dispatch(receiptCleanUp());
		await dispatch(reportCleanUp());
		await dispatch(walletCleanUp());
		// await dispatch(logout());
		await dispatch(navCleanUp());
		await sessionStorage.clear();
		await localStorage.clear();
		window.location.href = './logout';
	}

	useEffect(() => {
		if (user?.usrId) {
			handleFetch();
		}
	}, [user]);

	useEffect(() => {}, [branches]);

	return (
		<Navbar
			style={{
				zIndex: 1,
			}}
			className='header'
			collapseOnSelect
			fixed='top'>
			<Container>
				<Navbar.Brand href='/dashboard'>
					<img src={Logo} alt='header-logo' width='140' height='50' />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />

				<div id='responsive-navbar-nav'>
					<Nav className='ms-auto align-items-center'>
						{isAccountType === 'selling' && (
							<Nav.Link className='me-4 me-md-5  d-lg-block' href='./Developer'>
								<img
									className='me-2'
									src={DeveloperIcon}
									alt='logo'
									width='32'
									height='32'
								/>
								Developer
							</Nav.Link>
						)}
						{/* 
						<Nav.Link className='flex items-center mr-3'>
							<LanguageIcon
								className='!text-[25px] !text-[#212143]'
								color='action'
							/>
							<span className='text-[#212143] ml-1'>EN</span>
						</Nav.Link> */}

						{Number(user?.usrRoleId) === 0 && (
							<Select
								variant='borderless'
								value={globalBrnId ?? user?.usrBrnId}
								defaultValue='Select Branch'
								style={{
									width: '350px',
									height: '44px',
								}}
								onChange={handleGlobalBrnIdChange}
								options={branches?.map((item) => {
									return {
										label: (
											<span>
												Branch:{' '}
												<span className='font-semibold'>{item?.brnName}</span>
											</span>
										),
										value: item?.brnId,
									};
								})}
							/>
						)}

						<Nav.Link className='mr-3'>
							<Badge
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								variant='dot'
								color='primary'
								overlap='circular'>
								<NotificationsNoneOutlinedIcon
									className='!text-[25px] !text-[#212143]'
									color='action'
								/>
							</Badge>
						</Nav.Link>

						<NavDropdown
							className='userDropDown'
							title={
								<div className='d-flex'>
									<div className='flex-shrink-0 imgDiv avatar avatar--sm'>
										<img
											className='w-9 h-9 rounded-full'
											src='https://www.computerhope.com/jargon/g/guest-user.png'
											alt='avatar'
										/>
									</div>
									<div className='flex-grow-1 align-self-center ms-3'>
										<div className='d-flex align-items-center'>
											<p className='hidden md:flex mb-0 fw-md'>
												{user?.usrFullNames}
											</p>
										</div>
									</div>
								</div>
							}>
							<div className='flex flex-col py-1 px-2'>
								<Link to='/profile'>Profile</Link>

								<a
									className='mt-1'
									onClick={(e) => logUserOut(e)}
									href='/logout'>
									Logout
								</a>
							</div>
						</NavDropdown>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};
export default DashboardHeader;
