import { Tabs } from 'antd';
import ApprovalTable from '../../components/global/ApproveTable';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchPendingColApp, fetchPendingDisbApp } from '../../features/approve/approveSlice';
import { useEffect } from 'react';

export default function Approvals() {
	const dispatch = useDispatch();
	const { colapprovals, disbapprovals } = useSelector((state) => state.approve);
	const { user } = useSelector((state) => state.auth);
	// const rejected = approvalReceipts?.filter((item) => item.rcptapStatus === 'REJECTED');

	async function handleFetchApprovalReceipts() {
		const usrObj = {
			usrId: user?.usrId,
		};
		await dispatch(fetchPendingDisbApp(usrObj));
		await dispatch(fetchPendingColApp(usrObj));
	}

	useEffect(() => {}, [colapprovals, disbapprovals]);

	useEffect(() => {
		handleFetchApprovalReceipts();
	}, []);

	return (
		<>
			<BreadCrumb
				root={'Approvals'}
				current={'Approval Dashboard'}
				url={'approvals'}
			/>

			<div className='flex items-center justify-between mt-3 mb-5'>
				<h3 className='table-heads'>Approval Dashboard</h3>
			</div>

			<Tabs
				defaultActiveKey='1'
				type='card'
				size={'Middle'}
				items={[
					{
						label: (
							<span className='text-[#000] text-[13px] font-[500]'>
								Pending Disbursement Approvals
							</span>
						),
						key: 1,
						children: <ApprovalTable data={disbapprovals} show={true} />,
					},
					{
						label: (
							<span className='text-[#000] text-[13px] font-[500]'>
								Pending Collection Transfer Requests
							</span>
						),
						key: 2,
						children: <ApprovalTable data={colapprovals} show={true} />,
					},
					// {
					// 	label: (
					// 		<span className='text-[#000] text-[13px] font-[500]'>
					// 			Rejected Requests
					// 		</span>
					// 	),
					// 	key: 3,
					// 	children: <ApprovalTable data={rejected} rejected={rejected} show={false} />,
					// },
				]}
			/>
		</>
	);
}
