import { Box, Button, Grid, Input, Typography } from '@mui/joy';
import {
	Checkbox,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import { MailOutline, Lock } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import login from '../../assets/img/regImg.png';
import logo from '../../assets/web.svg';
import { Spin, message } from 'antd';
import { updateUser, updateUserPassword } from '../../features/auth/authSlice';

const NewPassword = () => {
	const { authLoading } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const { usrId } = useParams();
	const params = useParams();
	const [searchParams] = useSearchParams();

	const usrId = searchParams.get('usrId');

	const [pin1, setpin1] = useState(null);
	const [pin2, setpin2] = useState(null);

	const handleOnChangePin = (event) => {
		setpin1(event.target.value);
	};

	const handleOnChangePin2 = (event) => {
		setpin2(event.target.value);
	};

	const handleLogin = () => {
		window.location.href = "./login"
	}

	const handleOnSubmitLogin = async (e) => {
		const usrObj = {
			usrId: Number(usrId),
			usrEncryptedPassword: pin1,
		};
		e.preventDefault();
		if (!pin1 || !pin2) {
			return message.info('Password cannot be blank');
		}
		if (pin1 !== pin2 || pin1?.length < 6) {
			return message.info('Passwords do not match');
		} else {
			const res = await dispatch(updateUserPassword(usrObj));
			console.log(res);
			if (res?.payload?.success) {
				message.success(res?.payload?.messages?.message);
				setTimeout(() => {
					window.location.href = "./login"
				}, 3000);
			} else {
				message.error(
					res?.payload?.messages?.message
						? res?.payload?.messages?.message
						: 'Could not update password. Please try agin later',
				);
			}
		}
	};

	const backgroundImageUrl = `url(${login})`;

	useEffect(() => {
		if (pin1?.length < 6 && pin2?.length === pin1?.length) {
			message.info('Password length must be atleast 6 characters');
		}
	}, [pin1, pin2]);

	return (
		<>
			<Box
				className='flex lg:justify-between'
				spacing={2}
				sx={{
					width: '100%',
					height: '100%',
					overflowX: 'hidden',
				}}
			>
				<Box sx={{ width: '100%', bgcolor: '#fff' }}>
					<Box sx={{ mt: '30px', ml: '33px' }}>
						<Link to='/home'>
							<img className='w-[214px] h-[77px]' src={logo} alt='logo' />
						</Link>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								height: '90vh',
							}}
						>
							<Box sx={{ textAlign: 'center' }}>
								<Typography sx={{ fontWeight: 600, fontSize: '28px' }}>
									New Password
								</Typography>
								<Typography sx={{ mt: 2 }}>Enter your new password.</Typography>
							</Box>
							<form onSubmit={(e) => handleOnSubmitLogin(e)}>
								<Box sx={{ width: '70%', mx: 'auto' }}>
									<Grid item xs={12}>
										<Typography
											component='h6'
											sx={{ my: '.5rem', fontWeight: 'bold' }}
										>
											New Password
										</Typography>
										<OutlinedInput
											label=''
											value={pin1}
											onChange={handleOnChangePin}
											type='password'
											onFocus
											size='lg'
											sx={{ width: '100%' }}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														<Lock />{' '}
														{/* Replace this with the icon you want to use */}
													</IconButton>
												</InputAdornment>
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<Typography
											component='h6'
											sx={{ my: '.5rem', fontWeight: 'bold' }}
										>
											Confirm Password
										</Typography>
										<OutlinedInput
											label=''
											value={pin2}
											onChange={handleOnChangePin2}
											type='password'
											onFocus
											size='lg'
											sx={{ width: '100%' }}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														<Lock />{' '}
														{/* Replace this with the icon you want to use */}
													</IconButton>
												</InputAdornment>
											}
										/>
									</Grid>

									<Grid xs={6} sx={{ mx: 'auto', mt: '71px' }}>
										<Button
											// onClick={onNextHandler}
											sx={{
												// mx: '20%',
												bgcolor: 'black',
												width: '188px',
												p: 2,
												borderRadius: 0,
												'&:hover': {
													bgcolor: 'gray',
												},
											}}
											// disabled={messagePhone == 'OK'}
											type='submit'
											disabled={
												pin1?.length < 6 ||
												pin2?.length !== pin1?.length ||
												authLoading
											}
										>
											{authLoading ? <Spin /> : 'Submit'}
										</Button>

										<div className='mt-[19px]'>
											<button
												onClick={handleLogin}
												className='text-[#0873B9]'
											>
												Back to login
											</button>
										</div>
									</Grid>
								</Box>
							</form>
						</Box>
					</Box>
				</Box>
				<Box
					className='hidden lg:flex'
					sx={{
						width: '100%',
						// height: '100vh',
						bgcolor: 'black',
						backgroundImage: backgroundImageUrl,
						backgroundSize: 'cover',
						// backgroundPosition: "center",
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div
						className='text-[#fff] p-5 ml-[5%]'
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							// alignItems: 'center',
							width: '100%',
						}}
					>
						<h1 className='text-[#fff] text-5xl font-bold mt-5'>
							Seamless payment management
						</h1>
						<p className='text-[#fff] text-[18px] mt-2'>
							The fastest way to manage payments across your <br /> departments
						</p>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default NewPassword;
