import React, { useEffect, useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import Grid from '@mui/material/Grid';
import { Box, Container, Stepper, Step, StepButton } from '@mui/material';
import { Typography } from '@mui/joy';
import Institution from './components/Institution';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import OrgDetails from './components/OrgDetails';
import { message } from 'antd';

import Credentials from './components/credentials';
import { useDispatch } from 'react-redux';
import { createOrganization } from '../../../features/auth/authSlice';
import LandingHeader from '../../../components/header/LandingHeader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const steps = [
	{
		label: 'Step 1',
		description: 'Organization Details',
	},
	{
		label: 'Step 2',
		description: 'Admin Information',
	},
	// {
	// 	label: 'Step 3',
	// 	description: 'Password Setup',
	// },
];

const SignupPartner = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		orgCounty: 'Nairobi',
	});
	const [next, setnext] = useState(true);
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});

	const updateFormData = (updatedData) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			...updatedData,
		}));
	};

	console.log(formData);

	const handleSubmit = async () => {
		if (!formData.orgName) {
			handleReset();
			return message.info('Organization name cannot be blank');
		}
		if (!formData.orgEmail) {
			handleReset();
			return message.info('Organization email cannot be blank');
		}
		if (!formData.orgMobile) {
			handleReset();
			return message.info('Organization phone number is required');
		}
		if (!formData.orgCounty) {
			handleReset();
			return message.info('Please select your county');
		}
		if (!formData.orgKraPin) {
			handleReset();
			return message.info('Enter organization KRA pin');
		}
		if (formData.orgKraPin.length < 11) {
			handleReset();
			return message.info('KRA pin should be 11 characters long');
		}
		if (!formData.orgTown) {
			handleReset();
			return message.info('Fill the town where organization is situated');
		}
		if (!formData.usrFirstName) {
			return message.info('Admin name is required');
		}
		if (!formData.usrLastName) {
			return message.info('Admin name is required');
		}
		if (!formData.usrEmail) {
			return message.info('Email address is required for the admin');
		}
		if (!formData.usrMobileNumber) {
			return message.info('Please enter admin telephone number');
		}
		if (formData.usrEncryptedPassword?.length < 6) {
			return message.info('Password length must be more than 6 characters');
		}

		if (formData.usrEncryptedPassword !== formData.usrEncryptedPasswordAlt) {
			return message.info('Passwords do not match');
		}

		if (
			formData.usrEncryptedPassword?.length > 6 &&
			formData.usrEncryptedPassword === formData?.usrEncryptedPasswordAlt
		) {
			await delete formData?.usrEncryptedPasswordAlt;
			const res = await dispatch(createOrganization(formData));
			if (res?.payload?.success) {
				await setFormData({});
				// await navigate('/login');
				
				await message.success(
					res?.payload?.messages?.message
						? res?.payload?.messages?.message
						: 'Account created successfuly',
				);
				window.location = await "./login"
			} else {
				await message.error(
					res?.payload?.messages?.message
						? res?.payload?.messages?.message
						: 'We cannot register your account at the moment. Please try again later',
				);
			}
		} else {
			return message.error('Check your passwords and try again');
		}
	};

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
		setnext(false);
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: '#000000', // Change this to the desired color (black in this case)
			},
		},
	});

	const ActiveStepContent = ({ step }) => {
		switch (step) {
			case 0:
				return (
					<Institution
						next={next}
						formData={formData}
						updateFormData={updateFormData}
					/>
				);
			case 1:
				return (
					<OrgDetails formData={formData} updateFormData={updateFormData} />
				);
			// case 2:
			// 	return (
			// 		<Credentials formData={formData} updateFormData={updateFormData} />
			// 	);
			default:
				throw new Error('Unknown step');
		}
	};

	const onNextHandler = () => {
		if (activeStep === 0) {
			if (!formData.orgName) {
				return message.info('Organization name cannot be blank');
			}
			if (!formData.orgEmail) {
				return message.info('Organization email cannot be blank');
			}
			if (!formData.orgMobile) {
				return message.info('Organization phone number is required');
			}
			if (!formData.orgCounty) {
				return message.info('Please select your county');
			}
			if (!formData.orgKraPin) {
				return message.info('Enter organization KRA pin');
			}
			if (formData.orgKraPin.length < 11) {
				return message.info('KRA pin should be 11 characters long');
			}
			if (!formData.orgTown) {
				return message.info('Fill the town where organization is situated');
			}
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			handleNext();
		}
	};

	const handleStep = (step) => () => {
		// handleNext()
		setActiveStep(step);

		// little customization
		if (step === 0) {
			handleReset();
		} else if (step === 1) {
			setCompleted({ 0: true, 1: true });
		} else if (step === 2) {
			setCompleted({ 0: true, 1: true });
		} else {
			handleComplete();
		}
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	useEffect(() => {}, [formData]);
	useEffect(() => {}, [next]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<Box>
					<Container className='main' maxWidth='lg' sx={{ my: 4 }}>
						<Grid container spacing={2}>
							<Grid
								item
								md={12}
								xs={12}
								style={{
									backgroundColor: '#ffff',
									paddingTop: 0,
									marginTop: '16px',
								}}
							>
								<Grid xs={1} sx={{ m: 'auto' }}>
									<div className='text-xl font-bold text-neutral-950'>
										Register
									</div>
								</Grid>
								<Grid xs={5} sx={{ m: 'auto' }}>
									<div className='text-lg  mt-3 text-neutral-950'>
										Fill in the details below to create an account
									</div>
								</Grid>
								<Box
									sx={{ width: '100%', paddingTop: '24px' }}
									className='custom-stepper'
								>
									<ThemeProvider theme={theme}>
										<Stepper nonLinear activeStep={activeStep} alternativeLabel>
											{steps.map((step, index) => (
												<Step key={step.label} completed={completed[index]}>
													<StepButton onClick={handleStep(index)}>
														<Typography
															component='h5'
															className='step-description'
														>
															{step.description}
														</Typography>
													</StepButton>
												</Step>
											))}
										</Stepper>
									</ThemeProvider>
									<div style={{ margin: '4rem 1.5rem' }}>
										<React.Fragment>
											<Box sx={{ mt: 4, mb: 2, py: 1 }}>
												<ActiveStepContent step={activeStep} />
											</Box>

											<Box
												sx={{
													display: 'flex',
													width: '100%',
													justifyContent: 'center',
													pt: 2,
													m: 'auto',
												}}
											>
												{activeStep === 0 && (
													<Button
														disbaled={true}
														onClick={onNextHandler}
														sx={{
															bgcolor: 'black',
															width: '200px',
															p: 3,
															borderRadius: 0,
															'&:hover': {
																bgcolor: 'gray',
															},
														}}
														// disabled={messagePhone == 'OK'}
													>
														Next
													</Button>
												)}
												{activeStep === 1 && (
													<>
														<Button
															onClick={handleSubmit}
															sx={{
																bgcolor: 'black',
																width: '200px',
																p: 3,
																borderRadius: 0,
																'&:hover': {
																	bgcolor: 'gray',
																},
															}}
														>
															Register
														</Button>
													</>
												)}
											</Box>
										</React.Fragment>
									</div>
								</Box>
								<CssVarsProvider />
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default SignupPartner;
