import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './reports.css';
import { fetchBranchWallets } from '../../features/wallet/walletSlice';
import { Button, DatePicker, Select } from 'antd';
import {
	convertToDateISO,
	previewPaper2PaidIn,
	previewPaperPaidIn,
} from '../../utility/Utils';

export default function AccountPaidIn() {
	const { showUser } = useSelector((state) => state.reports);
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { branchWallets } = useSelector((state) => state.wallet);
	const { globalBrnId } = useSelector((state) => state.global);

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [size, setSize] = useState('large');
	const [selectedWalletId, setSelectedWalletId] = useState(null);

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		brnId: globalBrnId ?? user?.brnId,
	};

	async function handleBranchData() {
		await dispatch(fetchBranchWallets(usrObj));
	}

	const onChangeStartDate = (date) => {
		setStartDate(convertToDateISO(date?.$d));
	};

	const onChangeEndDate = (date) => {
		setEndDate(convertToDateISO(date?.$d));
	};

	useEffect(() => {
		handleBranchData();
	}, [globalBrnId, user]);

	useEffect(() => {}, [showUser]);

	return (
		<>
			<Box sx={{ display: 'flex', gap: 2 }}>
				<Box sx={{ width: '30%' }}>
					<Paper
						elevation={0}
						sx={{ height: '90vh', position: 'sticky', bottom: 0, pt: 4 }}
					>
						<NavLink
							to='/reports'
							className='hoveredNavLink'
							style={({ isActive, isTransitioning }) => {
								return {
									fontWeight: isActive ? 800 : '',
									color: isActive ? '#36C2C9' : '#495C5D',
									viewTransitionName: isTransitioning ? 'slide' : '',
									textDecoration: isActive ? 'none' : 'none',
									backgroundColor: isActive ? '#E5FCFD' : '',
									paddingBottom: isActive ? 10 : '',
									display: 'flex',
									alignItems: 'center',
									width: isActive ? '90%' : '90%',
									padding: isActive ? '10px 0px 10px 5px' : '10px 0px 10px 5px',
									borderRadius: isActive ? '7px' : '',
									marginBottom: 10,
									marginLeft: isActive ? '10px' : '10px',
									paddingLeft: isActive ? '20px' : '20px',
								};
							}}>
							<Typography variant='body1'>Statement</Typography>
						</NavLink>

						<NavLink
							to='/paid-in'
							className='hoveredNavLink'
							style={({ isActive, isTransitioning }) => {
								return {
									fontWeight: isActive ? 800 : '',
									color: isActive ? '#36C2C9' : '#495C5D',
									viewTransitionName: isTransitioning ? 'slide' : '',
									textDecoration: isActive ? 'none' : 'none',
									backgroundColor: isActive ? '#E5FCFD' : '',
									paddingBottom: isActive ? 10 : '',
									display: 'flex',
									alignItems: 'center',
									// gap: 8,
									width: isActive ? '90%' : '90%',
									padding: isActive ? '10px 0px 10px 5px' : '10px 0px 10px 5px',
									borderRadius: isActive ? '7px' : '',
									marginBottom: 10,
									marginLeft: isActive ? '10px' : '10px',
									paddingLeft: isActive ? '20px' : '20px',
								};
							}}>
							<Typography variant='body1'>Paid in</Typography>
						</NavLink>

						<NavLink
							to='/paid-out'
							className='hoveredNavLink'
							style={({ isActive, isTransitioning }) => {
								return {
									fontWeight: isActive ? 800 : '',
									color: isActive ? '#36C2C9' : '#495C5D',
									viewTransitionName: isTransitioning ? 'slide' : '',
									textDecoration: isActive ? 'none' : 'none',
									backgroundColor: isActive ? '#E5FCFD' : '',
									paddingBottom: isActive ? 10 : '',
									display: 'flex',
									alignItems: 'center',
									width: isActive ? '90%' : '90%',
									padding: isActive ? '10px 0px 10px 5px' : '10px 0px 10px 5px',
									borderRadius: isActive ? '7px' : '',
									marginBottom: 10,
									marginLeft: isActive ? '10px' : '10px',
									paddingLeft: isActive ? '20px' : '20px',
								};
							}}>
							<Typography variant='body1'>Paid out</Typography>
						</NavLink>
					</Paper>
				</Box>

				<Box sx={{ width: '70%', px: '5%', position: 'relative', pt: 5 }}>
					<div className='flex gap-4 mb-4'>
						<DatePicker
							onChange={onChangeStartDate}
							className='w-[50%] h-[40px]'
						/>
						<DatePicker
							onChange={onChangeEndDate}
							className='w-[50%] h-[40px]'
						/>
					</div>

					<Select
						onDropdownVisibleChange={handleBranchData}
						name={'waltTo'}
						defaultValue='Choose'
						style={{
							width: '100%',
						}}
						onChange={(value) => {
							setSelectedWalletId(value);
						}}
						options={
							branchWallets?.length > 0 &&
							branchWallets?.map((item) => ({
								value: item?.walId,
								label: `${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`,
							}))
						}
					/>

					<div className='flex absolute bottom-10 right-0 gap-4'>
						<Button
							size={size}
							onClick={() =>
								previewPaper2PaidIn(startDate, endDate, selectedWalletId)
							}>
							Preview
						</Button>

						<Button
							size={size}
							onClick={() =>
								previewPaperPaidIn(startDate, endDate, selectedWalletId)
							}>
							Download
						</Button>
					</div>
				</Box>
			</Box>
		</>
	);
}
