import { React, useEffect, useState } from 'react';
import { Table } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionPayTransfer } from '../../../features/collections/collectionSlice';

const FundTransTable = ({ data }) => {
	const dispatch = useDispatch()
	const { colLoading } = useSelector((state) => state.collection);
	const [filter, setfilter] = useState('All');

	const [filterData, setfilterData] = useState(data);

	const pending = data?.filter((item) => item.waltStatus === 'PENDING');
	const approved = data?.filter((item) => item.waltStatus === 'COMPLETED');
	const rejected = data?.filter((item) => item.waltStatus === 'REJECTED');

	async function handlePendingReceipts(){
		await dispatch(fetchCollectionPayTransfer()) // PENDING TASK HAHAHHAHAHHAHAHHA
	}

	useEffect(() => {
		if (filter === 'PENDING') {
			setfilterData(pending);
		} else if (filter === 'REJECTED') {
			setfilterData(rejected);
		} else if (filter === 'COMPLETED') {
			setfilterData(approved);
		} else {
			setfilterData(data);
		}
	}, [filter]);

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'waltCreatedDate',
			render: (item) => (
				<span>{moment(item).format('MMM DD YYYY, h:mm A')}</span>
			),
		},
		{
			title: 'Source Acc',
			dataIndex: 'waltFromWalCode',
			sorter: {
				compare: (a, b) => a.waltFromWalCode - b.waltFromWalCode,
				multiple: 2,
			},
		},
		{
			title: 'Source Name',
			dataIndex: 'waltFromWalName',
			sorter: {
				compare: (a, b) => a.waltFromWalName - b.waltFromWalName,
				multiple: 2,
			},
		},
		{
			title: 'Beneficiary Acc',
			sorter: {
				compare: (a, b) => a.waltToWalCode - b.waltToWalCode,
				multiple: 1,
			},
			render: (item) => (
				<span>{item?.waltToWalCode + ' - ' + item?.waltToWalName}</span>
			),
		},
		{
			title: 'Transfer Reason',
			sorter: {
				compare: (a, b) => a.waltTransferReason - b.waltTransferReason,
				multiple: 1,
			},
			render: (item) => <span>{item?.waltTransferReason}</span>,
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'waltAmount',
			sorter: {
				compare: (a, b) => a.waltAmount - b.waltAmount,
				multiple: 1,
			},
		},
		{
			title: 'Request By',
			sorter: {
				compare: (a, b) => a.createdBy - b.createdBy,
				multiple: 1,
			},
			render: (item) => <span>{item?.createdBy}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'waltStatus',
			sorter: {
				compare: (a, b) => a.waltStatus - b.waltStatus,
				multiple: 1,
			},
		},
	];
	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<div className='flex items-center mb-4'>
					<div
						onClick={() => setfilter('All')}
						className={
							filter === 'All'
								? 'flex items-center p-2 border-2 border-[#146ADC] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#146ADC] !text-[12px]' />
						<span className='table-filter-txt ml-2'>All</span>
					</div>

					<div
						onClick={() => setfilter('PENDING')}
						className={
							filter === 'PENDING'
								? 'flex items-center p-2 border-2 border-[#FFAB00] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#FFAB00] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Pending</span>
					</div>

					<div
						onClick={() => setfilter('REJECTED')}
						className={
							filter === 'REJECTED'
								? 'flex items-center border-2 border-[#9D1500] p-2 justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#9D1500] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Rejected</span>
					</div>

					<div
						onClick={() => setfilter('COMPLETED')}
						className={
							filter === 'COMPLETED'
								? 'flex items-center p-2 border-2 border-[#2DD683] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#2DD683] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Approved</span>
					</div>
				</div>

				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={filterData}
					onChange={onChange}
					loading={colLoading}
				/>
			</section>
		</div>
	);
};
export default FundTransTable;
