import React from "react";
import DashboardHeader from "../components/header/DashboardHeader";
import Sidebar3 from "../components/sidebar/Sidebar3";

const DashboardLayout = ({children, isSideBarEnabled = true, isAccountType, setAccountType}) => {
   
    return (
        <div className={`page-wrapper page-wrapper--dashboard ${isSideBarEnabled ? 'show-sidebar':'' }`}>
            {isSideBarEnabled ? <DashboardHeader isAccountType = {isAccountType}/> : null}
            {isSideBarEnabled && <Sidebar3
                isAccountType = {isAccountType}
                setAccountType = {setAccountType}
            />}
            <main className="dash-main">
                {children}
            </main>
        </div>
    );
}

export default DashboardLayout;

