import { React, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Dropdown, Pagination, Radio } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setActiveBranch,
	setEditBranch,
} from '../../features/branch/branchSlice';

const BranchTable = ({ data }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleViewBranch = (branch) => {
		dispatch(setActiveBranch(branch));
		navigate('/setup/branch/overview');
	};

	const handleEditBranch = (branch) => {
		dispatch(setEditBranch(branch));
		navigate(`/setup/branch/${branch?.brnName}/edit`);
		handleClose();
	};

	return (
		<div className='dash-inner-page mt-7'>
			<section>
				<Table responsive striped className='commonTable'>
					<thead>
						<tr>
							<th>Branch Name</th>
							<th>Location</th>
							<th>Email</th>
							<th>Phone No.</th>
							{Number(user?.usrRoleId) === 1 ? <th>Action</th> : null}
						</tr>
					</thead>
					<tbody>
						{data?.map((item) => {
							const items =
								Number(user?.usrRoleId) === 1 && item?.brnId === user?.brnId
									? [
											{
												label: (
													<a onClick={() => handleViewBranch(item)}>
														View Info
													</a>
												),
												key: '0',
											},
											{
												label: (
													<a onClick={() => handleEditBranch(item)}>
														Edit Branch
													</a>
												),
												key: '1',
											},
									  ]
									: [
											{
												label: (
													<a onClick={() => handleViewBranch(item)}>
														View Info
													</a>
												),
												key: '0',
											},
									  ];

							return (
								<>
									<tr>
										<td>
											<div className='flex items-center space-x-4'>
												<div className='flex-shrink-0'>
													<img
														className='w-[45px] h-[35px] rounded-full'
														src='https://www.branch.io/wp-content/uploads/2022/12/Full-color-logo-1.svg'
														alt='branch'
													/>
												</div>
												<div className=''>
													<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
														{item?.brnName}
													</p>
												</div>
											</div>
										</td>
										<td>{item?.brnLocation}</td>
										<td>{item?.brnEmail}</td>
										<td>{item?.brnMobile}</td>
										{Number(user?.usrRoleId) === 1 ? (
											<td>
												<div>
													<Dropdown
														placement='bottom'
														overlayStyle={{
															width: '239px',
															padding: '10px',
														}}
														menu={{
															items,
														}}
														trigger={['click']}
													>
														<a onClick={(e) => e.preventDefault()}>
															<MoreHorizIcon className='text-[#000] -mt-1' />
														</a>
													</Dropdown>
												</div>
											</td>
										) : null}
									</tr>
								</>
							);
						})}
					</tbody>
				</Table>

				{/* <div className='flex justify-end'>
					<Pagination defaultCurrent={6} total={500} />
				</div> */}
			</section>
		</div>
	);
};
export default BranchTable;
