import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import UserTable from "../../components/global/UserTable";
import { useDispatch, useSelector } from "react-redux";
// import { openAddUserDrawer } from "../../features/drawer/drawerSlice";
import UserInfoTabs from "./UserInfoTabs";
// import { usersArray } from "../../data";
import AddUserModalOne from "./modals/AddUserModalOne";
import EditUserModal from "./modals/EditUserModal";
import AddUserToBranchModal from "./modals/AddUserToBranchModal";
import {
  closeSelectedActive,
  // fetchUsers,
  fetchUsersAdmin,
} from "../../features/auth/authSlice";

function UserManagement() {
  const dispatch = useDispatch();
  const { userList, user, activeUser, selectedActive } = useSelector(
    (state) => state.auth
  );
  const { branches } = useSelector((state) => state.branch);

  const activeUserBranch = branches?.find(
    (item) => item?.brnId === activeUser?.usrJbrnId
  );

  const [open, setopen] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open3, setopen3] = useState(false);
  const [data, setdata] = useState(userList);
  const [search, setsearch] = useState("");

  function handleOpenOne() {
    setopen(true);
  }

  function closeModalOne() {
    setopen(false);
    setopen2(false);
    setopen3(false);
  }

  function handleEditUser(value) {
    setopen2(value);
  }

  function handleEditUserBranch(value) {
    setopen3(value);
  }

  async function handleFetchUsers() {
    const usrObj = {
      usrId: user?.usrId,
      orgId: user?.orgId,
    };

    await dispatch(fetchUsersAdmin(usrObj));
  }

  function handleFilterUser(value) {
    setsearch(value);
  }

  useEffect(() => {
    handleFetchUsers();
  }, [activeUserBranch, activeUser]);

  useEffect(() => {}, [selectedActive]);

  useEffect(() => {
    handleFetchUsers();
    dispatch(closeSelectedActive());
  }, []);


  useEffect(() => {
    const newData = data?.filter((item) => {
      if (search?.length > 0) {
        return (
          item?.usrFirstName?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.usrLastName?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.usrEmail?.toLowerCase().includes(search?.toLowerCase())
        );
      } else {
        return item;
      }
    });

    if (newData?.length > 0) {
      setdata(newData);
    } else {
      setdata(userList);
    }
  }, [search]);

  useEffect(() => {}, [data]);

  useEffect(() => {
    setdata(userList);
  }, [userList]);

  useEffect(() => {}, [user]);

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="table-heads">User Management</h3>
        <button
          onClick={handleOpenOne}
          type="button"
          className="flex items-center border-2 border-[#222] rounded-[6px] p-1 text-white bg-[#212143]"
        >
          <AddIcon />
          <span className="ml-1 text-[14px]">Add user</span>
        </button>
      </div>
      <div className="flex flex-col h-[600px] max-h-[700px] justify-between ">
        <div className="mt-4">
          <form>
            <div className="relative w-[260px]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                value={search}
                onChange={(e) => handleFilterUser(e.target.value)}
                type="search"
                id="default-search"
                className="block w-full h-[20px] p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg ml-[5px]"
                placeholder="Start typing to search"
                required
              />
            </div>
          </form>

          <UserTable
            filter={search}
            handleEditUser={handleEditUser}
            handleEditUserBranch={handleEditUserBranch}
            data={data}
          />
        </div>

        <div className="">
          {selectedActive ? (
            <UserInfoTabs
              handleEditUser={handleEditUser}
              data={activeUserBranch}
            />
          ) : null}
        </div>
      </div>
      <AddUserModalOne open={open} closeModalOne={closeModalOne} />
      <EditUserModal open={open2} closeModalOne={closeModalOne} />
      <AddUserToBranchModal open={open3} closeModalOne={closeModalOne} />
    </>
  );
}

export default UserManagement;
