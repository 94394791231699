import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import '@splidejs/react-splide/css';

ReactDOM.render(
    <Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<HashRouter>
				{/* <React.StrictMode> */}
					<App />
					<Toaster
						position='top-center'
						reverseOrder={false}
						gutter={8}
						containerClassName=''
						containerStyle={{}}
						toastOptions={{
							// Define default options
							className: '',
							duration: 5000,
							style: {
								background: '#363636',
								color: '#fff',
							},

							// Default options for specific types
							success: {
								duration: 3000,
								theme: {
									primary: 'green',
									secondary: 'black',
								},
							},
						}}
					/>
				{/* </React.StrictMode> */}
			</HashRouter>
		</PersistGate>
	</Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
