import { React, useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

const CollectionIncomingTable = ({ data }) => {
	const { colLoading } = useSelector((state) => state.collection);

	const [filterData, setfilterData] = useState(data);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'payCreatedTime',
			key: 'payCreatedDate',
			render: (item) => (
				<span className='capitalize'>
					{moment(item).format('MMM DD YYYY, h:mm A')}
				</span>
			),
			width: '180px',
		},
		{
			title: 'Reference No.',
			dataIndex: 'payReferenceNo',
			key: 'payReferenceNo',
		},
		{
			title: 'Source Acc No.',
			key: 'paySourceAccId',
			render: (item) => (
				<span>
					{item?.payMpesaNo ? item?.payMpesaNo : item?.payPaySourceAccNo}
				</span>
			),
		},
		{
			title: 'Source Acc Name',
			key: 'mpesaName',
			render: (item) => (
				<span>
					{item?.mpesaName
						? item?.mpesaName.split(' ').slice(0, -1).join()
						: 'N/A'}
				</span>
			),
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'payAmount',
			key: 'payAmount',
		},
		{
			title: 'Payment Mode',
			dataIndex: 'payMethod',
			key: 'payMethod',
		},
	];

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={filterData}
					loading={colLoading}
				/>
			</section>
		</div>
	);
};
export default CollectionIncomingTable;
