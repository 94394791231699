import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { Button, Dropdown, Form, Input, InputNumber, Select, Spin } from 'antd';
import SearchSelectUser from '../../components/global/SearchSelectUser';
import { counties } from '../../counties';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBranches, saveBranch } from '../../features/branch/branchSlice';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import AccountModal from './modals/AccountModal';
import { fetchBanks } from '../../features/payment/paymentSlice';
import { fetchOrganizationPayAccounts } from '../../features/organization/organizationSlice';
import MpesaModal from './modals/MpesaModal';
import WalletModal from './modals/WalletModal';
import { fetchBranchWallets } from '../../features/wallet/walletSlice';

const { Option } = Select;

export default function CreateEditBranch({ edit }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const { organizations, orgPayAccounts } = useSelector((state) => state.org);
	const { branchLoading, editBranch } = useSelector((state) => state.branch);
	const { branchWallets } = useSelector((state) => state.wallet);

	const usrObj = {
		usrId: user?.usrId,
		orgadBrnId: editBranch?.brnId,
		brnId: editBranch?.brnId,
	};

	let org = organizations[0];

	const [form] = Form.useForm();
	const params = useParams();

	const [open, setOpen] = useState(false);
	const [open2, setopen2] = useState(false);
	const [open3, setopen3] = useState(false);

	function handleOpenOne(e) {
		e.preventDefault();
		setOpen(true);
	}

	function handleOpenTwo(e) {
		e.preventDefault();
		setopen2(true);
	}

	function handleOpenThree(e) {
		e.preventDefault();
		setopen3(true);
	}

	function closeModalOne() {
		setOpen(false);
		setopen2(false);
		setopen3(false);
	}

	const handleCancel = () => navigate(`/setup/${org?.orgName}/branches`);

	async function handleFetchbanks() {
		await dispatch(fetchBanks(usrObj));
	}

	async function handleOrgPayAcc() {
		await dispatch(fetchOrganizationPayAccounts(usrObj));
	}

	async function onFinish(values) {
		const usrObj = {
			usrId: user?.usrId,
			orgId: org?.orgId,
		};

		values.orgId = org?.orgId;
		values.brnCreatedBy = user?.usrId;
		values.usrId = user?.usrId;
		values.brnKraPin = 'XXXXXXXXXX';

		if (editBranch?.brnId) {
			values.brnId = editBranch?.brnId;
		}

		if (values.brnCounty === undefined) {
			values.brnCounty = 'Nairobi';
		}
		const res = await dispatch(saveBranch(values));
		if (res?.payload?.success) {
			await dispatch(fetchBranches(usrObj));
			if (params?.type) {
				await toast.success('Branch created.');
			} else {
				await toast.success('Branch updated.');
			}

			await navigate(`/setup/${org?.orgName}/branches`);
		} else {
			await toast.error('Branch not created. Please try again later');
		}
	}

	async function handleBrnWalletChange(){
		await dispatch(fetchBranchWallets(usrObj))
	}

	const items = [
		{
			label: (
				<a onClick={(e) => handleOpenOne(e)} href='#to'>
					Bank Account
				</a>
			),
			key: '0',
		},
		{
			label: (
				<a onClick={(e) => handleOpenTwo(e)} href='#to'>
					M-PESA
				</a>
			),
			key: '1',
		},
	];

	useEffect(() => {
		form.setFieldsValue({
			brnName: editBranch?.brnName ? editBranch?.brnName : '',
			brnCounty: editBranch?.brnCounty ? editBranch?.brnCounty : 'Nairobi',
			brnTown: editBranch?.brnTown ? editBranch?.brnTown : '',
			brnLocation: editBranch?.brnLocation ? editBranch?.brnLocation : '',
			brnPostalAddress: editBranch?.brnPostalAddress
				? editBranch?.brnPostalAddress
				: '',
			brnMobile: editBranch?.brnMobile ? editBranch?.brnMobile : '',
			brnEmail: editBranch?.brnEmail ? editBranch?.brnEmail : '',
		});
	}, [editBranch]);

	useEffect(() => {
		handleFetchbanks();
		handleOrgPayAcc();
		handleBrnWalletChange()
	}, []);

	useEffect(() => {}, [orgPayAccounts, branchWallets]);

	console.log(branchWallets);

	return (
		<>
			<BreadCrumb
				root={'Setup'}
				current={params?.type ? 'Create brunch' : 'Edit'}
				others={params?.type ? ['Branch'] : [org?.orgName, params?.name]}
				url={'/setup/organizations'}
			/>

			<h3 className='table-heads my-4'>
				{params?.type ? 'Create Branch' : 'Edit Branch'}
			</h3>

			<div className='bg-white p-4'>
				<h4 className='text-[#0A0A0A] font-[700] text-[16px] mb-3'>
					Branch Info
				</h4>
				<Form
					onFinish={onFinish}
					layout={'vertical'}
					form={form}
					initialValues={{
						layout: 'vertical',
					}}
				>
					<Form.Item
						rules={[
							{
								required: true,
								message: 'Branch name is required',
							},
						]}
						name={'brnName'}
						label='Branch Name'
					>
						<Input placeholder='Branch Name' />
					</Form.Item>

					<div className='flex items-center justify-between'>
						<Form.Item name={'brnCreatedBy'} label='Branch Manager'>
							<SearchSelectUser />
						</Form.Item>
						<Form.Item name={'brnProfilePic'} label='Branch Logo'>
							<Input
								type='file'
								style={{
									width: '527px',
									border: '1px solid black',
								}}
								placeholder='Branch Logo'
							/>
						</Form.Item>
					</div>

					<h4 className='text-[#0A0A0A] font-[700] text-[16px] my-3'>
						Address and Contact Information
					</h4>

					<div className='flex items-center justify-between'>
						<Form.Item name={'brnCounty'} label='County'>
							<Select
								style={{
									width: '527px',
								}}
								placeholder='select one country'
								defaultValue={['Nairobi']}
								optionLabelProp='label'
							>
								{counties?.map((item) => {
									return (
										<>
											<Option value={item?.name} label={item?.name}></Option>
										</>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Enter the town branch is located',
								},
							]}
							name={'brnTown'}
							label='Area'
						>
							<Input
								style={{
									width: '527px',
								}}
							/>
						</Form.Item>
					</div>

					<div className='flex items-center justify-between'>
						<Form.Item name={'brnPostalAddress'} label='P.O Box'>
							<Input
								style={{
									width: '527px',
								}}
							/>
						</Form.Item>
						<Form.Item name={'brnLocation'} label='Physical Address'>
							<Input
								style={{
									width: '527px',
								}}
							/>
						</Form.Item>
					</div>

					<div className='flex items-center justify-between'>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Email is a required',
								},
							]}
							name={'brnEmail'}
							label='Email'
						>
							<Input
								style={{
									width: '527px',
								}}
							/>
						</Form.Item>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Branch mobile number is required',
								},
							]}
							name={'brnMobile'}
							label='Phone Number'
						>
							<InputNumber
								prefix='+254'
								maxLength={10}
								minLength={9}
								style={{
									width: '527px',
								}}
							/>
						</Form.Item>
					</div>

					{params?.type ? null : (
						<>
							<div className='flex justify-between items-center my-4'>
								<h4 className='text-[#0A0A0A] font-[700] text-[16px]'>
									Wallet Information
								</h4>
								<button
									onClick={handleOpenThree}
									className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
									type='button'
								>
									Add Wallet
								</button>
							</div>

							{/* <h4 className='text-[#0A0A0A] font-[500] text-[14px]  my-3'>
								Bank Details
								{orgPayAccounts?.length ? null : (
									<span className='ml-3 font-[400] text-[13px]'>
										: Not provided
									</span>
								)}
							</h4> */}

							{branchWallets?.map((item) => {
								return (
									<>
										<div className='my-3 pb-3 border-b border-gray-400'>
											<div className='flex items-center justify-between'>
												<div className='flex flex-col'>
													<label for='orgadBnkAccName'>Wallet Name</label>
													<input
														className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
														readOnly
														name='orgadBnkAccName'
														value={item?.walDesc}
													/>
												</div>

												<div className='flex flex-col'>
													<label for='orgadDesc'>Type</label>
													<input
														className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
														readOnly
														name='orgadDesc'
														value={item?.walType}
													/>
												</div>
											</div>

											<div className='flex items-center justify-between mt-2'>
												<div className='flex flex-col'>
													<label for='orgadBnkAccNo'>Wallet Acc Number</label>
													<input
														className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
														readOnly
														name='orgadBnkAccNo'
														value={item?.walCode}
													/>
												</div>

												<div className='flex flex-col'>
													<label for='orgadStatus'>Wallet Amount</label>
													<input
														className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
														readOnly
														name='orgadStatus'
														value={'KES ' + item?.walAmount}
													/>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</>
					)}

					{params?.type ? null : (
						<>
							<div className='flex justify-between items-center my-4'>
								<h4 className='text-[#0A0A0A] font-[700] text-[16px]'>
									Payment Information
								</h4>

								<Dropdown
									menu={{
										items,
									}}
									trigger={['click']}
								>
									<button
										className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
										type='button'
									>
										Add Account
									</button>
								</Dropdown>
							</div>

							<h4 className='text-[#0A0A0A] font-[500] text-[14px]  my-3'>
								Bank Details
								{orgPayAccounts?.length ? null : (
									<span className='ml-3 font-[400] text-[13px]'>
										: Not provided
									</span>
								)}
							</h4>

							{orgPayAccounts
								?.filter((item) => item?.orgadType === 'BANK')
								?.map((item) => {
									return (
										<>
											<div className='my-3 pb-3 border-b border-gray-400'>
												<div className='flex items-center justify-between'>
													<div className='flex flex-col'>
														<label for='orgadBnkAccName'>Bank Name</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadBnkAccName'
															value={item?.orgadBnkAccName}
														/>
													</div>

													<div className='flex flex-col'>
														<label for='orgadDesc'>Branch</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadDesc'
															value={item?.orgadDesc}
														/>
													</div>
												</div>

												<div className='flex items-center justify-between mt-2'>
													<div className='flex flex-col'>
														<label for='orgadBnkAccNo'>Account Number</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadBnkAccNo'
															value={item?.orgadBnkAccNo}
														/>
													</div>

													<div className='flex flex-col'>
														<label for='orgadStatus'>Account name</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadStatus'
															value={item?.orgadStatus}
														/>
													</div>
												</div>
											</div>
										</>
									);
								})}

							<h4 className='text-[#0A0A0A] font-[500] text-[14px] my-3'>
								MPESA Details{' '}
								{orgPayAccounts?.length ? null : (
									<span className='ml-3 font-[400] text-[13px]'>
										: Not provided
									</span>
								)}
							</h4>

							{orgPayAccounts
								?.filter((item) => item?.orgadType === 'MPESA')
								?.map((item) => {
									if (item?.orgadStatus === 'PAYBILL') {
										return (
											<>
												<div className='flex items-center justify-between'>
													<div className='flex flex-col'>
														<label for='orgadBnkAccName'>Paybill Name</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadBnkAccName'
															value={item?.orgadBnkAccName}
														/>
													</div>

													<div className='flex flex-col mt-2'>
														<label for='orgadPaybillNo'>Paybill Number</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadPaybillNo'
															value={item?.orgadPaybillNo}
														/>
													</div>
												</div>
											</>
										);
									} else {
										return (
											<>
												<div className='flex items-center justify-between'>
													<div className='flex flex-col'>
														<label for='orgadBnkAccName'>Till Name</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadBnkAccName'
															value={item?.orgadBnkAccName}
														/>
													</div>

													<div className='flex flex-col mt-2'>
														<label for='orgadTillNo'>Till Name</label>
														<input
															className='border !border-[#0A0A0A] w-[527px] h-[44px] mt-2 pl-3'
															readOnly
															name='orgadTillNo'
															value={item?.orgadTillNo}
														/>
													</div>
												</div>
											</>
										);
									}
								})}
						</>
					)}

					<div className='flex justify-end'>
						<button
							onClick={handleCancel}
							className='p-1'
							style={{
								background: '#D3D3D9',
								color: '#212143',
								fontWeight: 500,
								fontSize: '14px',
							}}
							type='button'
						>
							Cancel
						</button>
						<button
							disabled={branchLoading}
							className='p-1'
							style={{
								background: '#10101E',
								color: '#fff',
								fontWeight: 500,
								fontSize: '14px',
								marginLeft: '10px',
							}}
							type='submit'
						>
							{branchLoading ? <Spin className='mx-2' /> : 'Save'}
						</button>
					</div>
				</Form>
			</div>

			<AccountModal closeModalOne={closeModalOne} open={open} />
			<MpesaModal closeModalOne={closeModalOne} open={open2} />
			<WalletModal closeModalOne={closeModalOne} open={open3} />
		</>
	);
}
