import { React, useState } from 'react';
import { Table } from 'antd';
import { Pagination } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const columns = [
	{
		title: 'Account No.',
		dataIndex: 'name',
		sorter: {
			compare: (a, b) => a.chinese - b.chinese,
			multiple: 3,
		},
	},
	{
		title: 'Account Name',
		dataIndex: 'english',
		sorter: {
			compare: (a, b) => a.english - b.english,
			multiple: 1,
		},
	},
	{
		title: 'Account Balance',
		dataIndex: 'english',
		sorter: {
			compare: (a, b) => a.english - b.english,
			multiple: 1,
		},
	},
	{
		title: 'Statement',
		dataIndex: 'english',
		sorter: {
			compare: (a, b) => a.english - b.english,
			multiple: 1,
		},
	},
];
const data = [
	{
		key: '1',
		name: 'John Brown',
		chinese: 98,
		math: 60,
	},
	{
		key: '2',
		name: 'Jim Green',
		chinese: 98,
		math: 66,

	},
	{
		key: '3',
		name: 'Joe Black',
		chinese: 98,
		math: 90,
	},
	{
		key: '4',
		name: 'Jim Red',
		chinese: 88,
		math: 99,
	},
];

const AccountTable = ({ funds, transfer }) => {
	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	return (
		<div className='dash-inner-page mt-4'>
			<section>
				{funds ? (
					<div className='flex items-center mb-4'>
						<div className='flex items-center p-2 border-2 border-[#9D1500] justify-center w-[158px] h-[39px] cursor-pointer'>
							<FiberManualRecordIcon className='text-[#9D1500] !text-[12px]' />
							<span className='table-filter-txt ml-2'>Pending</span>
						</div>

						<div className='flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'>
							<FiberManualRecordIcon className='text-[#FFAB00] !text-[12px]' />
							<span className='table-filter-txt ml-2'>Processing</span>
						</div>

						<div className='flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'>
							<FiberManualRecordIcon className='text-[#2DD683] !text-[12px]' />
							<span className='table-filter-txt ml-2'>Approved</span>
						</div>
						{transfer ? (
							<div className='flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'>
								<FiberManualRecordIcon className='text-[#dc3545] !text-[12px]' />
								<span className='table-filter-txt ml-2'>Rejected</span>
							</div>
						) : null}
					</div>
				) : null}

				<Table columns={columns} dataSource={data} onChange={onChange} />

				{/* <div className='flex justify-end'>
					<Pagination defaultCurrent={6} total={500} />
				</div> */}
			</section>
		</div>
	);
};
export default AccountTable;
