import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Select, Spin } from 'antd';
import { MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

const url = process.env.REACT_APP_API_BASE_URL;

const data = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function DashRoundChart() {
	const { branches } = useSelector((state) => state.branch);
	const { user } = useSelector((state) => state.auth);

	const [brSUm, setbrSUm] = useState([]);
	const [loading, setloading] = useState(false);

	async function handleFetchBranchlySummary() {
		if (user?.usrId) {
			setloading(true);
			const fetchPromises = await branches?.map((item) =>
				fetch(
					`${url}/web/fetchMpesaOutgoingSummary.action?usrId=${user?.usrId}&payBrnId=${item?.brnId}&payMethod=MPESA&period=MONTH&payType=DEBIT`
				)
					.then((res) => res.json())
					.then((data) => ({
						branch: item?.brnName,
						amount: data?.data?.result?.reduce(
							(accumulator, currentValue) =>
								accumulator + currentValue.totalAmount,
							0
						),
					}))
			);

			Promise.all(fetchPromises)
				.then((branchSums) => {
					setbrSUm(branchSums);
					setloading(false);
				})
				.catch((error) => {
					// console.error('An error occurred:', error);
					setloading(false);
				});
		}
	}

	useEffect(() => {
		handleFetchBranchlySummary();
	}, []);

	useEffect(() => {}, [brSUm, loading, user]);

	return (
		<>
			<div className='flex flex-col border-2 border-[#EBEBEB] bg-white rounded-[14px] px-4 pt-4 mt-10'>
				<div className='flex items-center'>
					<h3 className='text-[14px] font-[600] text-[#0D163A]'>
						Disbursement requests by Branch
					</h3>
				</div>
				{loading ? (
					<Spin size='large' className='text-6xl' />
				) : (
					<div className='flex flex-col'>
						<div>
							<ResponsiveContainer width='100%' height={300}>
								<PieChart
									width={250}
									height={300}
									onMouseEnter={this?.onPieEnter}>
									<Pie
										data={brSUm}
										cx={120}
										cy={150}
										innerRadius={70}
										outerRadius={110}
										fill='#8884d8'
										paddingAngle={5}
										dataKey='amount'>
										{brSUm.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={COLORS[index % COLORS.length]}
											/>
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
						<div className='flex flex-row flex-wrap justify-between px-3'>
							{brSUm?.map((item) => {
								return (
									<>
										<div className='flex flex-col mb-3'>
											<span className='w-full'>{item?.branch}</span>
											<span className='font-bold w-full'>
												KES {item?.amount}
											</span>
										</div>
									</>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</>
	);
}
