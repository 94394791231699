import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import PayFundModal from './modals/PayFundModal';
import { useEffect, useState } from 'react';
import FundTransTable from './tables/FundTransTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionTransfer } from '../../features/collections/collectionSlice';

export default function PayFundsTransfer() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { disbTransfers } = useSelector((state) => state.collection);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		brnId: globalBrnId ?? user?.brnId,
		waltType: 'DISBURSEMENT',
	};

	async function prefetchData() {
		await dispatch(fetchCollectionTransfer(usrObj));
	}

	const [open, setopen] = useState(false);

	function handleOpenOne() {
		setopen(true);
	}

	function closeModalOne() {
		setopen(false);
	}

	const initialValue = 0;
	const outgoingDisbursement = disbTransfers
		?.filter((item) => item?.payStatus === 'APPROVED')
		?.reduce(
			(accumulator, currentValue) => accumulator + currentValue.payAmount,
			initialValue
		);

	useEffect(() => {}, [disbTransfers]);

	useEffect(() => {
		prefetchData();
	}, [globalBrnId, user]);

	return (
		<>
			<BreadCrumb
				root={'Payments'}
				current={'Funds Transfer'}
				url={'payments/funds-transfer'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Funds Transfer</h3>
				<button
					onClick={handleOpenOne}
					className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
					type='button'>
					Funds Transfer
				</button>
			</div>

			<div className='mt-7 w-full'>
				<h3 className='table-sub-heads mt-3'>Activities</h3>
				<FundTransTable data={disbTransfers} />
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-4 mt-10'>
				<div className='!border-l-8 border-l-[#FA8B3A] border-2 border-[#E6E9EE] w-[237px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>Outgoing</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						KES {outgoingDisbursement}
					</span>
				</div>
			</div>

			{/* <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
				<DashRecentTransactions />
			</div> */}

			<PayFundModal
				prefetchData={prefetchData}
				open={open}
				closeModalOne={closeModalOne}
			/>
		</>
	);
}
