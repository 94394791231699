import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserTable from '../../components/global/UserTable';
import AddUserDrw from '../../components/drawers/user/AddUserDrw';
import { useDispatch } from 'react-redux';
import { openAddUserDrawer } from '../../features/drawer/drawerSlice';

function Users() {
	const dispatch = useDispatch()

	function handleAddUserDrawer(){
		dispatch(openAddUserDrawer())
	}

	return (
		<>
			<div className='flex items-center justify-between py-3 border-y-2 border-[#E8E8E8]'>
				<h3 className='text-[24px] text-[#1D191B] font-[600]'>Users</h3>
				<button onClick={handleAddUserDrawer} type='button' className='flex items-center border-2 border-[#222] rounded-[6px] p-2 text-[#1D191B]'>
					<PersonAddIcon />
					<span className='ml-2'>Add user</span>
				</button>
			</div>

			<UserTable />
			<AddUserDrw />
		</>
	);
}

export default Users;
