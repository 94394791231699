import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	payLoading: false,
	bulkUploads: [],
	bulkUploadDetails: [],
	selectedbulk: null,
	openSelectedbulk: false,
	banks: [],
	payTransfers: [],
	payReceipts: [],
	directPays: [],
	paySummary: [],
	payMonthSummary: [],
	payDaySummary: [],
	billers: [],
};

export const fetchPayments = createAsyncThunk(
	'paymentSlice/fetchPayments',
	() => {
		return axios.get(`${url}/web/fetchPayments.action`).then((res) => res.data);
	}
);

export const savePayment = createAsyncThunk(
	'paymentSlice/savePayment',
	async (data) => {
		const res = await axios.post(`${url}/web/saveDirectPayment.action`, data);
		return res.data;
	}
);

export const saveBulkPayment = createAsyncThunk(
	'paymentSlice/saveBulkPayment',
	async (data) => {
		const res = await axios.post(
			`${url}/web/uploadBulkPayments.action?usrId=${data?.usrId}`,
			data
		);
		return res.data;
	}
);

export const fetchBulkUpload = createAsyncThunk(
	'paymentSlice/fetchBulkUpload',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchbulkPaymentUpload.action?usrId=${usrObj?.usrId}&blkuMethod=${usrObj?.blkuMethod}&blkuBrnId=${usrObj?.brnId}`
			)
			.then((res) => res.data);
	}
);

export const fetchBulkUploadPayments = createAsyncThunk(
	'paymentSlice/fetchBulkUploadPayments',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetch_bulk_payments.action?usrId=${usrObj?.usrId}&blkBlkuId=${usrObj?.blkBlkuId}`
			)
			.then((res) => res.data);
	}
);

export const fetchBanks = createAsyncThunk(
	'paymentSlice/fetchBanks',
	(usrObj) => {
		return axios
			.get(`${url}/web/fetchBanks.action?usrId=${usrObj?.usrId}`)
			.then((res) => res.data);
	}
);

export const fetchPaymentTransfer = createAsyncThunk(
	'paymentSlice/fetchPaymentTransfer',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchCustomBulkPaymentsUploads.action?usrId=${usrObj?.usrId}&blkuMethod=${usrObj?.blkuMethod}`
			)
			.then((res) => res.data);
	}
);

export const fetchBulkPaymentsUploads = createAsyncThunk(
	'paymentSlice/fetchBulkPaymentsUploads',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetch_direct_payments.action?usrId=${usrObj?.usrId}&blkuBrnId=${usrObj?.brnId}`
			)
			.then((res) => res.data);
	}
);

export const fetchPaymetReceipts = createAsyncThunk(
	'paymentSlice/fetchPaymetReceipts',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPaymentTransferReceipt.action?usrId=${usrObj?.usrId}&paySummary=TRUE`
			)
			.then((res) => res.data);
	}
);

export const fetchPaymentSummary = createAsyncThunk(
	'paymentSlice/fetchPaymentSummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPaymentSummary.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}`
			)
			.then((res) => res.data);
	}
);

export const fetchPaymentMonthlySummary = createAsyncThunk(
	'paymentSlice/fetchPaymentMonthlySummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchMpesaOutgoingSummary.action?usrId=${usrObj?.usrId}&payMethod=MPESA&payBrnId=${usrObj?.brnId}&period=MONTH&payType=DEBIT`
			)
			.then((res) => res.data);
	}
);

export const fetchPaymentDailySummary = createAsyncThunk(
	'paymentSlice/fetchPaymentDailySummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchMpesaOutgoingSummary.action?usrId=${usrObj?.usrId}&payMethod=MPESA&payBrnId=${usrObj?.brnId}&period=DAY&payType=DEBIT`
			)
			.then((res) => res.data);
	}
);

export const fetchBillers = createAsyncThunk(
	'paymentSlice/fetchBillers',
	(usrObj) => {
		return axios.get(`${url}/web/fetchPaybills.action`).then((res) => res.data);
	}
);

export const saveBiller = createAsyncThunk(
	'paymentSlice/saveBiller',
	(obj) => {
		return axios.post(`${url}/web/savePaybill.action`, obj).then((res) => res.data);
	}
);

export const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		handleSelectedBulk: (state, action) => {
			state.selectedbulk = action?.payload;
			state.openSelectedbulk = true;
		},
		closeSelectedBulk: (state) => {
			state.selectedbulk = null;
			state.openSelectedbulk = false;
		},
		paymentCleanUp: (state) => {
			state.payLoading = false;
			state.bulkUploads = [];
			state.bulkUploadDetails = [];
			state.selectedbulk = null;
			state.openSelectedbulk = false;
			state.banks = [];
			state.payTransfers = [];
			state.payReceipts = [];
			state.directPays = [];
			state.paySummary = [];
			state.payMonthSummary = [];
			state.payDaySummary = [];
		},
	},

	extraReducers: (builder) => {
		builder

			.addCase(fetchBillers.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchBillers.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.billers = action?.payload?.data?.result;
				} else {
					state.billers = [];
				}
			})
			.addCase(fetchBillers.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchPayments.fulfilled, (state) => {
				state.payLoading = true;
			})

			.addCase(saveBiller.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(saveBiller.fulfilled, (state) => {
				state.payLoading = false;
			})
			.addCase(saveBiller.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(savePayment.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(savePayment.fulfilled, (state) => {
				state.payLoading = false;
			})
			.addCase(savePayment.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(saveBulkPayment.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(saveBulkPayment.fulfilled, (state) => {
				state.payLoading = false;
			})
			.addCase(saveBulkPayment.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchBulkUploadPayments.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchBulkUploadPayments.fulfilled, (state, action) => {
				state.payLoading = false;
				state.bulkUploadDetails = action?.payload?.data?.result;
			})
			.addCase(fetchBulkUploadPayments.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchBulkUpload.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchBulkUpload.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.bulkUploads = action?.payload?.data?.result;
				} else {
					state.bulkUploads = [];
				}
			})
			.addCase(fetchBulkUpload.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchBanks.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchBanks.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.banks = action?.payload?.data?.result;
				} else {
					state.banks = [];
				}
			})
			.addCase(fetchBanks.rejected, (state) => {
				state.payLoading = false;
				state.banks = [];
			})

			.addCase(fetchPaymentTransfer.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchPaymentTransfer.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.payTransfers = action?.payload?.data?.result;
				} else {
					state.payTransfers = [];
				}
			})
			.addCase(fetchPaymentTransfer.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchPaymetReceipts.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchPaymetReceipts.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.payReceipts = action?.payload?.data?.result;
				} else {
					state.payReceipts = [];
				}
			})
			.addCase(fetchPaymetReceipts.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchBulkPaymentsUploads.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchBulkPaymentsUploads.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.directPays = action?.payload?.data?.result;
				} else {
					state.directPays = [];
				}
			})
			.addCase(fetchBulkPaymentsUploads.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchPaymentSummary.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchPaymentSummary.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.paySummary = action?.payload?.data?.result;
				} else {
					state.paySummary = [];
				}
			})
			.addCase(fetchPaymentSummary.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchPaymentMonthlySummary.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchPaymentMonthlySummary.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.payMonthSummary = action?.payload?.data?.result;
				} else {
					state.payMonthSummary = [];
				}
			})
			.addCase(fetchPaymentMonthlySummary.rejected, (state) => {
				state.payLoading = false;
			})

			.addCase(fetchPaymentDailySummary.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(fetchPaymentDailySummary.fulfilled, (state, action) => {
				state.payLoading = false;
				if (action?.payload?.success) {
					state.payDaySummary = action?.payload?.data?.result;
				} else {
					state.payDaySummary = [];
				}
			})
			.addCase(fetchPaymentDailySummary.rejected, (state) => {
				state.payLoading = false;
			});
	},
});

export default paymentSlice.reducer;
export const { handleSelectedBulk, closeSelectedBulk, paymentCleanUp } =
	paymentSlice.actions;
