import { React, useEffect, useState } from 'react';
// import { Table } from 'react-bootstrap';
import { Pagination, Table } from 'antd';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { Tabs } from 'antd';
import Overview from './Overview';
import BranchOverview from './BranchOverview';
import { useSelector } from 'react-redux';

const OrgUserTable = () => {
	const { organizations } = useSelector((state) => state.org);
	const { activeBranch } = useSelector((state) => state.branch);
	const { userList } = useSelector((state) => state.auth);

	const [branchOverview, setbranchOverview] = useState('Overview');

	const org = organizations[0];

	const filterList = userList?.filter(
		(user) => user?.usrJbrnId === activeBranch?.brnId,
	);

	console.log('activeBranch', filterList);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'usrFullNames',
			key: 'usrFullNames',
			render: (item) => (
				<div className='flex items-center space-x-4'>
					<div className='flex-shrink-0'>
						<img
							className='w-[35px] h-[35px] rounded-full'
							src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
							alt='Neil'
						/>
					</div>
					<div className=''>
						<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
							{item}
						</p>
					</div>
				</div>
			),
		},
		{
			title: 'Role',
			dataIndex: 'usrRoleId',
			key: 'usrRoleId',
			render: item => <span>{Number(item?.usrRoleId) === 1 ? " Admin" : "Staff"}</span>
		},
		{
			title: 'Email',
			dataIndex: 'usrEmail',
			key: 'usrEmail'
		},
		{
			title: 'Phone Number',
			dataIndex: 'usrMobileNumber',
			key: 'usrMobileNumber',
		},
	];

	useEffect(() => {}, [branchOverview]);

	return (
		<div className='dash-inner-page'>
			<BreadCrumb
				root={'Setup'}
				others={['Organization', org?.orgName]}
				current={branchOverview}
				url={'setup/organizations'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads mb-4'>Branch - {branchOverview}</h3>
			</div>

			<Tabs
				defaultActiveKey={2}
				type='card'
				size={'Middle'}
				items={[
					{
						label: (
							<span
								onClick={() => setbranchOverview('Overview')}
								className='text-[#000] text-[13px] font-[500]'
							>
								Overview
							</span>
						),
						key: 1,
						children: <BranchOverview />,
					},
					{
						label: (
							<span
								onClick={() => setbranchOverview('Users')}
								className='text-[#000] text-[13px] font-[500]'
							>
								Users
							</span>
						),
						key: 2,
						children: (
							<section>
								<Table
									pagination={{
										defaultPageSize: 10,
										hideOnSinglePage: true,
										pageSizeOptions: [10, 20, 50, 100],
									}}
									columns={columns}
									dataSource={filterList}
								/>
								{/* <Table responsive striped className='commonTable'>
									<thead>
										<tr>
											<th>Name</th>
											<th>Location</th>
											<th>Email</th>
											<th>Phone No.</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div className='flex items-center space-x-4'>
													<div className='flex-shrink-0'>
														<img
															className='w-[35px] h-[35px] rounded-full'
															src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
															alt='Neil'
														/>
													</div>
													<div className=''>
														<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
															Abzedizo Tetz
														</p>
													</div>
												</div>
											</td>
											<td>Nairobary</td>
											<td>dee@tetz.com</td>
											<td>07541442562</td>
										</tr>
									</tbody>
								</Table> */}

								{/* <div className='flex justify-end'>
									<Pagination defaultCurrent={6} total={500} />
								</div> */}
							</section>
						),
					},
				]}
			/>
		</div>
	);
};
export default OrgUserTable;
