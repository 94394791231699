import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	message,
	InputNumber,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchBillers,
	fetchBulkPaymentsUploads,
	fetchPaymentTransfer,
	savePayment,
} from '../../../features/payment/paymentSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { fetchBranchWallets } from '../../../features/wallet/walletSlice';
import { mpesaTransType } from '../../../data';

const { Option } = Select;

const PayFundModalThree = ({ open3, closeModalOne, prefetchData }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = React.useRef(null);
	const { disbursementWallets, walletLoading, branchWallets } = useSelector(
		(state) => state.wallet
	);
	const { payLoading, billers } = useSelector((state) => state.payment);
	const { user } = useSelector((state) => state.auth);
	const { branches, branchLoading } = useSelector((state) => state.branch);

	const [current, setCurrent] = useState(0);
	const [otpVerified, setotpVerified] = useState('');
	const [statedisbursementWallets, setstatedisbursementWallets] =
		useState(disbursementWallets);
	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [data, setdata] = useState({});
	const [activeBranch, setactiveBranch] = useState();

	let allWallets = [...disbursementWallets, ...branchWallets];

	let srcName = allWallets?.find(
		(item) => Number(item?.walCode) === Number(data?.bkluWalCode)
	);

	const usrObj = {
		usrId: user?.usrId,
		blkuMethod: 'DIRECT',
		brnId: activeBranch?.brnId ?? user?.brnId,
		orgId: user?.orgId,
	};

	function handleOtpVerified(val) {
		setotpVerified(val);
	}

	const next = async () => {
		const otpObj = {
			usrId: user?.usrId,
		};

		if (current === 0) {
			if (
				data?.bkluWalCode === undefined ||
				data?.blkReceiverMobileNo === undefined ||
				data?.blkPaymentAmount === undefined
			) {
				return message.info('Please fill in the required fields');
			} else {
				setCurrent(current + 1);
			}
		} else if (current === 1) {
			const res = await dispatch(sendTransOtp(otpObj));
			if (res?.payload?.success) {
				setCurrent(current + 1);
			} else {
				message.info('We cannot complete your request at the moment');
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		form.resetFields();
		setdata({});
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	function handleAmountChange(value) {
		setdata((prevData) => ({
			...prevData,
			blkPaymentAmount: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const handleNumberChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			blkReceiverMobileNo: '254' + String(e),
		}));
	};

	const onFinish = async () => {
		data.blkuCreatedBy = user?.usrId;
		data.blkuType = 'MPESA';
		data.blkuMethod = 'DIRECT';
		data.blkuPaymentMode =
			data?.blkPaymentMode === 'MOBILE_MONEY' ? 'MPESA' : 'MPESA_B2B';
		data.blkPaymentType =
			data?.blkPaymentMode === 'MOBILE_MONEY' ? 'MPESA' : 'MPESA_B2B';
		data.blkuTotalamount = Number(data.blkPaymentAmount);
		data.blkuFileDescription = data.blkPaymentReason;
		data.blkPaymentDesc = data.blkPaymentReason;
		data.blkBeneficiaryAccount = data.blkReceiverMobileNo;
		data.blkBeneficiaryDetails = data.blkReceiverMobileNo;

		const otpObj = {
			usrId: user?.usrId,
			usrSalt: otpVerified,
		};

		if (otpVerified?.length === 4) {
			const resp = await dispatch(verifyTransOtp(otpObj));
			if (resp?.payload?.success) {
				const res = await dispatch(savePayment(data));

				if (res?.payload?.success) {
					setisSuccess(true);
					setresponse(res?.payload?.messages?.message); // fetchBulkPaymentsUploads
					await dispatch(fetchPaymentTransfer(usrObj));
					const resp = await dispatch(fetchBulkPaymentsUploads(usrObj));
					if (resp?.payload?.success) {
						prefetchData();
					}
				} else {
					setisSuccess(false);
					setresponse(res?.payload?.messages?.message);
				}
				await setCurrent(3);
			} else {
				return message.error('Could not verify code. Please try again');
			}
		} else {
			return message.info('Enter the four digit verification code');
		}
	};

	function handleSelect(value, fileName) {
		setdata((prevData) => ({
			...prevData,
			[fileName]: value,
		}));
	}

	async function handleFetch() {
		await dispatch(fetchBillers());
	}

	async function handleBranchChange(brnId) {
		await setdata({ ...data, bkluWalCode: null });
		let actvBrn = await branches?.find((item) => item?.brnId === brnId);
		await setactiveBranch(actvBrn);
		await dispatch(fetchBranchWallets({ ...usrObj, brnId: actvBrn?.brnId }));
	}

	async function handleCancel() {
		await form.resetFields();
		await setactiveBranch();
		await setdata({});
		await closeModalOne();
	}

	useEffect(() => {}, [
		data,
		branches,
		branchWallets,
		activeBranch,
		disbursementWallets,
	]);

	useEffect(() => {
		handleFetch();
	}, []);

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					{Number(user?.usrRoleId) === 0 ? (
						<div className='mb-4'>
							<label className='mb-1 flex items-center' for='waltTo'>
								<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
									*
								</span>{' '}
								Payment Source
							</label>
							<div className='flex items-center'>
								{branches?.length > 1 ? (
									<Select
										value={activeBranch?.brnId}
										loading={branchLoading}
										onChange={(e) => handleBranchChange(e)}
										defaultValue='Select Branch'
										style={{
											width: '190px',
											marginRight: 20,
										}}>
										{branches?.map((item) => {
											return (
												<>
													<Option value={item?.brnId}>{item?.brnName}</Option>
												</>
											);
										})}
									</Select>
								) : null}

								<Select
									value={data?.bkluWalCode}
									loading={walletLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}
									onChange={(e) => handleSelect(e, 'bkluWalCode')}>
									{branchWallets
										?.filter((item) => item.walType === 'DISBURSEMENT')
										?.map((item) => {
											return (
												<>
													<Option
														value={
															item?.walCode
														}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
												</>
											);
										})}
								</Select>
							</div>
						</div>
					) : (
						<div className='mb-4'>
							<label className='mb-1 flex items-center' for='waltTo'>
								<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
									*
								</span>{' '}
								Payment Source
							</label>
							<Select
								value={data?.bkluWalCode}
								loading={walletLoading}
								defaultValue='Choose'
								style={{
									width: '100%',
								}}
								onChange={(e) => handleSelect(e, 'bkluWalCode')}>
								{disbursementWallets?.map((item) => {
									return (
										<>
											<Option
												value={
													item?.walCode
												}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
										</>
									);
								})}
							</Select>
						</div>
					)}

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'blkPaymentAmount'}
						label='Amount'>
						<InputNumber
							min={8}
							max={499999}
							onChange={handleAmountChange}
							name={'blkPaymentAmount'}
							type='number'
							className='rounded-[8px] h-[44px] w-full'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Select transaction type',
							},
						]}
						name={'blkPaymentMode'}
						label='Mode of payment'>
						<Select
							value={data?.blkPaymentMode}
							loading={walletLoading}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelect(e, 'blkPaymentMode')}
							options={mpesaTransType.map((item) => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</Form.Item>

					{data?.blkPaymentMode === 'TILL_NUMBER' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Select till',
									},
								]}
								name={'blkReceiverMobileNo'}
								label='Select till number'>
								<Select
									value={data?.blkReceiverMobileNo}
									loading={walletLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
										height: '44px',
									}}
									onDropdownVisibleChange={handleFetch}
									onChange={(e) => handleSelect(e, 'blkReceiverMobileNo')}
									options={billers
										?.filter((x) => x?.pbType === 'TILL_NUMBER')
										?.filter((y) => y?.pbStatus === 'APPROVED')
										?.map((item) => {
											return {
												label: item?.pbName,
												value: item?.pbPaybill,
											};
										})}
								/>
							</Form.Item>
						</>
					) : data?.blkPaymentMode === 'PAYBILL' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Select paybill',
									},
								]}
								name={'blkReceiverMobileNo'}
								label='Select paybill'>
								<Select
									value={data?.blkReceiverMobileNo}
									loading={walletLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
										height: '44px',
									}}
									onDropdownVisibleChange={handleFetch}
									onChange={(e) => handleSelect(e, 'blkReceiverMobileNo')}
									options={billers
										?.filter((x) => x?.pbType === 'PAYBILL')
										?.filter((y) => y?.pbStatus === 'APPROVED')
										?.map((item) => {
											return {
												label: item?.pbName,
												value: item?.pbPaybill,
											};
										})}
								/>
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter account number',
									},
								]}
								name={'blkPurpose'}
								label='Account number'>
								<Input
									onChange={handleChange}
									name={'blkPurpose'}
									className='rounded-[8px] h-[44px] w-full'
								/>
							</Form.Item>
						</>
					) : data?.blkPaymentMode === 'MOBILE_MONEY' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter phone number',
									},
								]}
								name={'blkReceiverMobileNo'}
								label='M-PESA Phone No.'>
								<InputNumber
									maxLength={10}
									minLength={9}
									prefix={'+254'}
									onChange={handleNumberChange}
									className='rounded-[8px] h-[44px] w-full'
								/>
							</Form.Item>
						</>
					) : null}

					<Form.Item name={'blkPaymentReason'} label='Reason for transfer'>
						<Input
							name={'blkPaymentReason'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					<p className='text-[15px] text-[#000] font-[600] mb-8'>
						Would you like to make the following transfer?
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Source:{' '}
						<span className='font-[400]'>
							{' '}
							{srcName?.walDesc} - {srcName?.walType}
						</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Amount:{' '}
						<span className='font-[400]'> KES {data?.blkPaymentAmount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Mpesa No:{' '}
						<span className='font-[400]'> {data?.blkReceiverMobileNo}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Reason:{' '}
						<span className='font-[400]'> {data?.blkPaymentReason}</span>
					</p>
				</div>
			),
		},
		{
			title: 'Second',
			content: (
				<div className='flex flex-col justify-center mt-10'>
					<p className='text-center mb-10'>
						Enter the 4 digit code sent to you
					</p>
					<OTPForm handleOtpVerified={handleOtpVerified} />
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-[179px]'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [data, statedisbursementWallets]);

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Direct Payment'
						: current === 1
						? 'Verification'
						: 'Transfer Status'
				}
				centered
				footer={null}
				open={open3}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}>
										{payLoading ? <Spin /> : 'Previous'}
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}>
										{payLoading ? <Spin /> : 'Next'}
									</Button>
								)}

								{current === 2 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										type='primary'
										htmlType='submit'>
										{payLoading ? <Spin /> : 'Transfer'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default PayFundModalThree;
