import { useDispatch, useSelector } from 'react-redux';
import HomeCard from '../../components/global/HomeCard';
import DashChart from '../dashboard/DashChart';
import DashLineChart from '../dashboard/DashLineChart';
import DashNotifications from '../dashboard/DashNotifications';
import DashRoundChart from '../dashboard/DashRoundChart';
import { useEffect, useState } from 'react';
import { fetchWallets } from '../../features/wallet/walletSlice';
import {
	fetchCollectionDailySummary,
	fetchCollectionMonthlySummary,
} from '../../features/collections/collectionSlice';

import {
	fetchPaymentDailySummary,
	fetchPaymentMonthlySummary,
} from '../../features/payment/paymentSlice';
import DashRecentTransactions from '../dashboard/DashRecentTransactions';

export default function CorporateHome() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { wallets } = useSelector((state) => state.wallet);
	const { organizations } = useSelector((state) => state.org);
	const { payMonthSummary, payDaySummary } = useSelector(
		(state) => state.payment
	);
	const { colDailySummary, colMonthlySummary } = useSelector(
		(state) => state.collection
	);
	const { globalBrnId } = useSelector((state) => state.global);
	const [loading, setloading] = useState(false);

	let org = organizations?.length && organizations[0];

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		payMethod: 'INCOMING',
		brnId: globalBrnId ?? user?.brnId,
	};

	async function getCardData() {
		if (user?.usrId) {
			await prefetchData();
		}
	}

	async function prefetchData() {
		await setloading(true);
		await dispatch(fetchWallets(usrObj));
		await dispatch(fetchCollectionDailySummary(usrObj));
		await dispatch(fetchCollectionMonthlySummary(usrObj));
		await dispatch(fetchPaymentMonthlySummary(usrObj));
		await dispatch(fetchPaymentDailySummary(usrObj));
		await setloading(false);
	}

	const disbWallet = wallets?.find((item) => item?.walType === 'DISBURSEMENT');
	const collWallet = wallets?.find((item) => item?.walType === 'COLLECTION');

	const initialValue = 0;

	const dailyCol =
		colDailySummary?.length &&
		new Date(colDailySummary[0].dates).getDate() === new Date().getDate()
			? colDailySummary[0].totalAmount
			: 0;

	const yesterCol =
		colDailySummary?.length > 1 ? colDailySummary[1].totalAmount : 0;

	const dailPerc = ((dailyCol - yesterCol) / yesterCol) * 100;

	const monthlyCol = colMonthlySummary?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.totalAmount,
		initialValue
	);

	const dailypay =
		payDaySummary?.length &&
		new Date(payDaySummary[0].dates).getDate() === new Date().getDate()
			? payDaySummary[0].totalAmount
			: 0;

	const yesterPay =
		payDaySummary?.length > 1 ? payDaySummary[1].totalAmount : 0;

	const dailPayPerc = ((dailypay - yesterPay) / yesterPay) * 100;

	const monPay = payMonthSummary?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.totalAmount,
		initialValue
	);

	useEffect(() => {
		getCardData();
	}, [globalBrnId, user]);

	useEffect(() => {}, [wallets, colMonthlySummary, organizations]);

	return (
		<>
			<div className='mb-10 ml-10 lg:ml-0'>
				<h3 className='text-[24px] font-[600] text-[#000] mb-[1rem]'>
					{org?.orgName}
				</h3>

				<h1 className='text-[20px] font-[500] text-[#0A0A0A]'>
					Welcome, {user?.usrFirstName}
				</h1>
				<p className='text-[13px] font-[500] text-[#575050]'>
					Here's a summary of payment activity
				</p>
			</div>
			<div className='flex w-full lg:w-[73%]'>
				<div className='w-[100%]'>
					{loading ? (
						<>
							<div class='loading-state'>
								<div class='loading'></div>
							</div>
						</>
					) : null}

					<div className='flex flex-wrap lg:flex-nowrap gap-3 px-10 lg:px-0'>
						<HomeCard
							color={'#C93C26'}
							text={'Current Collection Balance'}
							value={`${collWallet?.walCurrency ?? 'KES'} ${
								collWallet?.walAmount ?? 0
							}`}
							percent={'0.00%'}
							accNumber={collWallet?.walCode}
							account={true}
							name={collWallet?.walName}
						/>
						<HomeCard
							color={'#1D191B'}
							text={"Today's Total Collection"}
							value={`${collWallet?.walCurrency ?? 'KES'} ${dailyCol ?? 0}`}
							isUser={true}
							dailPerc={dailPerc}
						/>
						<HomeCard
							color={'#7C888B'}
							text={'Monthly Total Collection'}
							value={`${collWallet?.walCurrency ?? 'KES'} ${monthlyCol ?? 0}`}
							isUser={true}
						/>
					</div>

					<div className='flex flex-wrap lg:flex-nowrap gap-3 mt-4 px-10 lg:px-0'>
						<HomeCard
							color={'#F4B940'}
							text={'Currrent Disbursement Balance'}
							value={`${disbWallet?.walCurrency ?? 'KES'} ${
								disbWallet?.walAmount ?? 0
							}`}
							percent={'0.00%'}
							accNumber={disbWallet?.walCode}
							name={disbWallet?.walName}
							account={true}
						/>
						<HomeCard
							color={'#1D191B'}
							text={"Today's Total Disbursements"}
							value={`${disbWallet?.walCurrency ?? 'KES'} ${dailypay ?? 0}`}
							isUser={true}
							dailPayPerc={dailPayPerc}
						/>
						<HomeCard
							color={'#7C888B'}
							text={'Monthly Total Disbursement'}
							value={`${disbWallet?.walCurrency ?? 'KES'} ${monPay ?? 0}`}
							isUser={true}
						/>
					</div>

					<div className='grid grid-cols-1'>
						<DashLineChart />
					</div>

					<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
						<DashChart />
						<DashRoundChart />
					</div>

					<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
						<DashRecentTransactions />
						<DashRecentTransactions disb={true} />
					</div>
				</div>
			</div>

			<div className='hidden lg:block p-3 fixed right-5 bg-white w-[300px] h-[100%]'>
				<DashNotifications />
			</div>
		</>
	);
}
