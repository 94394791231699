import React, { useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	InputNumber,
	Spin,
} from 'antd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
	createOtherUser,
	fetchUsersAdmin,
} from '../../../features/auth/authSlice';

const AddUserModalOne = ({ open, closeModalOne }) => {
	const dispatch = useDispatch();
	const { branches } = useSelector((state) => state.branch);
	const { user, authLoading } = useSelector((state) => state.auth);

	const formRef = React.useRef(null);
	const [form] = Form.useForm();

	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const steps = [
		{
			title: 'User Info',
			key: 'User Info',
			content: (
				<>
					<div className='flex flex-col lg:flex-row lg:items-center'>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'First name is required',
								},
							]}
							name={'usrFirstName'}
							label='First Name'
						>
							<Input
								style={{
									width: '290px',
								}}
								className='rounded-[8px] h-[44px]'
							/>
						</Form.Item>

						<Form.Item
							rules={[
								{
									required: true,
									message: 'Last name is required',
								},
							]}
							name={'usrLastName'}
							className='ml-0 lg:ml-5'
							label='Last Name'
						>
							<Input
								style={{
									width: '290px',
								}}
								className='rounded-[8px] h-[44px]'
							/>
						</Form.Item>
					</div>

					<Form.Item name='usrRoleId' label='Role'>
						<Select
							defaultValue={2}
							style={{
								width: '100%',
							}}
							options={[
								{
									value: 2,
									label: 'User',
								},
								{
									value: 1,
									label: 'Admin',
								},
							]}
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'User should belong to a branch',
							},
						]}
						name={'brnId'}
						label='Branch'
					>
						<Select
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={handleChange}
							options={branches?.map((item) => {
								return {
									value: item?.brnId,
									label: item?.brnName,
								};
							})}
						/>
					</Form.Item>

					{/* <Form.Item
						name={'usrPersmissions'}
						label='Permission'
					>
						<Select
							defaultValue='None'
							style={{
								width: '100%',
							}}
							options={[
								{
									value: 'None',
									label: 'None',
								},
								{
									value: 'Initiate',
									label: 'Initiate',
								},
								{
									value: 'Approve',
									label: 'Approve',
								},
							]}
						/>
					</Form.Item> */}

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter a valid email',
							},
						]}
						name={'usrUsername'}
						label='Email'
					>
						<Input type='email' className='rounded-[8px] h-[44px]' />
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter a valid phone number',
							},
						]}
						name={'usrMobileNumber'}
						label='Phone No'
					>
						<InputNumber
							style={{
								width: '100%',
								height: '44px',
							}}
							maxLength={10}
							minLength={9}
							className='rounded-[8px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-[179px]'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}
							>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}
									>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}
									>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));
	const contentStyle = {};

	async function handleDone() {
		setCurrent(0);
		await form.resetFields();
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	async function handleCancel() {
		await form.resetFields();
		closeModalOne();
	}

	async function createUser(values) {
		const usrObj = {
			usrId: user?.usrId,
			orgId: user?.orgId,
		};

		values.usrId = user?.usrId;
		values.usrEmail = values?.usrUsername;

		if (!values.usrRoleId) {
			values.usrRoleId = 2;
		}

		const res = await dispatch(createOtherUser(values));

		if (res?.payload?.success) {
			setisSuccess(true);
			setresponse(res?.payload?.messages?.message);
		} else {
			setisSuccess(false);
			setresponse(res?.payload?.messages?.message);
		}
		await dispatch(fetchUsersAdmin(usrObj));
		setCurrent(1);
	}

	return (
		<>
			<Modal
				title={current === 0 ? 'Add User' : 'Status'}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={'Save'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={createUser}
						style={{
							maxWidth: 600,
						}}
						initialValues={{
							remember: true,
						}}
						form={form}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 0 ? (
							<div className='flex justify-end mt-[10%]'>
								<Button
									disabled={authLoading}
									style={{
										background: '#D3D3D9',
										color: '#212143',
										fontWeight: 500,
										fontSize: '14px',
									}}
									onClick={() => closeModalOne()}
								>
									{authLoading ? <Spin /> : 'Cancel'}
								</Button>

								<Button
									disabled={authLoading}
									style={{
										background: '#10101E',
										color: '#fff',
										fontWeight: 500,
										fontSize: '14px',
										marginLeft: '10px',
									}}
									type='primary'
									htmlType='submit'
									// onClick={() => createUser()}
								>
									{authLoading ? <Spin /> : 'Save'}
								</Button>
							</div>
						) : null}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default AddUserModalOne;
