import React, { useEffect } from 'react';
import { Modal, Table, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	approveCollection,
	fetchPendingFundApp,
	rejectCollection,
} from '../../features/approve/approveSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

const FundApprovalTable = ({ show }) => {
	const [modal, contextHolder] = Modal.useModal();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { approveLoading, fundapprovals } = useSelector(
		(state) => state.approve,
	);
	const { globalBrnId } = useSelector((state) => state.global);

	const [open, setOpen] = useState(false);
	const [confObj, setconfObj] = useState({});

	const location = window.location.href;

	const pathname = location.substring(location.lastIndexOf('/') + 1);

	const usrObj = {
		usrId: user?.usrId,
		brnId: globalBrnId ?? user?.brnId,
		waltApprovalLevels:
		  pathname === "approval-one" ? 0 : pathname === "approval-two" ? 1 : 2,
	  };

	async function handleFetch() {
		await dispatch(fetchPendingFundApp(usrObj));
	}

	useEffect(() => {}, [fundapprovals]);

	function handleConfirmApproval(obj) {
		setconfObj(obj);
		setOpen(true);
		confirm(obj);
	}

	async function handleApprove(item) {
		const usrObj = {
			usrId: user?.usrId,
			waltId: item,
			waltApprovalLevels:
				pathname === 'approval-one' ? 0 : pathname === 'approval-two' ? 1 : 2,
		};

		const res = await dispatch(approveCollection(usrObj));

		if (res?.payload?.success) {
			await handleFetch()
			message.success(res?.payload?.messages.message);
		} else {
			message.error(res?.payload?.messages.message);
		}
	}

	function handleConfirmreject(obj) {
		setconfObj(obj);
		setOpen(true);
		reject(obj);
	}

	async function handleReject(item) {
		const usrObj = {
			usrId: user?.usrId,
			waltId: item,
			waltApprovalLevels:
				pathname === 'approval-one' ? 0 : pathname === 'approval-two' ? 1 : 2,
		};

		const res = await dispatch(rejectCollection(usrObj));

		if (res?.payload?.success) {
			message.success(res?.payload?.messages?.message);
			await handleFetch()
		} else {
			message.error(res?.payload?.messages.message);
		}
	}

	const columns = [
		{
			title: 'Beneficiary Wallet',
			key: 'waltToWalName',
			dataIndex: 'waltToWalName',
		},
		{
			title: 'Beneficiary Account',
			key: 'waltToWalCode',
			dataIndex: 'waltToWalCode',
		},
		{
			title: 'Source Account',
			key: 'waltFromWalCode',
			dataIndex: 'waltFromWalCode',
		},
		{
			title: 'Reason for transfer',
			dataIndex: 'waltTransferReason',
			key: 'waltTransferReason',
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'waltAmount',
			key: 'waltAmount',
		},
		{
			title: 'Transfer Type',
			dataIndex: 'waltType',
			key: 'waltType',
			render: (item) => <span>Disbursement To Disbursement</span>,
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (item) => (
				<>
					{show ? (
						<>
							<button
								onClick={() => handleConfirmreject(item)}
								className='p-1'
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
								}}
							>
								Reject
							</button>
							<button
								onClick={() => handleConfirmApproval(item)}
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								className='ml-2 p-1'
							>
								Approve
							</button>
						</>
					) : null}
				</>
			),
		},
	];

	const confirm = (item) => {
		modal.confirm({
			title: 'Confirm Transaction',
			icon: <ExclamationCircleOutlined />,
			content: (
				<>
					<div>
						<p>
							Do you confirm transfer of KES{' '}
							<span className='!font-bold'>{item?.waltAmount}</span> to{' '}
							<span className='!font-bold'>{item?.waltToWalName}</span> Account:{' '}
							<span className='!font-bold'>{item?.waltToWalCode}</span>
						</p>
					</div>
				</>
			),
			okText: 'Confirm',
			cancelText: 'Cancel',
			confirmLoading: approveLoading,
			onOk: () => {
				handleApprove(item?.waltId);
			},
			okButtonProps: {
				style: {
					background: '#10101E',
					color: '#fff',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
			cancelButtonProps: {
				style: {
					background: '#D3D3D9',
					color: '#212143',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
		});
	};

	const reject = (item) => {
		modal.confirm({
			title: 'Reject Transaction',
			icon: <ExclamationCircleOutlined />,
			content: (
				<>
					<div>
						<p>
							Confirm rejecting transaction of KES{' '}
							<span className='!font-bold'>{item?.waltAmount}</span> to{' '}
							<span className='!font-bold'>{item?.waltToWalName}</span> Account:{' '}
							<span className='!font-bold'>{item?.waltToWalCode}</span>
						</p>
					</div>
				</>
			),
			okText: 'Reject',
			cancelText: 'Cancel',
			confirmLoading: approveLoading,
			onOk: () => {
				handleReject(item?.waltId);
			},
			okButtonProps: {
				style: {
					background: '#10101E',
					color: '#fff',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
			cancelButtonProps: {
				style: {
					background: '#D3D3D9',
					color: '#212143',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
		});
	};

	useEffect(() => {}, [open, confObj, fundapprovals]);

	useEffect(() => {
		handleFetch();
	}, [globalBrnId, user]);

	return (
		<>
			<div className='dash-inner-page  max-w-full w-full overflow-x-auto'>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={fundapprovals}
					loading={approveLoading}
					sortDirections={['descend']}
				/>
			</div>

			{contextHolder}
		</>
	);
};

export default FundApprovalTable;
