import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

// function handleClick(event) {
// 	event.preventDefault();
// 	console.info('You clicked a breadcrumb.');
// }

export default function BreadCrumb({ root, current, url, others }) {
	const breadcrumbs = [
		<Link
			underline='hover'
			key='1'
			color='inherit'
			to={url}
			// onClick={handleClick}
		>
			{root}
		</Link>,

		others?.length
			? others.map((bread) => {
					return (
						<>
							<Link
								underline='hover'
								key='1'
								color='inherit'
								to={url}
								// onClick={handleClick}
							>
								{bread}
							</Link>
						</>
					);
			  })
			: null,

		<Typography key='3' color='text.primary'>
			{current}
		</Typography>,
	];

	return (
		<Stack spacing={2}>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				{breadcrumbs}
			</Breadcrumbs>
		</Stack>
	);
}
