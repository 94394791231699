import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import UserInfoTable from "../../components/global/UserInfoTable";
import UserPermissions from "./UserPermissions";
import { useDispatch } from "react-redux";
import {
  closeSelectedActive,
} from "../../features/auth/authSlice";
const UserInfoTabs = ({ handleEditUser }) => {
  const dispatch = useDispatch();

  function handleCloseSelectedActive() {
    dispatch(closeSelectedActive());
  }


  return (
    <>
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={"Middle"}
        items={[
          {
            label: (
              <span className="text-[#000] text-[13px] font-[500]">
                Branches
              </span>
            ),
            key: 1,
            children: <UserInfoTable handleEditUser={handleEditUser} />,
          },
          // {
          // 	label: <span className='text-[#000] text-[13px] font-[500]'>Permissions</span>,
          // 	key: 2,
          // 	children: <UserPermissions />,
          // },
          {
            label: (
              <span
                onClick={handleCloseSelectedActive}
                className="text-[#000] text-[13px] font-[500]"
              >
                Close
              </span>
            ),
            key: 3,
          },
        ]}
      />
    </>
  );
};
export default UserInfoTabs;
