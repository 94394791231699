import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import TableComponent from '../../components/global/TableComponent';
import { useEffect, useState } from 'react';
import ColFundModalOne from './modals/ColFundModalOne';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionTransfer } from '../../features/collections/collectionSlice';
import { fetchWallets } from '../../features/wallet/walletSlice';

export default function FundsTransfer() {
	const dispatch = useDispatch();

	const { colTransfers } = useSelector((state) => state.collection);
	const { user } = useSelector((state) => state.auth);
	const { wallets } = useSelector((state) => state.wallet);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		waltType: 'COLLECTION',
		brnId: globalBrnId ?? user?.brnId,
	};

	const [open, setOpen] = useState(false);

	const collWallet = wallets?.find((item) => item?.walType === 'COLLECTION');

	function closeModalOne() {
		setOpen(false);
	}

	async function prefetchData() {
		await dispatch(fetchCollectionTransfer(usrObj));
		await dispatch(fetchWallets(usrObj));
	}

	useEffect(() => {
		prefetchData();
	}, [globalBrnId, user]);

	useEffect(() => {}, [wallets, colTransfers]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<>
			<BreadCrumb
				root={'Collections'}
				current={'Funds Transfer'}
				url={'collections/funds-transfer'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Funds Transfer</h3>
				<button
					onClick={() => setOpen(true)}
					className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
					type='button'>
					Funds Transfer
				</button>
			</div>

			<div className='mt-7 w-full'>
				<h3 className='table-sub-heads mt-3'>Activities</h3>
				<TableComponent funds={true} data={colTransfers} />
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-4 mt-10'>
				<div className='!border-l-8 border-l-[#FA8B3A] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>
						Current Collection Balance
					</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						KES {collWallet?.walAmount ?? 0}
					</span>
				</div>
			</div>
			{/* 
			<div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
				<DashRecentTransactions />
			</div> */}

			<ColFundModalOne
				closeModalOne={closeModalOne}
				open={open}
				prefetchData={prefetchData}
			/>
		</>
	);
}
