import { Tabs } from "antd";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchPendingPayApp } from "../../features/approve/approveSlice";
import { useEffect } from "react";
import ApprovalPayTable from "../../components/global/ApprovalPayTable";

export default function PayApprovals() {
  const dispatch = useDispatch();
  const { payapprovals } = useSelector((state) => state.approve);
  const { user } = useSelector((state) => state.auth);
  const { globalBrnId } = useSelector((state) => state.global);

  const location = window.location.href;

  const pathname = location.substring(location.lastIndexOf("/") + 1);

  const usrObj = {
    usrId: user?.usrId,
    brnId: globalBrnId ?? user?.brnId,
    blkuApprovalLevel:
      pathname === "approval-one" ? 0 : pathname === "approval-two" ? 1 : 2,
  };

  async function handleFetch() {
    await dispatch(fetchPendingPayApp(usrObj));
  }

  useEffect(() => {}, [payapprovals]);

  useEffect(() => {
    handleFetch();
  }, [globalBrnId, user]);


  return (
    <>
      <BreadCrumb
        root={"Approvals"}
        current={"Approval Dashboard"}
        url={"approvals"}
      />

      <div className="flex items-center justify-between mt-3 mb-5">
        <h3 className="table-heads">Payment Approval Dashboard</h3>
      </div>

      <Tabs
        defaultActiveKey={1}
        type="card"
        size={"Middle"}
        items={[
          {
            label: (
              <span className="text-[#000] text-[13px] font-[500]">
                Pending Payment Requests
              </span>
            ),
            key: 1,
            children: <ApprovalPayTable handleFetch={handleFetch} data={payapprovals} show={true} />,
          },
        ]}
      />
    </>
  );
}
