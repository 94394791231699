import React, { useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	Checkbox,
} from 'antd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Table } from 'react-bootstrap';

const AddUserToBranchModal = ({ open, closeModalOne }) => {
	const formRef = React.useRef(null);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	const onFinish = (values) => {
		console.log(values);
	};

	const steps = [
		{
			title: 'User Info',
			key: 'User Info',
			content: (
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: 600,
					}}
				>
					<Form.Item>
						<section>
							<Table responsive striped className='commonTable'>
								<thead>
									<tr>
										<th>Branch</th>
										<th>Permissions</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div className='flex items-center space-x-4'>
												<Checkbox className='w-[18px] h-[18px]' onChange={onChange}></Checkbox>

												<div className='flex-shrink-0'>
													<img
														className='w-[35px] h-[35px] rounded-full'
														src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
														alt='Neil'
													/>
												</div>
												<div className=''>
													<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
														Group F
													</p>
												</div>
											</div>
										</td>
										<td>
											<Select
												defaultValue='Initiate'
												style={{
													width: 140,
												}}
												bordered={false}
												options={[
													{
														value: 'Initiate',
														label: 'Initiate',
													},
													{
														value: 'Approve',
														label: 'Approve',
													},
													{
														value: 'Initiate, Approve',
														label: 'Initiate, Approve',
													},
												]}
											/>
										</td>
									</tr>
								</tbody>
							</Table>
						</section>
					</Form.Item>
				</Form>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='h-[179px]'>
					<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
						<div className='flex items-center'>
							<TaskAltIcon className='text-[#3BBB6E]' />
							<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
								User Added Successfully
							</p>
						</div>
						<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
							User will be notified of account creation
						</p>
					</div>

					<div className='flex justify-center mt-10'>
						<Button
							style={{
								background: '#10101E',
								color: '#fff',
								fontWeight: 500,
								fontSize: '14px',
								marginLeft: '10px',
							}}
							onClick={() => handleDone()}
						>
							Done
						</Button>
					</div>
				</div>
			),
		},
	];

	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};
	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));
	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
	}

	function createUser() {
		message.success('Processing complete!');
		setCurrent(1);
	}

	return (
		<>
			<Modal
				title={current === 0 ? 'Add User' : 'Done'}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={'Save'}
				onCancel={() => closeModalOne()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Steps current={current} items={items} />
					<div style={contentStyle}>{steps[current].content}</div>

					{current === 0 ? (
						<div className='flex justify-end mt-[10%]'>
							<Button
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
								}}
								onClick={() => closeModalOne()}
							>
								Cancel
							</Button>

							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								type='primary'
								onClick={() => createUser()}
							>
								Save
							</Button>
						</div>
					) : null}
				</>
			</Modal>
		</>
	);
};

export default AddUserToBranchModal;
