import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	globalLoading: false,
	mainBranch: false,
	alerts: [],
	globalBrnId: null,
};

export const sendTransOtp = createAsyncThunk(
	'globalSlice/sendTransOtp',
	async (data) => {
		const res = await axios.post(`${url}/web/send_otp_code.action`, data);
		return res.data;
	}
);

export const checkMainBranch = createAsyncThunk(
	'globalSlice/checkMainBranch',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/web/checkMainBranch.action?usrId=${usrObj?.usrId}`
		);
		return res.data;
	}
);

export const fetchAlerts = createAsyncThunk(
	'globalSlice/fetchAlerts',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/web/fetchAlerts.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}`
		);
		return res.data;
	}
);

export const updateAlert = createAsyncThunk(
	'globalSlice/updateAlert',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/web/updateAlert.action?usrId=${usrObj?.usrId}&altId=${usrObj?.altId}`
		);
		return res.data;
	}
);

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		globalCleanUp: (state) => {
			state.globalLoading = false;
			state.mainBranch = false;
			state.alerts = [];
		},
		setGlobalBrnId: (state, action) => {
			state.globalBrnId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(sendTransOtp.pending, (state) => {
				state.globalLoading = true;
			})
			.addCase(sendTransOtp.fulfilled, (state, action) => {
				state.globalLoading = false;
			})
			.addCase(sendTransOtp.rejected, (state) => {
				state.globalLoading = false;
			})

			.addCase(checkMainBranch.pending, (state) => {
				state.globalLoading = true;
			})
			.addCase(checkMainBranch.fulfilled, (state, action) => {
				state.globalLoading = false;
				if (action?.payload?.success) {
					state.mainBranch = true;
				} else {
					state.mainBranch = false;
				}
			})
			.addCase(checkMainBranch.rejected, (state) => {
				state.globalLoading = false;
			})

			.addCase(fetchAlerts.pending, (state) => {
				state.globalLoading = true;
			})
			.addCase(fetchAlerts.fulfilled, (state, action) => {
				state.globalLoading = false;
				if (action?.payload?.success) {
					state.alerts = action?.payload?.data?.result;
				} else {
					state.alerts = [];
				}
			})
			.addCase(fetchAlerts.rejected, (state) => {
				state.globalLoading = false;
			})

			.addCase(updateAlert.pending, (state) => {
				state.globalLoading = true;
			})
			.addCase(updateAlert.fulfilled, (state) => {
				state.globalLoading = false;
			})
			.addCase(updateAlert.rejected, (state) => {
				state.globalLoading = false;
			});
	},
});

export default globalSlice.reducer;
export const { globalCleanUp, setGlobalBrnId } = globalSlice.actions;
