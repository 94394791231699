import { Box, Grid } from '@mui/material';
import React from 'react';
import login from '../../../assets/img/regImg.png';
import logo from '../../../assets/web.svg';
import { Typography } from 'antd';
import SignupPartner from './SignupPartner';
import { Link } from 'react-router-dom';

const PartnerSingUp = () => {
	const backgroundImageUrl = `url(${login})`;
	return (
		<Box
			className="flex lg:justify-between"
			spacing={2}
			sx={{
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
			}}
		>
			<Box
				sx={{
					width: '100%',
				}}
			>
				<Link to='/home#/home'>
					<img className='w-[214px] h-[77px]' src={logo} alt='logo' />
				</Link>
				<SignupPartner />
			</Box>
			<Box
				className="hidden lg:flex"
				sx={{
					width: '100%',
					// height: '100vh',
					bgcolor: 'black',
					backgroundImage: backgroundImageUrl,
					backgroundSize: 'cover',
					// backgroundPosition: "center",
					backgroundRepeat: 'no-repeat',
				}}
			>
				<div
					className='text-[#fff] p-5 mt-[20%] ml-[5%]'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						// alignItems: 'center',
						width: '100%',
					}}
				>
					<h1 className='text-[#fff] text-5xl font-bold mt-5'>
						Seamless payment management
					</h1>
					<p className='text-[#fff] text-[18px] mt-2'>
						The fastest way to manage payments across your <br /> departments
					</p>
				</div>
			</Box>
		</Box>
	);
};

export default PartnerSingUp;
