import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchBranchWallets } from '../../features/wallet/walletSlice';

export default function MainAcc() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { branchWallets } = useSelector((state) => state.wallet);
	const { branches } = useSelector((state) => state.branch);
	const { globalBrnId } = useSelector((state) => state.global);

	const [activeBranch, setactiveBranch] = useState('');

	const branchId = globalBrnId ?? user?.brnId;

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		brnId: globalBrnId ?? user?.brnId,
	};

	async function handleBranchData() {
		await dispatch(fetchBranchWallets(usrObj));
	}

	async function handleBranchChange() {
		const brn = branches?.find((item) => item?.brnId === branchId);
		setactiveBranch(brn?.brnName);
	}

	useEffect(() => {
		handleBranchData();
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [globalBrnId, user]);

	useEffect(() => {
		handleBranchChange();
	}, [branches, branchWallets]);

	return (
		<>
			{/* <iframe title='item1' src="http://localhost:8080/usr/directReport.action?reportName=ACCOUNT_PAID_OUT&P_START_DATE=12/12/2023&P_END_DATE=22/04/2023"/> */}
			<BreadCrumb
				root={'Wallets'}
				current={activeBranch?.brnName + ' Branch'}
				url={'/wallets'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>{activeBranch} Branch</h3>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10'>
				{branchWallets?.map((item) => {
					return (
						<>
							<div className='!border-l-8 border-l-[#00B3FF] border-2 border-[#E6E9EE] mb-3 w-[287px] h-[105px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
								<span className='text-[#AFAFAF] text-[13px]'>
									{item?.walDesc} - {item?.walType}
								</span>
								<span className='text-[#AFAFAF] text-[16px]'>
									Total Balance
								</span>
								<span className='text-[#15223C] text-[20px] font-[600]'>
									KES {item?.walAmount}
								</span>
							</div>
						</>
					);
				})}
			</div>

			{/* <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
				<div className='col-span-2'>
					<DashChart />
				</div>

				<DashRecentTransactions />
			</div> */}
		</>
	);
}
