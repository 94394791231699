import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import { savePayment } from '../../../features/payment/paymentSlice';
import { fetchCollectionTransfer } from '../../../features/collections/collectionSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';
import {
	fetchOrganizationPayAccounts,
	saveOrganizationPayAccounts,
} from '../../../features/organization/organizationSlice';

const { Option } = Select;

const MpesaModal = ({ open, closeModalOne }) => {
	const dispatch = useDispatch();
	const { editBranch } = useSelector((state) => state.branch);
	const { organizations, orgLoading } = useSelector((state) => state.org);
	const { user } = useSelector((state) => state.auth);
	const { banks } = useSelector((state) => state.payment);
	const formRef = React.useRef(null);

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});

	let org = organizations[0];

	const usrObj = {
		usrId: user?.usrId,
		orgadBrnId: editBranch?.brnId,
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
	}

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			accType: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const onFinish = async () => {
		data.orgadBrnId = String(editBranch?.brnId);
		data.orgadOrgId = org?.orgId;
		data.orgadCreatedBy = user?.usrId;
		data.orgadType = 'MPESA';

        if (data?.accType === "PAYBILL") {
            data.orgadPaybillNo = data.orgadBnkAccNo
            data.orgadStatus = "PAYBILL"
        }else{
            data.orgadTillNo = data.orgadBnkAccNo
            data.orgadStatus = "TILL_NUMBER"
        }

        delete data.accType

		const res = await dispatch(saveOrganizationPayAccounts(data));
		if (res?.payload?.success) {
			await dispatch(fetchOrganizationPayAccounts(usrObj));
			await message.success('Account added');
			await handleDone();
			// await window.location.reload();
		} else {
			message.error('Unable to add an account');
		}
	};

	const steps = [
		{
			title: 'Mpesa Information',
			key: 'Mpesa Info',
			content: (
				<>
					<Form.Item
						name={'accType'}
						rules={[
							{
								required: true,
								message: 'Select bank',
							},
						]}
						label='Mpesa Type'
					>
						<Select
							name={'accType'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange2(e)}
						>
							<Option value='TILL_NUMBER'>Till </Option>
							<Option value='PAYBILL'>Paybill</Option>
						</Select>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Select bank',
							},
						]}
						name={'orgadBnkAccName'}
						label='Account Name'
					>
						<Input
							name={'orgadBnkAccName'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

                    <Form.Item
						rules={[
							{
								required: true,
								message: 'Select bank',
							},
						]}
						name={'orgadBnkAccNo'}
						label='Account Number'
					>
						<Input
							name={'orgadBnkAccNo'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [banks, data]);

	return (
		<>
			<Modal
				title={'Add Bank Details'}
				centered
				footer={null}
				open={open}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => closeModalOne()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						<div className='flex justify-end'>
							<Button
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}
							>
								Cancel
							</Button>

							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								htmlType='submit'
								type='primary'
							>
								{orgLoading ? <Spin /> : 'Save'}
							</Button>
						</div>
					</Form>
				</>
			</Modal>
		</>
	);
};

export default MpesaModal;
