import { React, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';

const ApprovalPayDetailTable = ({ data }) => {
	const { approveLoading, selectedApprovalObj } = useSelector(
		(state) => state.approve,
	);

	const columns = [
		{
			title: 'Source Acc No.',
			render: () => <span>{selectedApprovalObj?.bkluWalCode}</span>,
		},
		{
			title: 'Beneficiary Acc.',
			dataIndex: 'blkReceiverMobileNo',
			sorter: {
				compare: (a, b) => a.blkReceiverMobileNo - b.blkReceiverMobileNo,
				multiple: 1,
			},
		},
		{
			title: 'Beneficiary Name',
			dataIndex: 'blkReceiverName',
			sorter: {
				compare: (a, b) => a.blkReceiverName - b.blkReceiverName,
				multiple: 1,
			},
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'blkPaymentAmount',
			sorter: {
				compare: (a, b) => a.blkPaymentAmount - b.blkPaymentAmount,
				multiple: 1,
			},
		},
		{
			title: 'Payment Mode',
			render: () => <span>{selectedApprovalObj?.blkuPaymentMode}</span>,
		},
		{
			title: 'Payment Type',
			dataIndex: 'blkuType',
			render: () => <span>{selectedApprovalObj?.blkuType}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'blkStatus',
			sorter: {
				compare: (a, b) => a.blkStatus - b.blkStatus,
				multiple: 1,
			},
		},
	];

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	useEffect(() => {}, [selectedApprovalObj]);

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={data}
					onChange={onChange}
					loading={approveLoading}
				/>
			</section>
		</div>
	);
};
export default ApprovalPayDetailTable;
