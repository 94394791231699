import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import CorporateHome from './pages/corporate-home/CorporateHome';
import './App.css';
import Summary from './pages/collections/Summary';
import Incoming from './pages/collections/Incoming';
import FundsTransfer from './pages/collections/FundsTransfer';
import PaySummary from './pages/payments/Summary';
import DirectPay from './pages/payments/DirectPayment';
import PayFundsTransfer from './pages/payments/PayFundsTransfer';
import MainAcc from './pages/accounts/MainAcc';
import Organizations from './pages/setup/Organizations';
import Branches from './pages/setup/Branches';
import OrgUserTable from './pages/setup/OrgUsersTable';
import Home from './pages/home/Home';
import BulkPayment from './pages/payments/BulkPayment';
import Reports from './pages/reports/Reports';
import Profile from './pages/accounts/Profile';
import { useDispatch, useSelector } from 'react-redux';
import PartnerSingUp from './pages/sign-up/signup_partner/signup';
import SubAccount from './pages/accounts/Account';
import Approvals from './pages/approval/Approve';
import CreateEditBranch from './pages/setup/CreateEditBrunch';
import SendResetPassword from './pages/resets/SendResetPassword';
import NewPassword from './pages/resets/NewPassword';
import ColApprovals from './pages/approval/ColApprovals';
import PayApprovals from './pages/approval/PayApprovals';
import Config from './pages/configs/Config';
import FundApprovals from './pages/approval/FundApproval';
import { Button, Modal, message } from 'antd';
import UserManagement from './pages/users/UserManagement';
import Users from './pages/users/Users';
import DisplayPdf from './pages/reports/DisplayPdf';
import {
	fetchOrganizations,
	orgCleanUp,
} from './features/organization/organizationSlice';
import { fetchCurrentUser } from './features/auth/authSlice';
import AccountPaidIn from './pages/reports/AccountPaidIn';
import AccountPaidOut from './pages/reports/AccountPaidOut';
import Paybill from './pages/paybill/Paybill';
import { approveCleanUp } from './features/approve/approveSlice';
import { branchCleanUp } from './features/branch/branchSlice';
import { collectionCleanUp } from './features/collections/collectionSlice';
import { globalCleanUp } from './features/global/globalSlice';
import { paymentCleanUp } from './features/payment/paymentSlice';
import { receiptCleanUp } from './features/receipt/receiptSlice';
import { reportCleanUp } from './features/reports/reportsSlice';
import { walletCleanUp } from './features/wallet/walletSlice';
import { navCleanUp } from './features/nav/navSlice';

function App() {
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector((state) => state.auth);
	const { organizations } = useSelector((state) => state.org);

	let org = organizations?.length && organizations[0];

	const [isAccountType, setIsAccountType] = useState('');

	const [isUserLoggedIn, setisUserLoggedIn] = useState(isLoggedIn);

	message.config({
		duration: 10,
		maxCount: 3,
	});

	async function logUserOut() {
		await dispatch(approveCleanUp());
		await dispatch(branchCleanUp());
		await dispatch(collectionCleanUp());
		await dispatch(globalCleanUp());
		await dispatch(orgCleanUp());
		await dispatch(paymentCleanUp());
		await dispatch(receiptCleanUp());
		await dispatch(reportCleanUp());
		await dispatch(walletCleanUp());
		// await dispatch(logout());
		await dispatch(navCleanUp());
		await sessionStorage.clear();
		await localStorage.clear();
		window.location.href = './logout';
	}

	async function handleFetchUserInfo() {
		const res = await dispatch(fetchCurrentUser());
		if (res?.payload?.success) {
			await dispatch(fetchOrganizations());
			setisUserLoggedIn(true);
		}
	}

	useEffect(() => {}, [isUserLoggedIn, organizations]);

	useEffect(() => {
		setisUserLoggedIn(isLoggedIn);
	}, [isLoggedIn]);

	useEffect(() => {
		handleFetchUserInfo();
	}, []);

	if (org?.orgStatus === 'INACTIVE') {
		return (
			<DashboardLayout
				isAccountType={isAccountType}
				setAccountType={setIsAccountType}
				isSideBarEnabled={isUserLoggedIn}>
				<Modal
					centered
					className='modal-btn mod-height mod-footer'
					title='Organization Status'
					open={true}
					onCancel={null}
					width={850}
					footer={false}>
					<div className='w-full flex justify-center flex-col items-center'>
						<p className='text-black3 text-[24px] text-center w-full font-medium p-7'>
							Account is inactive. Please wait for account activation and
							verification
						</p>

						<div className='flex items-center mt-[3.5rem]'>
							<Button
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={logUserOut}>
								Logout
							</Button>

							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleFetchUserInfo()}>
								Retry
							</Button>
						</div>
					</div>
				</Modal>
			</DashboardLayout>
		);
	}

	return (
		<div className='wrapper'>
			<DashboardLayout
				isAccountType={isAccountType}
				setAccountType={setIsAccountType}
				isSideBarEnabled={isUserLoggedIn}>
				<Routes>
					<Route exact path='/home' element={<Home />} />
					{/* <Route path='*' element={<Navigate to='/login' replace />} /> */}
					<Route exact path='/sign-up' element={<PartnerSingUp />} />
					{/* <Route exact path='/login' element={<PartnerLogin />} /> */}
					<Route exact path='/reset-password' element={<SendResetPassword />} />
					<Route exact path='/new-password.action' element={<NewPassword />} />
					{/* <Route
            exact
            path="/"
            element={<Navigate to="/dashboard" replace />}
          /> */}
					<Route exact path='/dashboard' element={<CorporateHome />} />
					<Route path='*' element={<Navigate to='/dashboard' replace />} />
					<Route exact path='/collections' element={<CorporateHome />} />
					<Route exact path='/collections/summary' element={<Summary />} />
					<Route exact path='/payments/summary' element={<PaySummary />} />
					<Route
						exact
						path='/payments/direct-payments'
						element={<DirectPay />}
					/>
					<Route exact path='/collections/incoming' element={<Incoming />} />
					<Route
						exact
						path='/collections/approval-one'
						element={<ColApprovals />}
					/>
					<Route
						exact
						path='/collections/approval-two'
						element={<ColApprovals />}
					/>
					<Route
						exact
						path='/collections/approval-three'
						element={<ColApprovals />}
					/>
					<Route
						exact
						path='/collections/funds-transfer'
						element={<FundsTransfer />}
					/>
					<Route
						exact
						path='/payments/funds-transfer'
						element={<PayFundsTransfer />}
					/>
					<Route
						exact
						path='/payments/bulk-payments'
						element={<BulkPayment />}
					/>
					<Route
						exact
						path='/payments/approval-one'
						element={<PayApprovals />}
					/>
					<Route
						exact
						path='/payments/approval-two'
						element={<PayApprovals />}
					/>
					<Route
						exact
						path='/payments/approval-three'
						element={<PayApprovals />}
					/>

					<Route
						exact
						path='/payments/funds-transfer/approval-one'
						element={<FundApprovals />}
					/>
					<Route
						exact
						path='/payments/funds-transfer/approval-two'
						element={<FundApprovals />}
					/>
					<Route
						exact
						path='/payments/funds-transfer/approval-three'
						element={<FundApprovals />}
					/>

					<Route exact path='/payments' element={<CorporateHome />} />
					<Route exact path='/reports' element={<Reports />} />
					<Route exact path='/display-pdf' element={<DisplayPdf />} />
					<Route exact path='/paid-in' element={<AccountPaidIn />} />
					<Route exact path='/paid-out' element={<AccountPaidOut />} />

					<Route exact path='/wallets' element={<MainAcc />} />
					<Route exact path='/accounts/sub-account' element={<SubAccount />} />
					<Route exact path='/profile' element={<Profile />} />
					<Route exact path='/user-management' element={<UserManagement />} />
					<Route exact path='/users' element={<Users />} />
					<Route exact path='/approvals' element={<Approvals />} />
					<Route
						exact
						path='/setup/branch/:type'
						element={<CreateEditBranch />}
					/>
					<Route
						exact
						path='/setup/branch/:name/edit'
						element={<CreateEditBranch />}
					/>
					<Route
						exact
						path='/setup/organizations'
						element={<Organizations />}
					/>
					<Route
						exact
						path='/setup/:organization/branches'
						element={<Branches />}
					/>
					<Route
						exact
						path='/setup/branch/overview'
						element={<OrgUserTable />}
					/>
					<Route exact path='/configurations' element={<Config />} />
					<Route exact path='/paybill' element={<Paybill />} />
				</Routes>
			</DashboardLayout>
		</div>
	);
}

export default App;
