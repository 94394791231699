import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	showUser: false,
	userSelected: {},
};

export const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		toggleShowUser: (state, action) => {
			state.showUser = true;
			state.userSelected = action?.payload;
		},
		closeShowUser: (state) => {
			state.showUser = false;
			state.userSelected = {};
		},
		reportCleanUp: (state) => {
			state.showUser = false;
			state.userSelected = {};
		},
	},
});

export default reportsSlice.reducer;
export const { toggleShowUser, closeShowUser, reportCleanUp } = reportsSlice.actions;
