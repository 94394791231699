import { Form, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function OTPForm({handleOtpVerified}) {
	const [otpCode, setotpCode] = useState({});

	const handleChange = (e) => {
		setotpCode((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const handleVerifyOtp = async () => {
		let fullOtp = otpCode?.first + otpCode?.second + otpCode?.third + otpCode?.fourth;
		handleOtpVerified(fullOtp)
	};

	function OTPInput() {
		const inputs = document.querySelectorAll('#otp > *[id]');
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].addEventListener('keydown', function (event) {
				if (event.key === 'Backspace') {
					inputs[i].value = '';
					if (i !== 0) inputs[i - 1].focus();
				} else {
					if (i === inputs.length - 1 && inputs[i].value !== '') {
						return true;
					} else if (event.keyCode > 47 && event.keyCode < 58) {
						inputs[i].value = event.key;
						handleChange(event)
						if (i !== inputs.length - 1) inputs[i + 1].focus();
						event.preventDefault();
					} else if (event.keyCode > 64 && event.keyCode < 91) {
						inputs[i].value = String.fromCharCode(event.keyCode);
						handleChange(event)
						if (i !== inputs.length - 1) inputs[i + 1].focus();
						event.preventDefault();
					}
				}
			});
		}
	}

	useEffect(() => {
		OTPInput();
	}, []);

	useEffect(() => {
		handleVerifyOtp();
	}, [otpCode]);

	return (
		<>
			<div className='flex items-center justify-around mx-10' id='otp'>
				<input
					autoComplete='off'
					onChange={(e) => handleChange(e)}
					maxLength='1'
					id='first'
					name='first'
					type='text'
					className='w-[83px] outline-0 focus:outline-0 focus:border-b-2 focus:border-r-0 focus:ring-0 border-b-2 border-b-black border-t-0 border-r-0 border-l-0 text-center'
				/>
				<input
					autoComplete='off'
					onChange={(e) => handleChange(e)}
					maxLength='1'
					id='second'
					name='second'
					type='text'
					className='w-[83px] outline-0 focus:outline-0 focus:border-b-2 focus:border-r-0 focus:ring-0 border-b-2 border-b-black border-t-0 border-r-0 border-l-0'
				/>
				<input
					autoComplete='off'
					onChange={(e) => handleChange(e)}
					maxLength='1'
					id='third'
					name='third'
					type='text'
					className='w-[83px] outline-0 focus:outline-0 focus:border-b-2 focus:border-r-0 focus:ring-0 border-b-2 border-b-black border-t-0 border-r-0 border-l-0'
				/>
				<input
					autoComplete='off'
					onChange={(e) => handleChange(e)}
					maxLength='1'
					id='fourth'
					name='fourth'
					type='text'
					className='w-[83px] outline-0 focus:outline-0 focus:border-b-2 focus:border-r-0 focus:ring-0 border-b-2 border-b-black border-t-0 border-r-0 border-l-0'
				/>
			</div>
		</>
	);
}
