import React, { useEffect, useState } from 'react';
import { Button, Drawer, Radio, Space, Spin, message } from 'antd';
import ReplayIcon from '@mui/icons-material/Replay';
import PrintIcon from '@mui/icons-material/Print';
import PayDetailTable from '../../../components/global/PayDetailTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	approveDisbursement,
	closeSelectedApprovalId,
	fetchBulkPaymentDetails,
	fetchPendingPayApp,
} from '../../../features/approve/approveSlice';
import ApprovalPayDetailTable from '../../../components/global/ApprovalPayDetailTable';

const ApprovalDetailsDrawer = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const {
		openSelectedApproval,
		payApprovalDetails,
		selectedApprovalObj,
		approveLoading,
	} = useSelector((state) => state.approve);

	const location = window.location.href;
	const pathname = location.substring(location.lastIndexOf('/') + 1);

	const onClose = () => {
		dispatch(closeSelectedApprovalId());
	};

	async function handleFetchBulkUploadDetails() {
		const usrObj = {
			usrId: user?.usrId,
			blkBlkuId: selectedApprovalObj?.blkuId,
		};
		await dispatch(fetchBulkPaymentDetails(usrObj));
	}

	async function handleConfirmApproval() {
		const usrObj = {
			usrId: user?.usrId,
			blkuId: selectedApprovalObj?.blkuId,
			blkuApprovalLevel:
				pathname === 'approval-one' ? 0 : pathname === 'approval-two' ? 1 : 2,
		};
		const res = await dispatch(approveDisbursement(usrObj));
		if (res?.payload?.success) {
			message.success(res?.payload?.messages?.message);
			await dispatch(fetchPendingPayApp(usrObj));
			onClose();
		}else{
			message.info(res?.payload?.messages?.message);
		}
	}

	useEffect(() => {
		handleFetchBulkUploadDetails();
	}, [selectedApprovalObj]);

	useEffect(() => {}, [
		openSelectedApproval,
		payApprovalDetails,
		selectedApprovalObj,
	]);

	return (
		<>
			<Drawer
				title={<h3 className='table-heads'>Payment Details</h3>}
				placement={'bottom'}
				closable={false}
				onClose={onClose}
				open={openSelectedApproval}
				key={1}
			>
				<div>
					<ApprovalPayDetailTable data={payApprovalDetails} />
				</div>

				<div className='flex items-center justify-end text-[#222222] mt-5'>
					<button
						onClick={onClose}
						className='p-1'
						style={{
							background: '#D3D3D9',
							color: '#212143',
							fontWeight: 500,
							fontSize: '14px',
						}}
					>
						Close
					</button>
					<button
						disabled={approveLoading}
						onClick={() => handleConfirmApproval('yes')}
						style={{
							background: '#10101E',
							color: '#fff',
							fontWeight: 500,
							fontSize: '14px',
							marginLeft: '10px',
						}}
						className='ml-2 p-1'
					>
						{approveLoading ? <Spin /> : 'Confirm'}
					</button>
				</div>
			</Drawer>
		</>
	);
};
export default ApprovalDetailsDrawer;
