import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	message,
	Tooltip,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBulkUpload } from '../../../features/payment/paymentSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';
import { CloudDownloadOutlined } from '@ant-design/icons';
import bankTemplate from '../../../assets/NDOVUPAYBANKTEMPLATE.xlsx';
import mpesaTemplate from '../../../assets/NDOVUPAYMPESATEMPLATE.xlsx';
import axios from 'axios';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DeleteOutlined } from '@mui/icons-material';
import {
	fetchBranchWallets,
	fetchWallets,
} from '../../../features/wallet/walletSlice';

const url = process.env.REACT_APP_API_BASE_URL;

const { Option } = Select;

const PayBulkModal = ({ open, closeModalOne, prefetchData }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const { user } = useSelector((state) => state.auth);
	const { disbursementWallets, walletLoading, branchWallets } = useSelector(
		(state) => state.wallet
	);
	const { payLoading } = useSelector((state) => state.payment);
	const { branches, branchLoading } = useSelector((state) => state.branch);

	const formRef = React.useRef(null);

	const [current, setCurrent] = useState(0);
	const [formData, setformData] = useState(null);
	const [data, setdata] = useState({});
	const [otpVerified, setotpVerified] = useState('');

	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [activeBranch, setactiveBranch] = useState();

	const usrObj = {
		usrId: user?.usrId,
		blkuMethod: 'BULK',
		brnId: activeBranch?.brnId ?? user?.brnId,
		orgId: user?.orgId,
	};

	let srcName = disbursementWallets?.find(
		(item) => item?.walCode == data?.paySourceAccId
	);

	function handleOtpVerified(val) {
		setotpVerified(val);
	}

	const next = async () => {
		const otpObj = {
			usrId: user?.usrId,
		};
		if (current === 0) {
			if (data?.payMethod === 'BANK') {
				if (
					data?.blkuBankType === '' ||
					data?.blkuBankType == null ||
					data?.blkuBankType === undefined
				) {
					return message.info('Please fill in the required fields');
				}
			}
			if (
				formData === null ||
				formData === undefined ||
				formData === '' ||
				data?.filename === '' ||
				data?.filename == null ||
				data?.filename === undefined ||
				data?.fileDescription === '' ||
				data?.fileDescription == null ||
				data?.fileDescription === undefined ||
				data?.payMethod === undefined ||
				data?.paySourceAccId === undefined
			) {
				return message.info('Please fill in the required fields');
			} else {
				setCurrent(current + 1);
			}
		} else if (current === 1) {
			const res = await dispatch(sendTransOtp(otpObj));
			if (res?.payload?.success) {
				setCurrent(current + 1);
			} else {
				message.info('We cannot complete your request at the moment');
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
		setformData(null);
		setdata({});
		form.resetFields();
		form.setFieldsValue({
			filename: '',
			fileDescription: '',
		});
	}

	function handleReset() {
		setCurrent(0);
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			payMethod: value,
		}));
	}

	function handleSelectChange3(value) {
		setdata((prevData) => ({
			...prevData,
			blkuBankType: value,
		}));
	}

	function handleSelect(value, fileName) {
		setdata((prevData) => ({
			...prevData,
			[fileName]: value,
		}));
	}

	const onFinish = async () => {
		let paymode;

		data.file = formData;
		data.usrId = user?.usrId;
		const myForm = new FormData();
		myForm.append('file', formData);

		const otpObj = {
			usrId: user?.usrId,
			usrSalt: otpVerified,
		};

		if (data?.payMethod === 'BANK') {
			paymode = data?.blkuBankType;
		} else {
			paymode = 'MPESA';
		}

		if (otpVerified?.length === 4) {
			const resp = await dispatch(verifyTransOtp(otpObj));
			if (resp?.payload?.success) {
				const res = await axios
					.post(
						`${url}/web/uploadbulkPayment.action?usrId=${user?.usrId}&filename=${data?.filename}&fileDescription=${data?.fileDescription}&blkuType=${data?.payMethod}&blkuWalcode=${data?.paySourceAccId}&blkuBankType=${paymode}`,
						myForm
					)
					.then((res) => res.data);					

				if (res?.success) {
					setisSuccess(true);
					setresponse(res?.messages?.message);
					const resp = await dispatch(fetchBulkUpload(usrObj));
					if (resp?.payload?.success) {
						prefetchData();
					}
				} else {
					setisSuccess(false);
					setresponse(res?.messages?.message);
				}
				await setCurrent(3);
				// await setCurrent(current + 1);
			} else {
				return message.error('Could not verify code. Please try again');
			}
		} else {
			return message.info('Enter the four digit verification code');
		}
	};

	async function handleFetchWallets() {
		await dispatch(fetchWallets(usrObj));
	}

	async function handleBranchChange(brnId) {
		await setdata({ ...data, paySourceAccId: null });
		let actvBrn = await branches?.find((item) => item?.brnId === brnId);
		await setactiveBranch(actvBrn);
		await dispatch(fetchBranchWallets({ ...usrObj, brnId: actvBrn?.brnId }));
	}

	async function handleCancel() {
		await form.resetFields();
		setdata({});
		setformData(null);
		closeModalOne();
	}

	useEffect(() => {}, [
		data,
		branches,
		branchWallets,
		activeBranch,
		disbursementWallets,
	]);

	useEffect(() => {
		handleFetchWallets();
	}, []);
	

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<Form.Item required name='filename' label='File Name'>
						<Input
							name='filename'
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						required
						name='fileDescription'
						label='Reason for transfer'>
						<Input
							name='fileDescription'
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item label=''>
						{Number(user?.usrRoleId) === 0 ? (
							<div className=''>
								<label className='mb-1 flex items-center' for='waltTo'>
									<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
										*
									</span>{' '}
									Payment Source
								</label>
								<div className='flex items-center'>
									{branches?.length > 1 ? (
										<Select
											value={activeBranch?.brnId}
											loading={branchLoading}
											onChange={(e) => handleBranchChange(e)}
											defaultValue='Select Branch'
											style={{
												width: '190px',
												marginRight: 20,
											}}>
											{branches?.map((item) => {
												return (
													<>
														<Option value={item?.brnId}>{item?.brnName}</Option>
													</>
												);
											})}
										</Select>
									) : null}

									<Select
										value={data?.paySourceAccId}
										loading={walletLoading}
										defaultValue='Choose'
										style={{
											width: '100%',
										}}
										onChange={(e) => handleSelect(e, 'paySourceAccId')}>
										{branchWallets
											?.filter((item) => item.walType === 'DISBURSEMENT')
											?.map((item) => {
												return (
													<>
														<Option
															value={
																item?.walCode
															}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
													</>
												);
											})}
									</Select>
								</div>
							</div>
						) : (
							<div className=''>
								<label className='mb-1 flex items-center' for='waltTo'>
									<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
										*
									</span>{' '}
									Payment Source
								</label>
								<Select
									value={data?.paySourceAccId}
									loading={walletLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}
									onChange={(e) => handleSelect(e, 'paySourceAccId')}>
									{disbursementWallets?.map((item) => {
										return (
											<>
												<Option
													value={
														item?.walCode
													}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
											</>
										);
									})}
								</Select>
							</div>
						)}
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Choose method of payment',
							},
						]}
						name={'payMethod'}
						label='Payment Method'>
						<Select
							name={'payMethod'}
							defaultValue='Choose Payment Method'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange2(e)}>
							<Option value='BANK'>Bank</Option>
							<Option value='MPESA'>M-Pesa</Option>
						</Select>
					</Form.Item>

					{data?.payMethod === 'BANK' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Choose mode of payment',
									},
								]}
								name={'blkuBankType'}
								label='Payment Mode'>
								<Select
									onChange={handleSelectChange3}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}>
									<Option value={'PESALINK'}>PesaLink</Option>
									<Option value={'SWIFT'}>Swift</Option>
									<Option value={'EFT '}>EFT </Option>
									<Option value={'AAT'}>AAT</Option>
								</Select>
							</Form.Item>
							<a href={bankTemplate} type='button' download>
								<div className=' flex items-end'>
									<Tooltip title='Download file '>
										<Button
											className='!border-none !w-fit'
											shape=''
											icon={
												<CloudDownloadOutlined className='!text-[40px] text-[#010101]' />
											}
										/>
									</Tooltip>
									<span className='ml-3 text-[15px] font-bold'>
										Click to download bank template
									</span>
								</div>
							</a>
						</>
					) : data?.payMethod === 'MPESA' ? (
						<>
							<a href={mpesaTemplate} type='button' download>
								<div className=' flex items-end'>
									<Tooltip title='Download file '>
										<Button
											className='!border-none !w-fit'
											shape=''
											icon={
												<CloudDownloadOutlined className='!text-[40px] text-[#010101]' />
											}
										/>
									</Tooltip>
									<span className='ml-3 text-[15px] font-bold'>
										Click to download{' '}
										{data?.payMethod === 'BANK' ? 'bank' : 'mpesa'} template
									</span>
								</div>
							</a>
						</>
					) : null}

					<section>
						<form action='' method='POST' enctype='multipart/form-data'>
							<div className='container mt-4'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='form-group'>
											<div className='preview-zone hidden'>
												<div className='box box-solid'>
													<div className='box-header with-border'>
														<div>
															<b>Preview</b>
														</div>
														<div className='box-tools pull-right'>
															<button
																type='button'
																className='btn btn-danger btn-xs remove-preview'>
																<i className='fa fa-times'></i> Reset This Form
															</button>
														</div>
													</div>
													<div className='box-body'></div>
												</div>
											</div>
											<div className='dropzone-wrapper'>
												<div className='dropzone-desc'>
													<CloudUploadOutlinedIcon sx={{ fontSize: '24px' }} />
													<p className='ant-upload-text text-[#7E93AE] text-[14px] font-[400]'>
														Drag & Drop or{' '}
														<span className='text-[#010101] font-[500]'>
															Choose file
														</span>{' '}
														to upload
													</p>
													<p className='ant-upload-hint text-[#010101] font-[500]'>
														.xlsx
													</p>
												</div>
												<input
													accept='.xls,.xlsx'
													onChange={(e) => setformData(e.target.files[0])}
													type='file'
													name='file'
													className='dropzone'
												/>
											</div>

											<div className='flex items-center justify-between my-2'>
												<p className='text-[16px] f0nt-[600] text-[#010101] w-[80%] truncate'>
													{formData?.name}
												</p>
												<DeleteOutlined
													onClick={() => setformData(null)}
													className='text-[red] text-[25px] cursor-pointer'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-md-12'>
										{/* <button type='submit' className='btn btn-primary pull-right'>
											Upload
										</button> */}
									</div>
								</div>
							</div>
						</form>
					</section>

					{/* </Form.Item> */}
				</>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					<p className='text-[15px] text-[#000] font-[600] mb-8'>
						Would you like to make the following transfer?
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						File Name: <span className='font-[400]'> {data?.filename}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Method:{' '}
						<span className='font-[400]'> {data?.payMethod}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Description:{' '}
						<span className='font-[400]'> {data?.fileDescription}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Source:{' '}
						<span className='font-[400]'>
							{' '}
							{srcName?.walDesc} - {srcName?.walType}
						</span>
					</p>
				</div>
			),
		},
		{
			title: 'Second',
			content: (
				<div className='flex flex-col justify-center mt-10'>
					<p className='text-center mb-10'>
						Enter the 4 digit code sent to you
					</p>
					<OTPForm handleOtpVerified={handleOtpVerified} />
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-[179px]'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Upload Bulk Payment'
						: current === 1
						? 'Verification'
						: 'Transfer Status'
				}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}>
										{payLoading ? <Spin /> : 'Previous'}
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}>
										{payLoading ? <Spin /> : 'Next'}
									</Button>
								)}

								{current === 2 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										type='primary'
										htmlType='submit'
										// onClick={() => transferFund()}
									>
										{payLoading ? <Spin /> : 'Transfer'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default PayBulkModal;
