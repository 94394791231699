import React, { useEffect, useState } from 'react';
import { Button, Drawer, Radio, Space } from 'antd';
import ReplayIcon from '@mui/icons-material/Replay';
import PrintIcon from '@mui/icons-material/Print';
import PayDetailTable from '../../../components/global/PayDetailTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	closeSelectedBulk,
	fetchBulkUploadPayments,
} from '../../../features/payment/paymentSlice';

const BulkPayDetailsDrawer = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { openSelectedbulk, bulkUploadDetails, selectedbulk } = useSelector(
		(state) => state.payment,
	);

	const onClose = () => {
		dispatch(closeSelectedBulk());
	};

	async function handleFetchBulkUploadDetails() {
		const usrObj = {
			usrId: user?.usrId,
			blkBlkuId: selectedbulk,
		};
		await dispatch(fetchBulkUploadPayments(usrObj));
	}

	useEffect(() => {
		handleFetchBulkUploadDetails();
	}, [selectedbulk]);

	useEffect(() => {}, [bulkUploadDetails, openSelectedbulk, selectedbulk]);

	return (
		<>
			<Drawer
				title={<h3 className='table-heads'>Payment Details</h3>}
				placement={'bottom'}
				closable={false}
				onClose={onClose}
				open={openSelectedbulk}
				key={1}
			>
				{/* <div className='flex items-center justify-end text-[#222222]'>
					<div className='flex items-center'>
						<div className='flex items-center'>
							<ReplayIcon className='mr-[8px] text-[26px]' />
							<span>Cancel for one account</span>
						</div>

						<div className='flex items-center ml-[39px]'>
							<PrintIcon className='mr-[8px] text-[26px]' />
							<span>Print</span>
						</div>
					</div>
				</div> */}

				<div className='w-full'>
					<PayDetailTable data={bulkUploadDetails} />
				</div>
			</Drawer>
		</>
	);
};
export default BulkPayDetailsDrawer;
