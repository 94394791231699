import { Input } from '@mui/joy';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Form, Select } from 'antd';
import { counties } from '../../../../counties';

const { Option } = Select;

function Institution({ updateFormData, formData, next }) {
	const [data, setData] = useState(formData);
	const [nextChange, setnextChange] = useState(next);

	const handleChange = (e) => {
		setData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const handleChangePhone2 = (e) => {
		setData((prevData) => ({
			...prevData,
			orgMobile: e,
		}));
	};

	function handleSelectChange(value) {
		setData((prevData) => ({
			...prevData,
			orgCounty: value,
		}));
	}

	useEffect(() => {}, [data]);
	useEffect(() => {
		setnextChange(next);
	}, [next]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography component='h6' sx={{ my: '.5rem', fontWeight: 'bold' }}>
						Organization Name <span className='star'></span>
					</Typography>
					<input
						required
						className='w-full'
						label=''
						type='text'
						value={data.orgName}
						name='orgName'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						size='lg'
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography component='h6' sx={{ my: '.5rem', fontWeight: 'bold' }}>
						Organisation Email <span className='star'></span>
					</Typography>
					<input
						required
						className='w-full'
						value={data?.orgEmail}
						name='orgEmail'
						type='text'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						size='lg'
					/>
				</Grid>

				<Grid item xs={12}>
					<div className='flex flex-col w-full md:flex-row lg:items-center lg:justify-between'>
						<div className='flex flex-col w-full'>
							<Typography
								component='h6'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Organisation Phone <span className='star'></span>
							</Typography>
							<PhoneInput
								defaultCountry='ke'
								country={'ke'}
								required
								searchclassName='search-class'
								searchStyle={{
									margin: '0',
									width: '100% !important',
									height: '100px !important',
								}}
								enableSearchField
								disableSearchIcon
								value={data?.orgMobile}
								onBlur={() => updateFormData(data)}
								onChange={handleChangePhone2}
							/>
						</div>

						<div className='flex flex-col w-full ml-0 mt-3 lg:!mt-0 lg:ml-7'>
							<Typography
								component='h6'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								County <span className='star'></span>
							</Typography>
							<Select
								style={{
									width: '100%',
								}}
								onChange={handleSelectChange}
								placeholder='select one country'
								defaultValue={data.orgCounty ? data.orgCounty : 'Nairobi'}
								onBlur={() => updateFormData(data)}
								// onBlur={() => updateFormData(data)}
								optionLabelProp='label'
							>
								{counties?.map((item) => {
									return (
										<>
											<Option value={item?.name} label={item?.name}>
												{item?.name}
											</Option>
										</>
									);
								})}
							</Select>
						</div>
					</div>
				</Grid>

				{/* <Grid item md={12} xs={12}>
					<Typography component='h6' sx={{ my: '.5rem', fontWeight: 'bold' }}>
						Website <span className='star'></span>
					</Typography>
					<input
						required
						className='w-full'
						type='text'
						value={data?.orgWebsite}
						name='orgWebsite'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						size='lg'
					/>
				</Grid> */}

				<Grid item xs={12}>
					<div className='flex flex-col md:flex-row lg:items-center lg:justify-between'>
						<div className='flex flex-col w-full'>
							<Typography
								component='h6'
								className='fmw-checkbox-label'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Business Registration No.
							</Typography>
							<input
								className='w-full'
								type='text'
								value={data.orgCertificateOfIncorporation}
								name='orgCertificateOfIncorporation'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
						<div className='flex flex-col w-full ml-0 mt-3 lg:!mt-0 lg:ml-7'>
							<Typography
								component='h6'
								className='fmw-checkbox-label'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								KRA Pin
							</Typography>
							<input
								className='w-full'
								minLength={11}
								maxLength={11}
								type='text'
								value={data.orgKraPin}
								name='orgKraPin'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
					</div>
				</Grid>

				<Grid item xs={12}>
					<div className='flex flex-col md:flex-row lg:items-center lg:justify-between'>
						<div className='flex flex-col w-full'>
							<Typography
								component='h6'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Town
							</Typography>
							<input
								className='w-full'
								value={data.orgTown}
								name='orgTown'
								type='text'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>

						<div className='flex flex-col w-full ml-0 mt-3 lg:!mt-0 lg:ml-7'>
							<Typography
								className='font-bold'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Physical Address
							</Typography>
							<input
								className='w-full'
								type='text'
								value={data.orgLocation}
								name='orgLocation'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
					</div>
				</Grid>
			</Grid>
		</>
	);
}

export default Institution;
