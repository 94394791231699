import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import pdf from "../../assets/termspdf.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();


const url = process.env.REACT_APP_API_BASE_URL;
const DisplayPdf = () => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        // <div>
        //     <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        //         <Page pageNumber={pageNumber} />
        //     </Document>
        //     <p>
        //         Page {pageNumber} of {numPages}
        //     </p>
        // </div>

        <>
        dxfcgvhbnjk
        <iframe  
            // loading="lazy"
            title='Generated invoice'
            width={'100%'}
            height={'700px'}
            src={`${url}/usr/directReport.action?reportName=ACCOUNT_PAID_OUT&P_START_DATE=12/12/2023&P_END_DATE=02/05/2026&PDF_FORMAT=inline&P_FORMAT=PDF`}>

            </iframe>
    </>
    )
}

export default DisplayPdf