import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	navLoading: false,
	root: [],
	subRoot: [],
};

export const fetchRoot = createAsyncThunk('navSlice/fetchRoot', (data) => {
	return axios
		.get(`${url}/user/fetchAssignedMenus.action?usrId=${data?.usrId}&node=root`)
		.then((res) => res.data);
});

export const fetchSubRoot = createAsyncThunk(
	'navSlice/fetchSubRoot',
	(data) => {
		return axios
			.get(
				`${url}/user/fetchAssignedMenus.action?usrId=${data?.usrId}&node=${data?.node}`,
			)
			.then((res) => res.data);
	},
);

export const navSlice = createSlice({
	name: 'nav',
	initialState,
	reducers: {
		navCleanUp: (state) => {
			state.navLoading = false;
			state.root = [];
			state.subRoot = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchRoot.pending, (state) => {
				state.navLoading = true;
			})
			.addCase(fetchRoot.fulfilled, (state, action) => {
				state.navLoading = false;
				if (action?.payload?.length) {
					state.root = action?.payload;
				} else {
					state.root = [];
				}
			})
			.addCase(fetchRoot.rejected, (state) => {
				state.navLoading = false;
			})

			.addCase(fetchSubRoot.pending, (state) => {
				state.navLoading = true;
			})
			.addCase(fetchSubRoot.fulfilled, (state, action) => {
				state.navLoading = false;
				if (action?.payload?.length) {
					state.subRoot = action?.payload;
				} else {
					state.subRoot = [];
				}
			})
			.addCase(fetchSubRoot.rejected, (state) => {
				state.navLoading = false;
			});
	},
});

export default navSlice.reducer;
export const { navCleanUp } = navSlice.actions;
