import { React, useEffect, useState } from 'react';
import { Table } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const DirectTable = ({ data }) => {
	const { payLoading } = useSelector((state) => state.payment);
	const [filter, setfilter] = useState('All');

	const [filterData, setfilterData] = useState(data);

	const complete = data?.filter(
		(item) =>
			item.blkuRemoteStatus === 'CBS_COMPLETED' ||
			(item.blkuStatus === 'PROCESSED' && item.blkuRemoteStatus === null),
	);
	const pending = data?.filter((item) => item.blkuStatus === 'PENDING');
	const processing = data?.filter((item) => item.blkuStatus === 'PROCESSING');
	const rejected = data?.filter((item) => item.blkuStatus === 'REJECTED');
	const failed = data?.filter(
		(item) =>
			item?.blkuRemoteStatus === 'FAILED' ||
			item?.blkuRemoteStatus === 'CBS_FAILED' ||
			item?.blkuRemoteStatus === 'ERROR' ||
			item?.blkRemoteStatus === 'FAILED' ||
			item?.blkRemoteStatus === 'CBS_FAILED' ||
			item?.blkRemoteStatus === 'ERROR',
	);

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	useEffect(() => {
		if (filter === 'COMPLETE') {
			setfilterData(complete);
		} else if (filter === 'PENDING') {
			setfilterData(pending);
		} else if (filter === 'PROCESSING') {
			setfilterData(processing);
		} else if (filter === 'REJECTED') {
			setfilterData(rejected);
		} else if (filter === 'FAILED') {
			setfilterData(failed);
		} else {
			setfilterData(data);
		}
	}, [filter]);

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'blkuCreatedTime',
			render: (item) => (
				<span>{moment(item).format('MMM DD YYYY, h:mm A')}</span>
			),
			width: '180px',
		},
		{
			title: 'Source Acc No.',
			dataIndex: 'bkluWalCode',
			sorter: {
				compare: (a, b) => a.bkluWalCode - b.bkluWalCode,
				multiple: 2,
			},
		},
		{
			title: 'Payment Mode',
			dataIndex: 'blkuType',
			key: 'blkuType',
		},
		{
			title: 'Reason For Transfer',
			dataIndex: 'blkuFileDescription',
			key: 'blkuFileDescription',
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'blkuTotalamount',
			sorter: {
				compare: (a, b) => a.blkuTotalamount - b.blkuTotalamount,
				multiple: 1,
			},
		},
		{
			title: 'Initiated By',
			dataIndex: 'usrRequestName',
			sorter: {
				compare: (a, b) => a.usrRequestName - b.usrRequestName,
				multiple: 1,
			},
			render: (item) => <span>{item}</span>,
		},
		{
			title: 'Status',
			render: (item) => (
				<span>
					{item?.blkRemoteStatus
						? item?.blkRemoteStatus + ' - ' + item?.blkRemoteStatusDesc
						: item?.blkuStatus}
				</span>
			),
			sorter: {
				compare: (a, b) => a.blkuStatus - b.blkuStatus,
				multiple: 1,
			},
		},
	];

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<div className='flex items-center mb-4 w-full max-w-full overflow-x-auto'>
					<div
						onClick={() => setfilter('All')}
						className={
							filter === 'All'
								? 'flex items-center p-2 border-2 border-[#146ADC] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#146ADC] !text-[12px]' />
						<span className='table-filter-txt ml-2'>All</span>
					</div>

					<div
						onClick={() => setfilter('COMPLETE')}
						className={
							filter === 'COMPLETE'
								? 'flex items-center p-2 border-2 border-[#2DD683] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#2DD683] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Disbursed</span>
					</div>

					<div
						onClick={() => setfilter('PENDING')}
						className={
							filter === 'PENDING'
								? 'flex items-center p-2 border-2 border-[yellow] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[yellow] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Pending</span>
					</div>

					<div
						onClick={() => setfilter('PROCESSING')}
						className={
							filter === 'PROCESSING'
								? 'flex items-center border-2 border-[#FFAB00] p-2 justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#FFAB00] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Processing</span>
					</div>

					<div
						onClick={() => setfilter('FAILED')}
						className={
							filter === 'FAILED'
								? 'flex items-center border-2 border-[red] p-2 justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[red] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Failed</span>
					</div>
					<div
						onClick={() => setfilter('REJECTED')}
						className={
							filter === 'REJECTED'
								? 'flex items-center border-2 border-[#9D1500] p-2 justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}
					>
						<FiberManualRecordIcon className='text-[#9D1500] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Rejected</span>
					</div>
				</div>

				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={filterData}
					onChange={onChange}
					loading={payLoading}
				/>
			</section>
		</div>
	);
};
export default DirectTable;
