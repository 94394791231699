import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { closeDrawers } from '../../../features/drawer/drawerSlice';
import { Form, Input, InputNumber, Drawer } from 'antd';
const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {
	console.log(values);
};

function AddUserDrw() {
	const { addUserDrawer } = useSelector((state) => state.drawer);
	const dispatch = useDispatch();
	const onClose = () => {
		dispatch(closeDrawers());
	};
	return (
		<>
			<Drawer
				className='!z-[1000]'
				width={450}
				height={200}
				title='Add User'
				placement='right'
				onClose={onClose}
				open={addUserDrawer}
			>
				<p className='text-[13px] font-[600] text-[#000]'>
					Please fill in the details below
				</p>

				<Form
					{...layout}
					name='nest-messages'
					onFinish={onFinish}
					style={{
						maxWidth: 600,
						marginTop: 30,
					}}
					layout={'vertical'}
					validateMessages={validateMessages}
				>
					<Form.Item
						name={['user', 'name']}
						label='Role'
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input
							style={{
								width: '400px',
								borderRadius: '4px',
								border: '1px solid #697A8D',
								height: '58px',
							}}
						/>
					</Form.Item>
					<Form.Item
						name={['user', 'name']}
						label='Name'
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input
							style={{
								width: '400px',
								borderRadius: '4px',
								border: '1px solid #697A8D',
								height: '58px',
							}}
						/>
					</Form.Item>
					<Form.Item
						name={['user', 'email']}
						label='Email'
						rules={[
							{
								type: 'email',
							},
						]}
					>
						<Input
							style={{
								width: '400px',
								borderRadius: '4px',
								border: '1px solid #697A8D',
								height: '58px',
							}}
						/>
					</Form.Item>
					<Form.Item
						name={['user', 'age']}
						label='Phone No'
						rules={[
							{
								type: 'number',
								min: 0,
								max: 99,
							},
						]}
					>
						<InputNumber
							style={{
								width: '400px',
								borderRadius: '4px',
								border: '1px solid #697A8D',
								height: '58px',
							}}
						/>
					</Form.Item>


						<div className='flex justify-center mt-10'>
                        <button className='w-[352px] h-[48px] bg-[#1D191B] text-white' type='submit'>
							Submit
						</button>
                        </div>
				</Form>
			</Drawer>
		</>
	);
}

export default AddUserDrw;
