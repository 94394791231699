import { React, useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

const SumTable = ({ data }) => {
	const { payLoading } = useSelector((state) => state.payment);

	const [filterData, setfilterData] = useState(data);

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'payCreatedTime',
			key: 'payCreatedTime',
			render: (item) => (
				<span className='capitalize'>{moment(item).format('MMM DD YYYY, h:mm A')}</span>
			),
			width: '180px',
		},
		{
			title: 'Reference No.',
			dataIndex: 'payReferenceNo',
			key: 'payReferenceNo',
		},
		{
			title: 'Source Acc',
			dataIndex: 'payPaySourceAccNo',
			key: 'payPaySourceAccNo',
		},
		{
			title: 'Beneficiary',
			render: (item) => (
				<span className='capitalize'>{item?.mpesaName ? item?.mpesaName : item?.payBeneficiaryAccNo + " - " + item?.payBeneficiaryName}</span>
			),
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'payAmount',
			key: 'payAmount',
		},
		{
			title: 'TXN Cost (KES)',
			dataIndex: 'commission',
			key: 'commission',
			render: (item) => (
				<span className='capitalize text-[red] bg-red-200 w-full py-1 px-3'>-{item ? item : 0}</span>
			),
		},
		{
			title: 'Charges (KES)',
			dataIndex: 'charges',
			key: 'charges',
			render: (item) => (
				<span className='capitalize text-[red] bg-red-200 w-full py-1 px-3'>-{item ? item : 0}</span>
			),
		},
		{
			title: 'Payment Type',
			dataIndex: 'payType',
			key: 'payType',
			render: (item) => (
				<span className='capitalize'>{item}</span>
			),
		},
	];

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%'
					}}
					columns={columns}
					dataSource={filterData}
					onChange={onChange}
					loading={payLoading}
				/>
			</section>
		</div>
	);
};
export default SumTable;
