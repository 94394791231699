export const collectionsArray = [
	{
		id: 1,
		date: '09/23/16',
		reference_no: 'A67TTR7098',
		src_acc_no: 676378362,
		src_acc_name: 'Beta World Devs',
		amount: '300999',
		approved_by: 'Abzed Tetz',
		col_type: 'Incoming',
	},
	{
		id: 2,
		date: '11/23/06',
		reference_no: 'A67TTR7098',
		src_acc_no: 3882872,
		src_acc_name: 'Watiaji Sacco',
		amount: '501',
		approved_by: 'Tetman Walking',
		col_type: 'Outgoing',
	},
	{
		id: 3,
		date: '08/22/29',
		reference_no: 'A67TTR7098',
		src_acc_no: 28790998,
		src_acc_name: 'Iende Sana Limited',
		amount: '5000',
		approved_by: 'K. Abzedizo',
		col_type: 'Outgoing',
	},
];

export const paymentsArray = [
	{
		id: 1,
		date: '09/23/16',
		reference_no: 'UYF89937DU',
		src_acc_no: 2898721111,
		src_acc_name: 'Beta World Devs',
		amount: '300',
		approved_by: 'Abzed Tetz',
		ben_acc: 177937663,
		ben_name: 'SPA Limited',
		status: 'Pending',
		col_type: 'Incoming',
		trans_type: 'Direct',
	},
	{
		id: 2,
		date: '12/23/01',
		reference_no: 'HD98D6CD570',
		src_acc_no: 222288882,
		src_acc_name: 'SPA Limited',
		amount: '70000',
		approved_by: 'Daudi Mabati',
		ben_acc: 9876366282,
		ben_name: 'DA Limited',
		status: 'Pending',
		col_type: 'Outgoing',
		trans_type: 'Bulk',
	},
	{
		id: 3,
		date: '01/10/22',
		reference_no: 'A67TTR7098',
		src_acc_no: 987626892,
		src_acc_name: 'Watchers On The Wall',
		amount: '300999',
		approved_by: 'GOT',
		ben_acc: 177937663,
		ben_name: 'Zed Tetz',
		status: 'Approved',
		col_type: 'Incoming',
		trans_type: 'Direct',
	},
	{
		id: 4,
		date: '12/12/12',
		reference_no: '737YEHY79S',
		src_acc_no: 433336337,
		src_acc_name: 'World Bank',
		amount: '1',
		approved_by: 'S.K Snow',
		ben_acc: 177937663,
		ben_name: 'Equity Quiters',
		status: 'Processing',
		col_type: 'Outgoing',
		trans_type: 'Bulk',
	},
];

export const OrganisationsArray = [
	{
		id: 1,
		initial: 'BEWLD',
		name: 'Beta World Developers',
		county: 'Nairobi',
		location: 'CBD',
		email: 'bewld@devs.com',
		phone: '00100100100',
	},
];

export const branchesArray = [
	{
		id: 1,
		name: 'Alpha World Developers',
		county: 'Malindi',
		location: 'Gede',
		email: 'apha@devs.com',
		phone: '007278292',
		org_id: 1,
	},
	{
		id: 2,
		name: 'Kings World Developers',
		county: 'Kisumu',
		location: 'Kisumu',
		email: 'kings@devs.com',
		phone: '02982726728',
		org_id: 1,
	},
];

export const usersArray = [
	{
		id: 1,
		name: 'Daudi Mabatis',
		location: 'CBD',
		email: 'ngtas@devs.com',
		phone: '0973663682',
		permissions: 'Initiate',
		brn_id: 1,
	},
	{
		id: 2,
		name: 'Austin Juls',
		location: 'Karen',
		email: 'juls@devs.com',
		phone: '98763672',
		permissions: 'Initiate, Approve',
		brn_id: 1,
	},
	{
		id: 3,
		name: 'Tetz Tetman',
		location: 'Runda',
		email: 'tetz@devs.com',
		phone: '727365636',
		permissions: 'Approve',
		brn_id: 2,
	},
];

export const permissionsArray = [
	{
		value: 93,
		label: 'User Management',
	},
	{
		value: 94,
		label: 'Reports',
	},
	{
		value: 95,
		label: 'Setup',
	},
	{
		value: 18,
		label: 'Wallets',
	},
	{
		value: 110,
		label: 'Configurations',
	},
	{
		label: <span>Collections</span>,
		title: 'Collections',
		options: [
			{
				value: 114,
				label: 'Summary',
			},
			{
				value: 91,
				label: 'Incoming',
			},
			{
				value: 99,
				label: 'Funds Transfer',
			},
			{
				value: 97,
				label: 'Approval Level One',
			},
			{
				value: 98,
				label: 'Approval Level Two',
			},
			{
				value: 109,
				label: 'Approval Level Three',
			},
		],
	},
	{
		label: <span>Payments</span>,
		title: 'Payments',
		options: [
			{
				value: 42,
				label: 'Summary',
			},
			{
				value: 101,
				label: 'Direct Payments',
			},
			{
				value: 102,
				label: 'Bulk Payments',
			},
			{
				value: 103,
				label: 'Funds Transfer',
			},
			{
				value: 106,
				label: 'Approval Level One',
			},
			{
				value: 107,
				label: 'Approval Level Two',
			},
			{
				value: 108,
				label: 'Approval Level Three',
			},
			{
				value: 111,
				label: 'Funds Transfer Approval Level One',
			},
			{
				value: 112,
				label: 'Funds Transfer Approval Level Two',
			},
			{
				value: 113,
				label: 'Funds Transfer Approval Level Three',
			},
		],
	},
];

export const mpesaTransType = [
	{
		label: 'Mobile Money',
		value: 'MOBILE_MONEY',
	},
	{
		label: 'Paybill',
		value: 'PAYBILL',
	},
	{
		label: 'Till Number',
		value: 'TILL_NUMBER',
	},
];

export const whitelistType = [
	{
		label: 'Paybill',
		value: 'PAYBILL',
	},
	{
		label: 'Till Number',
		value: 'TILL_NUMBER',
	},
];
