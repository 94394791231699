import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	user: {},
	userList: [],
	isLoggedIn: false,
	userDetails: {},
	organization: {},
	activeUser: {},
	selectedActive: false,
	permissions: [],
};

export const createOrganization = createAsyncThunk(
	'authSlice/createOrganization',
	async (data) => {
		const res = await axios.post(`${url}/web/create_user.action`, data);
		return res.data;
	}
);

export const login = createAsyncThunk('authSlice/login', async (data) => {
	const res = await axios.post(
		`${url}/web/post_authenticate_user.action`,
		data
	);
	return res.data;
});

export const verifyPassword = createAsyncThunk(
	'authSlice/verifyPassword',
	async (data) => {
		const res = await axios.post(
			`${url}/web/post_authenticate_user.action`,
			data
		);
		return res.data;
	}
);

export const fetchUsersAdmin = createAsyncThunk(
	'authSlice/fetchUsersAdmin',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/web/fetchUserAdmin.action?usrId=${usrObj?.usrId}`
		);
		return res.data;
	}
);

export const fetchUsers = createAsyncThunk(
	'authSlice/fetchUsers',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/web/fetchUser.action?usrId=${usrObj?.usrId}`
		);
		return res.data;
	}
);

export const createOtherUser = createAsyncThunk(
	'authSlice/createOtherUser',
	async (data) => {
		const res = await axios.post(`${url}/web/create_other_users.action`, data);
		return res.data;
	}
);

export const updateUser = createAsyncThunk(
	'authSlice/updateUser',
	async (data) => {
		const res = await axios.post(`${url}/web/update_user.action`, data);
		return res.data;
	}
);

export const updateUserPassword = createAsyncThunk(
	'authSlice/updateUserPassword',
	async (data) => {
		const res = await axios.post(`${url}/web/updateUserPassword.action`, data);
		return res.data;
	}
);

export const fetchUserPermissions = createAsyncThunk(
	'authSlice/fetchUserPermissions',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/user/fetchAssignedRoles.action?userId=${usrObj?.usrId}&node=app_${usrObj?.app}`
		);
		return res.data;
	}
);

export const updateUserPermissions = createAsyncThunk(
	'authSlice/updateUserPermissions',
	async (data) => {
		const res = await axios.get(
			`${url}/user/assignRole.action?rolesId=${data?.rolesId}&userId=${data?.userId}&nodeValue=${data?.nodeValue}`
		);
		return res.data;
	}
);

export const verifyTransOtp = createAsyncThunk(
	'authSlice/verifyTransOtp',
	async (data) => {
		const res = await axios.post(`${url}/web/verify_code.action`, data);
		return res.data;
	}
);

export const resetPassword = createAsyncThunk(
	'authSlice/resetPassword',
	async (data) => {
		const res = await axios.get(
			`${url}/web/reset_password.action?mobile=${data?.usrEmail}`
		);
		return res.data;
	}
);

export const fetchCurrentUser = createAsyncThunk(
	'authSlice/fetchCurrentUser',
	async () => {
		const res = await axios.get(`${url}/web/fetchCurrentUser.action`);
		return res.data;
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = {};
			state.isLoggedIn = false;
			state.userList = [];
			state.userDetails = {};
			state.organization = {};
			state.activeUser = {};
			state.selectedActive = false;
			state.permissions = [];
		},
		setActiveUser: (state, action) => {
			state.activeUser = action.payload;
		},
		setSelectedActive: (state) => {
			state.selectedActive = true;
		},
		closeSelectedActive: (state) => {
			state.selectedActive = false;
			state.activeUser = {};
		},
		handleLoginSession: (state, action) => {
			state.isLoggedIn = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(login.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.authLoading = false;
				state.user = action?.payload;
				state.isLoggedIn = true;
				message.success('Login successful');
			})
			.addCase(login.rejected, (state) => {
				state.authLoading = false;
				state.user = {};
				state.isLoggedIn = false;
				message.error('Invalid email or password');
			})

			.addCase(createOrganization.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createOrganization.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(createOrganization.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(createOtherUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createOtherUser.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(createOtherUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUsers.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.authLoading = false;
				state.userList = action?.payload?.data?.result;
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUsersAdmin.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUsersAdmin.fulfilled, (state, action) => {
				state.authLoading = false;
				state.userList = action?.payload?.data?.result;
			})
			.addCase(fetchUsersAdmin.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUser.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(updateUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUserPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUserPassword.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(updateUserPassword.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUserPermissions.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUserPermissions.fulfilled, (state, action) => {
				state.authLoading = false;
				state.permissions = action?.payload;
			})
			.addCase(fetchUserPermissions.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUserPermissions.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUserPermissions.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(updateUserPermissions.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifyTransOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyTransOtp.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(verifyTransOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(resetPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(resetPassword.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(resetPassword.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchCurrentUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchCurrentUser.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.user = action?.payload?.jsonData;
					state.isLoggedIn = true;
				} else {
					state.user = {};
					state.isLoggedIn = false;
				}
			})
			.addCase(fetchCurrentUser.rejected, (state) => {
				state.user = {};
				state.isLoggedIn = false;
			})

			.addCase(verifyPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyPassword.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(verifyPassword.rejected, (state) => {
				state.authLoading = false;
			});
	},
});

export default authSlice.reducer;
export const {
	logout,
	setActiveUser,
	setSelectedActive,
	closeSelectedActive,
	handleLoginSession,
} = authSlice.actions;
