import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColIncomingReceipts } from '../../features/collections/collectionSlice';
import { useEffect, useState } from 'react';
import CollectionIncomingTable from '../../components/global/CollectionIncomingTable';
import ColFundModalTwo from './modals/ColFundModalTwo';

export default function Incoming() {
	const dispatch = useDispatch();
	const { colReceipts } = useSelector((state) => state.collection);
	const { user } = useSelector((state) => state.auth);
	const { globalBrnId } = useSelector((state) => state.global);

	const [receipts, setreceipts] = useState(colReceipts);
	const [open, setOpen] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		brnId: globalBrnId ?? user?.brnId,
	};

	function closeModalOne() {
		setOpen(false);
	}

	async function prefetchData() {
		await dispatch(fetchColIncomingReceipts(usrObj));
	}

	function handleUpdateState(arr) {
		setreceipts(arr);
	}

	useEffect(() => {
		prefetchData();
	}, [globalBrnId, user]);

	useEffect(() => {}, [receipts]);

	useEffect(() => {
		setreceipts(colReceipts);
	}, [colReceipts]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<>
			<BreadCrumb
				root={'Collections'}
				current={'Incoming'}
				url={'collections/incoming'}
			/>
			<div className='flex items-center justify-between'>
				<h3 className='table-heads mt-3'>Incoming Payments</h3>
				<button
					onClick={() => setOpen(true)}
					className='bg-[#10101E] w-[fit] h-[33px] p-2 text-white rounded-[6px] text-[14px]'
					type='button'>
					Initiate MPESA STK Payment
				</button>
			</div>

			<div className='mt-7 mb-[5rem] w-full'>
				<h3 className='table-sub-heads mt-3'>Activities</h3>
				<CollectionIncomingTable data={receipts} />
			</div>

			{/* <div className='grid grid-cols-1 lg:grid-cols-4 mt-10'>
				<div className='!border-l-8 border-l-[#2DD683] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<div className='flex items-center justify-between'>
						<span className='text-[#AFAFAF] text-[16px]'>Today's Incoming</span>
						{dailPerc >= 0 ? (
							<div className='flex text-[#2DD683] mr-[11px] px-[8px] py-[8px] bg-[#D8FFEC] rounded-[6px]'>
								<ArrowUpwardIcon className='mt-1 !text-[18px]' />
								<span>
									{Number(dailPerc) === Infinity
										? 100
										: Number(dailPerc)
										? Number(dailPerc)
										: 0}
									%
								</span>
							</div>
						) : (
							<div className='flex text-[red] mr-[11px] px-[8px] py-[8px] bg-red-200 rounded-[6px]'>
								<ArrowDownwardIcon className='mt-1 !text-[18px]' />
								<span>{Number(dailPerc) ? Number(dailPerc) : 0}%</span>
							</div>
						)}
					</div>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						KES {dailyCol ? dailyCol : 0}
					</span>
				</div>
			</div> */}

			<ColFundModalTwo
				open={open}
				closeModalOne={closeModalOne}
				handleUpdateState={handleUpdateState}
			/>
		</>
	);
}
