import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	branchLoading: false,
	branches: [],
	activeBranch: {},
	editBranch: {},
	editingBranch: false,
};

export const fetchBranches = createAsyncThunk(
	'branchSlice/fetchBranches',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetch_branches.action?usrId=${usrObj?.usrId}&orgId=${usrObj?.orgId}`,
			)
			.then((res) => res.data);
	},
);

export const saveBranch = createAsyncThunk(
	'branchSlice/saveBranch',
	(brnObj) => {
		return axios
			.post(`${url}/web/create_branch.action`, brnObj)
			.then((res) => res.data);
	},
);

export const branchSlice = createSlice({
	name: 'branch',
	initialState,
	reducers: {
		setActiveBranch: (state, action) => {
			state.activeBranch = action.payload;
		},
		removeActiveBranch: (state) => {
			state.activeBranch = {};
		},
		setEditBranch: (state, action) => {
			state.editBranch = action.payload;
			state.editingBranch = true;
		},
		removeEditBranch: (state) => {
			state.editBranch = {};
			state.editingBranch = false;
		},
		branchCleanUp: (state) => {
			state.branchLoading = false;
			state.branches = [];
			state.activeBranch = {};
			state.editBranch = {};
			state.editingBranch = false;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchBranches.pending, (state) => {
				state.branchLoading = true;
			})
			.addCase(fetchBranches.fulfilled, (state, action) => {
				state.branchLoading = false;
				state.branches = action.payload;
			})
			.addCase(fetchBranches.rejected, (state) => {
				state.branchLoading = false;
			})

			.addCase(saveBranch.pending, (state) => {
				state.branchLoading = true;
			})
			.addCase(saveBranch.fulfilled, (state) => {
				state.branchLoading = false;
			})
			.addCase(saveBranch.rejected, (state) => {
				state.branchLoading = false;
			});
	},
});

export default branchSlice.reducer;
export const {
	setActiveBranch,
	removeActiveBranch,
	setEditBranch,
	removeEditBranch,
	branchCleanUp,
} = branchSlice.actions;
