import { React } from 'react';
import { Table } from 'antd';

const columns = [
	// {
	// 	title: 'Source Acc No.',
	// 	dataIndex: 'src_acc_no',
	// 	sorter: {
	// 		compare: (a, b) => a.src_acc_no - b.src_acc_no,
	// 		multiple: 2,
	// 	},
	// },
	// {
	// 	title: 'Source Name',
	// 	dataIndex: 'src_acc_name',
	// 	sorter: {
	// 		compare: (a, b) => a.src_acc_name - b.src_acc_name,
	// 		multiple: 1,
	// 	},
	// },
	{
		title: 'Beneficiary Acc.',
		dataIndex: 'blkReceiverMobileNo',
		sorter: {
			compare: (a, b) => a.blkReceiverMobileNo - b.blkReceiverMobileNo,
			multiple: 1,
		},
	},
	{
		title: 'Beneficiary Name',
		dataIndex: 'blkReceiverName',
		sorter: {
			compare: (a, b) => a.blkReceiverName - b.blkReceiverName,
			multiple: 1,
		},
	},
	{
		title: 'Amount (KES)',
		dataIndex: 'blkPaymentAmount',
		sorter: {
			compare: (a, b) => a.blkPaymentAmount - b.blkPaymentAmount,
			multiple: 1,
		},
	},
	{
		title: 'Transfer Reason',
		dataIndex: 'blkPaymentReason',
		sorter: {
			compare: (a, b) => a.blkPaymentReason - b.blkPaymentReason,
			multiple: 1,
		},
	},
	{
		title: 'Status',
		render: (item) => <span>{item?.blkRemoteStatus ? item?.blkRemoteStatus + ' - ' + item?.blkRemoteStatusDesc : item?.blkStatus}</span>,
		sorter: {
			compare: (a, b) => a.blkRemoteStatus - b.blkRemoteStatus,
			multiple: 1,
		},
	},
];

const PayDetailTable = ({ data }) => {
	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%'
					}}
					columns={columns}
					dataSource={data}
					onChange={onChange}
				/>
			</section>
		</div>
	);
};
export default PayDetailTable;
