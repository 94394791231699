import React, { useEffect } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function DashLineChart() {
	const { user } = useSelector((state) => state.auth);
	const { colMonthlySummary } = useSelector((state) => state.collection);
	const { payMonthSummary } = useSelector((state) => state.payment);

	const items = [
		{
			key: '1',
			label: (
				<span className='text-[14px] font-[600] max-w-full truncate'>
					Total Monthly Collections
				</span>
			),
			children: (
				<div>
					<ResponsiveContainer width='100%' height={300}>
						<LineChart
							width={800}
							height={300}
							data={colMonthlySummary}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}>
							<CartesianGrid strokeDasharray='3 3' />
							<XAxis
								dataKey='dates'
								tickFormatter={(unixTime) => moment(unixTime).format('Do')}
							/>
							<YAxis />
							<Tooltip />
							<Legend />
							<Line
								type='monotone'
								dataKey='totalAmount'
								stroke='#8884d8'
								activeDot={{ r: 8 }}
							/>
							{/* <Line type='monotone' dataKey='uv' stroke='#82ca9d' /> */}
						</LineChart>
					</ResponsiveContainer>
				</div>
			),
		},
		{
			key: '2',
			label: (
				<span className='text-[14px] font-[600]'>
					Total Monthly Disbursements
				</span>
			),
			children: (
				<div>
					<ResponsiveContainer width='100%' height={300}>
						<LineChart
							width={800}
							height={300}
							data={payMonthSummary}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}>
							<CartesianGrid strokeDasharray='3 3' />
							<XAxis
								dataKey='dates'
								tickFormatter={(unixTime) => moment(unixTime).format('Do')}
							/>
							<YAxis />
							<Tooltip />
							<Legend />
							<Line
								type='monotone'
								dataKey='totalAmount'
								stroke='#82ca9d'
								activeDot={{ r: 8 }}
							/>
							{/* <Line type='monotone' dataKey='uv' stroke='#82ca9d' /> */}
						</LineChart>
					</ResponsiveContainer>
				</div>
			),
		},
	];

	useEffect(() => {}, [user, colMonthlySummary, payMonthSummary]);

	return (
		<>
			<div className='flex flex-col border-2 border-[#EBEBEB] bg-white rounded-[14px] p-4 mt-10'>
				<div>
					<Tabs className='' defaultActiveKey='1' items={items} />
				</div>
			</div>
		</>
	);
}
