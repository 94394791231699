import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWallets } from '../../features/wallet/walletSlice';
import { useEffect } from 'react';
import {
	fetchColIncomingReceipts,
	fetchCollectionDailySummary,
	fetchColoutgoingReceipts,
} from '../../features/collections/collectionSlice';
import { Tabs } from 'antd';
import CollectionIncomingTable from '../../components/global/CollectionIncomingTable';
import COlOutgoingTable from '../../components/global/ColOutgoingTable';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function Summary() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { wallets } = useSelector((state) => state.wallet);
	const { colReceipts, colDailySummary, colOutgoingReceipts } = useSelector(
		(state) => state.collection
	);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		payType: 'COLLECTION',
		brnId: globalBrnId ?? user?.brnId,
	};

	async function handleFetch() {
		await dispatch(fetchWallets(usrObj));
		await dispatch(fetchColIncomingReceipts(usrObj));
		await dispatch(fetchCollectionDailySummary(usrObj));
		await dispatch(fetchColoutgoingReceipts(usrObj));
	}

	const initialValue = 0;

	const collWallet = wallets?.find((item) => item?.walType === 'COLLECTION');

	const dailyCol =
		colDailySummary?.length &&
		new Date(colDailySummary[0].dates).getDate() === new Date().getDate()
			? colDailySummary[0].totalAmount
			: 0;

	const yesterCol =
		colDailySummary?.length > 1 ? colDailySummary[1].totalAmount : 0;

	const dailPerc = ((dailyCol - yesterCol) / yesterCol) * 100;

	const outgoing = colOutgoingReceipts?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.payAmount,
		initialValue
	);

	useEffect(() => {
		handleFetch();
	}, [globalBrnId, user]);

	useEffect(() => {}, [
		wallets,
		colDailySummary,
		colOutgoingReceipts,
		colReceipts,
		user,
	]);

	return (
		<>
			<BreadCrumb
				root={'Collections'}
				current={'Summary'}
				url={'collections/summary'}
			/>
			<h3 className='table-heads mt-3'>Summary</h3>

			<div className='mt-7 w-full'>
				<h3 className='table-sub-heads mt-3 mb-3'>Activities</h3>

				<Tabs
					defaultActiveKey={1}
					type='card'
					size={'Middle'}
					items={[
						{
							label: (
								<span className='text-[13px] font-[500]'>
									Incoming Payments
								</span>
							),
							key: 1,
							children: <CollectionIncomingTable data={colReceipts} />,
						},
						{
							label: (
								<span className='text-[13px] font-[500]'>
									Outgoing Tranfers
								</span>
							),
							key: 2,
							children: <COlOutgoingTable data={colOutgoingReceipts} />,
						},
					]}
				/>
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-3 mt-10'>
				<div className='!border-l-8 border-l-[#222222] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>
						Current Collection Balance
					</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						{`${collWallet?.walCurrency ?? 'KES'} ${
							collWallet?.walAmount ?? 0
						}`}
					</span>
				</div>

				<div className='!border-l-8 border-l-[#2DD683] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<div className='flex items-center justify-between'>
						<span className='text-[#AFAFAF] text-[16px]'>
							Amount Received Today
						</span>
						{dailPerc >= 0 ? (
							<div className='flex text-[#2DD683] mr-[11px] px-[8px] py-[8px] bg-[#D8FFEC] rounded-[6px]'>
								<ArrowUpwardIcon className='mt-1 !text-[18px]' />
								<span>
									{Number(dailPerc) === Infinity
										? 100
										: Number(dailPerc)
										? Number(dailPerc)
										: 0}
									%
								</span>
							</div>
						) : (
							<div className='flex text-[red] mr-[11px] px-[8px] py-[8px] bg-red-200 rounded-[6px]'>
								<ArrowDownwardIcon className='mt-1 !text-[18px]' />
								<span>{Number(dailPerc) ? Number(dailPerc) : 0}%</span>
							</div>
						)}
					</div>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						KES {dailyCol ? dailyCol : 0}
					</span>
				</div>

				<div className='!border-l-8 border-l-[#FA8B3A] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>
						Total Collection Transfers
					</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						KES {outgoing ? outgoing : 0}
					</span>
				</div>
			</div>

			{/* <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
				<DashRecentTransactions />
			</div> */}
		</>
	);
}
