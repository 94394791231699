import { Input } from '@mui/joy';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';

function Credentials({ updateFormData, formData }) {
	const [error, setError] = useState(false);

	const [data, setData] = useState(formData);
	const [confirmPassword, setConfirmPassword] = useState('');

	const handleChange = (e) => {
		setData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};


	useEffect(() => {}, [data]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						component='h6'
						className='fmw-checkbox-label'
						sx={{ my: '.5rem', fontWeight: 'bold' }}
					>
						Password
					</Typography>
					<input
						className='w-full fmw-input-joy'
						value={data.usrEncryptedPassword}
						name='usrEncryptedPassword'
						type='password'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						
						size='lg'
					/>
				</Grid>
				{/* {usrEmail && error && !focusEmail && <FormHelperText className="upload-sub-label-error">Invalid email address</FormHelperText>} */}
			</Grid>
			<Grid item xs={12} sx={{ mt: 2 }}>
				<Typography sx={{ my: '.5rem', fontWeight: 'bold' }}>
					Confirm password
				</Typography>
				{/* <Input
					label=''
					value={confirmPassword}
					type='password'
					onChange={passwordHandler}
					
					size='lg'
					className='fmw-input-joy'
					error={error}
				/> */}
				<input
					className='w-full fmw-input-joy'
					value={data.usrEncryptedPasswordAlt}
					name='usrEncryptedPasswordAlt'
					type='password'
					onChange={handleChange}
					onBlur={() => updateFormData(data)}
					
					size='lg'
					error={error}
				/>
			</Grid>
		</>
	);
}

export default Credentials;
