import { React, useEffect, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { handleSelectedBulk } from '../../features/payment/paymentSlice';
import { Table } from 'antd';

const BulkUploadTable = ({ data }) => {
	const dispatch = useDispatch();
	const { payLoading } = useSelector((state) => state.payment);

	const [filter, setfilter] = useState('All');
	const [filterData, setfilterData] = useState(data);

	const pending = data?.filter((item) => item.blkuStatus === 'PENDING');
	const rejected = data?.filter((item) => item.blkuStatus === 'REJECTED');
	const processing = data?.filter((item) => item.payStatus === 'PROCESSING');

	function handleBulkChangeId(id) {
		dispatch(handleSelectedBulk(id));
	}

	useEffect(() => {
		if (filter === 'PENDING') {
			setfilterData(pending);
		} else if (filter === 'REJECTED') {
			setfilterData(rejected);
		} else if (filter === 'PROCESSING') {
			setfilterData(processing);
		} else {
			setfilterData(data);
		}
	}, [filter]);

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'blkuCreatedDate',
			render: (item) => <span>{moment(item).format('ll')}</span>,
			width: '180px',
		},
		{
			title: 'File Name',
			dataIndex: 'blkuFileName',
			sorter: {
				compare: (a, b) => a.blkuFileName - b.blkuFileName,
				multiple: 3,
			},
		},
		{
			title: 'Description',
			dataIndex: 'blkuFileDescription',
			sorter: {
				compare: (a, b) => a.blkuFileDescription - b.blkuFileDescription,
				multiple: 2,
			},
		},
		{
			title: 'Request By',
			dataIndex: 'usrRequestName',
			sorter: {
				compare: (a, b) => a.usrRequestName - b.usrRequestName,
			},
		},
		{
			title: 'Status',
			dataIndex: 'blkuStatus',
			sorter: {
				compare: (a, b) => a.blkuStatus - b.blkuStatus,
				multiple: 1,
			},
		},
		{
			title: 'Action',
			dataIndex: 'blkuId',
			render: (item) => (
				<IconButton onClick={() => handleBulkChangeId(item)}>
					<MoreHorizIcon className='text-[#000]' />
				</IconButton>
			),
		},
	];

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<div className='flex items-center mb-4'>
					<div
						onClick={() => setfilter('All')}
						className={
							filter === 'All'
								? 'flex items-center p-2 border-2 border-[#146ADC] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#146ADC] !text-[12px]' />
						<span className='table-filter-txt ml-2'>All</span>
					</div>

					<div
						onClick={() => setfilter('PENDING')}
						className={
							filter === 'PENDING'
								? 'flex items-center p-2 border-2 border-[#9D1500] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#9D1500] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Pending</span>
					</div>

					<div
						onClick={() => setfilter('PROCESSING')}
						className={
							filter === 'PROCESSING'
								? 'flex items-center border-2 border-[#FFAB00] p-2 justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#FFAB00] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Processing</span>
					</div>

					<div
						onClick={() => setfilter('REJECTED')}
						className={
							filter === 'REJECTED'
								? 'flex items-center p-2 border-2 border-[#dc3545] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#dc3545] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Rejected</span>
					</div>
				</div>

				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={filterData}
					onChange={onChange}
					loading={payLoading}
				/>
			</section>
		</div>
	);
};

export default BulkUploadTable;
