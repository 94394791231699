import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	approveLoading: false,
	disbapprovals: [],
	colapprovals: [],
	fundapprovals: [],
	payapprovals: [],
	openSelectedApproval: false,
	payApprovalDetails: [],
	selectedApprovalObj: {},
};

export const fetchPendingDisbApp = createAsyncThunk(
	'approveSlice/fetchPendingDisbApp',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPaymentTransfer.action?usrId=${usrObj?.usrId}&payStatus=PENDING&payType=DISBURSEMENT`,
			)
			.then((res) => res.data);
	},
);

export const fetchPendingColApp = createAsyncThunk(
	'approveSlice/fetchPendingColApp',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchWalletTransfer.action?usrId=${usrObj?.usrId}&waltStatus=PENDING&waltType=COLLECTION&waltApprovalLevels=${usrObj?.waltApprovalLevels}&brnId=${usrObj?.brnId}`,
			)
			.then((res) => res.data);
	},
);

export const fetchPendingFundApp = createAsyncThunk(
	'approveSlice/fetchPendingFundApp',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchWalletTransfer.action?usrId=${usrObj?.usrId}&waltStatus=PENDING&waltType=DISBURSEMENT&waltApprovalLevels=${usrObj?.waltApprovalLevels}`,
			)
			.then((res) => res.data);
	},
);

export const fetchPendingPayApp = createAsyncThunk(
	'approveSlice/fetchPendingPayApp',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPendingPaymentApprovals.action?usrId=${usrObj?.usrId}&blkuApprovalLevel=${usrObj?.blkuApprovalLevel}&brnId=${usrObj?.brnId}`,
			)
			.then((res) => res.data);
	},
);

export const approveDisbursement = createAsyncThunk(
	'approveSlice/approveDisbursement',
	async (data) => {
		const res = await axios.get(
			`${url}/web/approveDisbursementsPayments.action?usrId=${data?.usrId}&blkuId=${data?.blkuId}`,
		);
		return res.data;
	},
);

export const rejectDisbursement = createAsyncThunk(
	'approveSlice/rejectDisbursement',
	async (data) => {
		const res = await axios.get(
			`${url}/web/rejectDisbursementsPayments.action?usrId=${data?.usrId}&blkuId=${data?.blkuId}&blkuRejectedReason=${data?.blkuRejectedReason}`,
		);
		return res.data;
	},
);

export const approveCollection = createAsyncThunk(
	'approveSlice/approveCollection',
	async (data) => {
		const res = await axios.get(
			`${url}/web/approveCollectionPaymentTransfer.action?usrId=${data?.usrId}&waltId=${data?.waltId}`,
		);
		return res.data;
	},
);

export const rejectCollection = createAsyncThunk(
	'approveSlice/rejectCollection',
	async (data) => {
		const res = await axios.get(
			`${url}/web/rejectCollectionPaymentTransfer.action?usrId=${data?.usrId}&waltId=${data?.waltId}`,
		);
		return res.data;
	},
);

export const fetchBulkPaymentDetails = createAsyncThunk(
	'approveSlice/fetchBulkPaymentDetails',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetch_bulk_payments.action?usrId=${usrObj?.usrId}&blkBlkuId=${usrObj?.blkBlkuId}`,
			)
			.then((res) => res.data);
	},
);

export const approveSlice = createSlice({
	name: 'approve',
	initialState,
	reducers: {
		handleSelectedApprovalId: (state, action) => {
			state.selectedApprovalObj = action?.payload;
			state.openSelectedApproval = true;
		},
		closeSelectedApprovalId: (state) => {
			state.selectedApprovalObj = {};
			state.openSelectedApproval = false;
		},
		approveCleanUp: (state) => {
			state.disbapprovals = [];
			state.colapprovals = [];
			state.fundapprovals = [];
			state.payapprovals = [];
			state.payApprovalDetails = [];
			state.selectedApprovalObj = {};
		},
	},

	extraReducers: (builder) => {
		builder

			.addCase(fetchPendingDisbApp.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(fetchPendingDisbApp.fulfilled, (state, action) => {
				state.approveLoading = false;
				state.disbapprovals = action?.payload?.data?.result;
			})
			.addCase(fetchPendingDisbApp.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(fetchPendingColApp.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(fetchPendingColApp.fulfilled, (state, action) => {
				state.approveLoading = false;
				if (action?.payload?.success) {
					state.colapprovals = action?.payload?.data?.result;
				} else {
					state.colapprovals = [];
				}
			})
			.addCase(fetchPendingColApp.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(fetchPendingFundApp.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(fetchPendingFundApp.fulfilled, (state, action) => {
				state.approveLoading = false;
				if (action?.payload?.success) {
					state.fundapprovals = action?.payload?.data?.result;
				} else {
					state.fundapprovals = [];
				}
			})
			.addCase(fetchPendingFundApp.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(approveDisbursement.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(approveDisbursement.fulfilled, (state, action) => {
				state.approveLoading = false;
			})
			.addCase(approveDisbursement.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(rejectDisbursement.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(rejectDisbursement.fulfilled, (state, action) => {
				state.approveLoading = false;
			})
			.addCase(rejectDisbursement.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(approveCollection.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(approveCollection.fulfilled, (state, action) => {
				state.approveLoading = false;
			})
			.addCase(approveCollection.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(rejectCollection.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(rejectCollection.fulfilled, (state, action) => {
				state.approveLoading = false;
			})
			.addCase(rejectCollection.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(fetchPendingPayApp.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(fetchPendingPayApp.fulfilled, (state, action) => {
				state.approveLoading = false;
				if (action?.payload?.success) {
					state.payapprovals = action?.payload?.data?.result;
				} else {
					state.payapprovals = [];
				}
			})
			.addCase(fetchPendingPayApp.rejected, (state) => {
				state.approveLoading = false;
			})

			.addCase(fetchBulkPaymentDetails.pending, (state) => {
				state.approveLoading = true;
			})
			.addCase(fetchBulkPaymentDetails.fulfilled, (state, action) => {
				state.approveLoading = false;
				if (action?.payload?.success) {
					state.payApprovalDetails = action?.payload?.data?.result;
				} else {
					state.payApprovalDetails = [];
				}
			})
			.addCase(fetchBulkPaymentDetails.rejected, (state) => {
				state.approveLoading = false;
			});
	},
});

export default approveSlice.reducer;
export const { handleSelectedApprovalId, closeSelectedApprovalId, approveCleanUp } =
	approveSlice.actions;
