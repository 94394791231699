import { useState } from 'react';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import PaybillPaymentsTable from './tables/PaybillPaymentsTable';
import PayBillModal from './modals/PaybillModal';

export default function Paybill() {
	const [open, setopen] = useState(false);

	function handleClose() {
		setopen(false);
	}

	async function handleFetch() {
		await console.log('Fetching paybills');
	}

	return (
		<>
			<BreadCrumb root={'Paybill'} current={'Paybill'} url={'paybill'} />

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Whitelist Paybill/Till Number</h3>
				<button
					onClick={() => setopen(true)}
					className='bg-[#10101E] w-[132px] h-[33px] text-white rounded-[6px] text-[14px]'
					type='button'>
					Add new
				</button>
			</div>

			<div className='mt-7 w-full'>
				<h3 className='table-sub-heads mt-3'>Whitelisting</h3>
				<PaybillPaymentsTable />
			</div>

			<PayBillModal
				open3={open}
				handleUpdateState={handleFetch}
				closeModalOne={handleClose}
			/>
		</>
	);
}
