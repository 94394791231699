import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import UserTable from '../../components/global/UserTable';
import { useDispatch, useSelector } from 'react-redux';
import { openAddUserDrawer } from '../../features/drawer/drawerSlice';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import BranchTable from '../../components/global/BranchTable';
import OrgTable from '../../components/global/OrgTable';

function Organizations() {
	const { organizations } = useSelector((state) => state.org);

	return (
		<>
			<BreadCrumb
				root={'Setup'}
				current={'Organizations'}
				url={'setup/organizations'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Organization</h3>
			</div>

			<div className='flex flex-col h-[600px] max-h-[700px] justify-between '>
				<div className='mt-4'>
					<OrgTable data={organizations} />
				</div>
			</div>
		</>
	);
}

export default Organizations;
