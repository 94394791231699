import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Modal, Steps, Button, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchBranchWallets,
	fetchWallets,
	saveWallet,
} from '../../../features/wallet/walletSlice';

const { Option } = Select;

const WalletModal = ({ open, closeModalOne }) => {
	const dispatch = useDispatch();
	const { editBranch } = useSelector((state) => state.branch);
	const { user } = useSelector((state) => state.auth);
	const { banks } = useSelector((state) => state.payment);
	const { walletLoading } = useSelector((state) => state.wallet);

	const formRef = React.useRef(null);

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});

	const usrObj = {
		usrId: user?.usrId,
		orgadBrnId: editBranch?.brnId,
		brnId: editBranch?.brnId,
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
	}

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			walTypeCode: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const onFinish = async () => {
		data.walBrnId = editBranch?.brnId;
		data.walAccId = editBranch?.brnId;
		data.walCreatedBy = user?.usrId;

		const res = await dispatch(saveWallet(data));
		if (res?.payload?.success) {
			message.success('Wallet added');
			await setTimeout(() => {
				handleDone();
				dispatch(fetchWallets(usrObj));
				dispatch(fetchBranchWallets(usrObj));
			}, 2000);
		} else {
			message.error('Unable to add your wallet');
		}
	};

	const steps = [
		{
			title: 'Wallet Information',
			key: 'Wallet Info',
			content: (
				<>
					<Form.Item
						name={'walTypeCode'}
						rules={[
							{
								required: true,
								message: 'Select wallet type',
							},
						]}
						label='Wallet Type'
					>
						<Select
							name={'walTypeCode'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange2(e)}
						>
							<Option value={1}>Disbursement </Option>
							<Option value={2}>Collection</Option>
						</Select>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter wallet name',
							},
						]}
						name={'walName'}
						label='Wallet Name'
					>
						<Input
							name={'walName'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: false,
								message: 'Confirmation url for customers',
							},
						]}
						name={'walUrlConfirmation'}
						label='Wallet Confirmation URL'
					>
						<Input
							type='url'
							name={'walUrlConfirmation'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [banks, data]);

	return (
		<>
			<Modal
				title={'Add Wallet Details'}
				centered
				footer={null}
				open={open}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => closeModalOne()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						<div className='flex justify-end'>
							<Button
								style={{
									background: '#D3D3D9',
									color: '#212143',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}
							>
								Cancel
							</Button>

							<Button
								disabled={walletLoading}
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								htmlType='submit'
								type='primary'
							>
								{walletLoading ? <Spin /> : 'Save'}
							</Button>
						</div>
					</Form>
				</>
			</Modal>
		</>
	);
};

export default WalletModal;
