import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Dropdown, Pagination, Radio } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setActiveUser,
	setSelectedActive,
} from '../../features/auth/authSlice';

const UserTable = ({
	organization,
	data,
	handleEditUser,
	handleEditUserBranch,
	filter,
}) => {
	const { activeUser } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState(1);

	function checkSelectedUser(id) {
		if (id === activeUser?.usrId) {
			const isActive = data?.find((item) => item?.usrId === activeUser?.usrId);
			if (isActive) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleUserManagement(usrObj) {
		handleEditUser(true);
		dispatch(setActiveUser(usrObj));
		handleClose();
	}

	const handleNavigate = (branch) => {
		navigate(`/setup/${branch}/branches`);
	};

	function handleSetSelectedActive(usrObj) {
		dispatch(setSelectedActive());
		dispatch(setActiveUser(usrObj));
		handleClose();
	}

	const filterArray = data?.filter((item) => {
		if (filter) {
			return (
				item?.usrFirstName?.toLowerCase().includes(filter?.toLowerCase()) ||
				item?.usrLastName?.toLowerCase().includes(filter?.toLowerCase()) ||
				item?.usrEmail?.toLowerCase().includes(filter?.toLowerCase())
			);
		} else {
			return item;
		}
	});

	useEffect(() => {}, [filter, filterArray, data]);

	return (
		<div className='dash-inner-page mt-7'>
			<section>
				<Table responsive striped className='commonTable'>
					<thead>
						<tr>
							<th>Name</th>
							<th>Role</th>
							<th>Status</th>
							<th>Email</th>
							<th>Phone No.</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{filterArray?.map((item) => {
							const items = [
								organization
									? {
											label: (
												<a onClick={() => handleNavigate(item?.id)}>
													View Info
												</a>
											),
											key: '3',
									  }
									: {
											label: (
												<a onClick={() => handleSetSelectedActive(item)}>
													View Info
												</a>
											),
											key: '0',
									  },
								{
									label: (
										<a onClick={() => handleUserManagement(item)}>
											Manage User
										</a>
									),
									key: '1',
								},
							];
							return (
								<>
									<tr key={item}>
										<td>
											<div className='flex items-center space-x-4'>
												{/* <FiberManualRecordIcon className='!text-[15px] text-[#3BBB6E]' /> */}
												{organization ? null : (
													<Radio
														onChange={() => handleSetSelectedActive(item)}
														checked={checkSelectedUser(item?.usrId)}
													></Radio>
												)}

												<div className='flex-shrink-0'>
													<img
														className='w-[35px] h-[35px] rounded-full'
														src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
														alt='Neil'
													/>
												</div>
												<div className=''>
													<p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
														{item?.usrFullNames}
													</p>
												</div>
											</div>
										</td>
										<td>{Number(item?.usrRoleId) === 1 ? 'Admin' : 'User'}</td>
										<td>{item?.usrEnabled === 1 ? 'Active' : 'Disbabled'}</td>
										<td>{item?.usrEmail}</td>
										<td>{item?.usrMobileNumber}</td>
										<td>
											<Dropdown
												placement='bottom'
												overlayStyle={{
													width: '239px',
													padding: '10px',
												}}
												menu={{
													items,
												}}
												trigger={['click']}
											>
												<a onClick={(e) => e.preventDefault()}>
													<VisibilityIcon className='text-[#000] -mt-1' />
												</a>
											</Dropdown>
											{/* <div>
												<IconButton
													aria-label='more'
													id={item?.usrId}
													aria-controls={open ? 'long-menu' : undefined}
													aria-expanded={open ? 'true' : undefined}
													aria-haspopup='true'
													onClick={handleClick}
												>
													<VisibilityIcon className='text-[#000] -mt-1' />
												</IconButton>
												<Menu
													id='long-menu'
													MenuListProps={{
														'aria-labelledby': 'long-button',
													}}
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													PaperProps={{
														style: {
															width: '289px',
															padding: '10px',
														},
													}}
												>
													{!organization ? (
														<>
															<MenuItem onClick={() => handleSetSelectedActive(item)}>
																View Info
															</MenuItem>
															<MenuItem
																onClick={() => handleUserManagement(item)}
															>
																Manage User
															</MenuItem>
														</>
													) : (
														<>
															<MenuItem
																onClick={() => handleNavigate(item?.id)}
															>
																View Info
															</MenuItem>
														</>
													)}
												</Menu>
											</div> */}
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</Table>

				{/* <div className='flex justify-end'>
					<Pagination defaultCurrent={6} total={data?.length} />
				</div> */}
			</section>
		</div>
	);
};
export default UserTable;
