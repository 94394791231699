import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import SumTable from './tables/SumTable';
import { fetchPaymentSummary } from '../../features/payment/paymentSlice';
import { fetchWallets } from '../../features/wallet/walletSlice';

export default function PaySummary() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { wallets } = useSelector((state) => state.wallet);
	const { paySummary } = useSelector((state) => state.payment);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		brnId: globalBrnId ?? user?.brnId,
	};

	async function prefetchReceipts() {
		await dispatch(fetchPaymentSummary(usrObj));
		await dispatch(fetchWallets(usrObj));
	}
	const disbWallet = wallets?.find((item) => item?.walType === 'DISBURSEMENT');

	const dailyPay = paySummary?.filter(item => new Date(item?.payApprovalDate).getDate() === new Date().getDate())

	const dailySum = dailyPay?.reduce(
		(accumulator, currentValue) => accumulator + currentValue?.payAmount,
		0
	);

	useEffect(() => {
		prefetchReceipts();
	}, [globalBrnId, user]);

	useEffect(() => {}, [wallets, paySummary]);

	return (
		<>
			<BreadCrumb
				root={'Payments'}
				current={'Payments Summary'}
				url={'payments/summary'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>Payments Summary</h3>
			</div>

			<div className='mt-7 w-full'>
				<h3 className='table-sub-heads mt-3'>Activities</h3>
				<SumTable data={paySummary} />
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-3 mt-10'>
				<div className='!border-l-8 border-l-[#222222] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>
						Current Disbursement Balance
					</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>
						{`${disbWallet?.walCurrency ?? 'KES'} ${
							disbWallet?.walAmount ?? 0
						}`}
					</span>
				</div>

				<div className='!border-l-8 border-l-[#FA8B3A] border-2 border-[#E6E9EE] w-full lg:w-[287px] h-[85px] flex flex-col justify-center pl-[31px] rounded-[12px]'>
					<span className='text-[#AFAFAF] text-[16px]'>
						Today's Disbursed Amount
					</span>
					<span className='text-[#15223C] text-[20px] font-[600]'>KES {dailySum}</span>
				</div>
			</div>
		</>
	);
}
