import AddIcon from '@mui/icons-material/Add';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default function HomeCard({
	color,
	text,
	value,
	percent,
	account,
	accNumber,
	name,
	dailPerc,
	dailPayPerc,
}) {
	return (
		<>
			<div
				style={{
					background: color,
				}}
				className='w-full max-h-[200px] h-[130px] max-w-full md:max-w-[265px] px-3 py-6 border border-gray-200 rounded-lg'
			>
				<p className='text-[14px] font-[600] text-white'>{text}</p>
				<div className='flex flex-col'>
					<h5 className='mb-1 text-[22px] font-[600] text-white w-full'>
						{value}
					</h5>
					{account ? (
						<>
							<div className='flex mb-1 !items-center'>
								<AccountBalanceIcon className='text-white text-[15px]' />
								<h5 className='text-[15px] font-[500] text-white w-full ml-1 mt-1'>
									{accNumber} - {name} Wallet
								</h5>
							</div>
						</>
					) : null}
					{/* {account ? null : (
						<div className='flex items-center'>
							<AddIcon className='text-white !text-[13px]' />
							<span className='text-white text-[12px]'>{percent}</span>
							<TrendingUpIcon className='text-white !text-[15px]' />
						</div>
					)} */}
				</div>
			</div>
		</>
	);
}
