import React, { useEffect, useState } from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlerts, updateAlert } from '../../features/global/globalSlice';
import { toast } from 'react-hot-toast';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

function DashNotifications() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { globalBrnId, alerts } = useSelector((state) => state.global);

	const color = '#2D68C4';
	const actColor = '#008080';

	const usrObj = {
		usrId: user?.usrId,
		brnId: globalBrnId ?? user?.brnId,
	};

	const notifications = alerts?.filter(
		(item) => item?.altType === 'NOTIFICATION'
	);
	const activities = alerts?.filter((item) => item?.altType === 'ACTIVITY');

	async function handleFetchAlerts() {
		if (user?.usrId) {
			await dispatch(fetchAlerts(usrObj));
		}
	}

	async function handleUpdateNotification(e, altObj) {
		e.preventDefault();
		const usrObj = {
			usrId: user?.usrId,
			altId: altObj?.altId,
		};
		await dispatch(updateAlert(usrObj));
		await navigate(altObj?.altUrl);
	}

	async function handleClearNotifications() {
		await alerts.forEach((item) => {
			const usrObj = {
				usrId: user?.usrId,
				altId: item?.altId,
			};
			dispatch(updateAlert(usrObj));
		});

		await handleFetchAlerts();
	}

	useEffect(() => {}, [alerts]);

	useEffect(() => {
		handleFetchAlerts();
	}, [globalBrnId, user]);

	return (
		<>
			<div className='flex items-center justify-between mb-3'>
				<h3 className='font-[600] text-[14px] text-[#1C1C1C]'>Notifications</h3>
				<button
					onClick={handleClearNotifications}
					className='border-none bg-transparent font-[500] text-[12px] text-[#1C1C1C]'
					type='button'>
					Clear All
				</button>
			</div>
			<div className='max-h-[100vh] h-fit overflow-y-auto'>
				<ol className='divide-y divider-gray-200'>
					{notifications?.length ? (
						notifications.map((item) => {
							return (
								<>
									<li>
										<Link
											onClick={(e) => handleUpdateNotification(e, item)}
											className='flex flex-col'>
											<div className='flex items-center py-2'>
												<NotificationsActiveIcon
													style={{
														color: color,
													}}
													className='mr-3'
												/>

												<div className='flex flex-col'>
													<span
														style={{
															color: color,
														}}
														className='font-[400] text-[14px]'>
														{item?.altNotification}
													</span>
													<span className='inline-flex items-center text-xs font-normal text-gray-500'>
														{moment(item?.altCreatedAt).format(
															'MMM DD YYYY, h:mm a'
														)}
													</span>
												</div>
											</div>
										</Link>
									</li>
								</>
							);
						})
					) : (
						<>
							<li>
								<Link to={''} className='flex items-center'>
									<NotificationsActiveIcon
										style={{
											color: color,
										}}
										className='mr-3'
									/>

									<span
										style={{
											color: color,
										}}
										className='font-[400] text-[14px]'>
										No notification available
									</span>
								</Link>
							</li>
						</>
					)}
				</ol>

				<div className='mt-10 mb-14'>
					<h3 className='font-[600] text-[14px] text-[#1C1C1C] mb-3'>
						Activities
					</h3>
					<ol className='divide-y divider-gray-200'>
						{activities?.length ? (
							activities?.map((item) => {
								return (
									<>
										<li>
											<Link
												onClick={(e) => handleUpdateNotification(e, item)}
												// to={item?.altUrl}
												className='flex flex-col'>
												<div className='flex py-2'>
													<TripOriginIcon
														style={{
															color: actColor,
														}}
														className='mr-3'
													/>
													<div className='flex flex-col'>
														<span
															style={{
																color: actColor,
															}}
															className='font-[400] text-[14px]'>
															{item?.altNotification}
														</span>

														<span className='inline-flex items-center text-xs font-normal'>
															{moment(item?.altCreatedAt).format(
																'MMM DD YYYY, h:mm a'
															)}
														</span>
													</div>
												</div>
											</Link>
										</li>
									</>
								);
							})
						) : (
							<>
								<li>
									<Link
										to={''}
										className='items-center block sm:flex hover:bg-gray-100 dark:hover:bg-gray-700'>
										<img
											className='!w-[35px] !h-[35px] mb-3 mr-3 rounded-full sm:mb-0'
											src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
											alt='user'
										/>
										<div className=''>
											<div className=''>
												<span
													style={{
														color: actColor,
													}}
													className='font-[400] text-[14px]'>
													No Activity
												</span>
											</div>

											<span className='inline-flex items-center text-xs font-normal text-gray-500'>
												{/* {moment(new Date()).format("LTS")} */}
											</span>
										</div>
									</Link>
								</li>
							</>
						)}
					</ol>
				</div>
			</div>
		</>
	);
}

export default DashNotifications;
