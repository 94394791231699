import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Tabs, Input, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  fetchCurrentUser,
  updateUser,
  updateUserPassword,
  verifyPassword,
} from "../../features/auth/authSlice";

function Profile() {
  const dispatch = useDispatch();
  const { user, authLoading } = useSelector((state) => state.auth);

  const [data, setData] = useState(user);
  const [passObj, setpassObj] = useState({});

  const handleChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordChange = (e) => {
    setpassObj((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  async function handleUpdateUserInfo() {
    const usrObj = {
      usrId: user?.usrId,
    };
    await dispatch(updateUser(data));
    await dispatch(fetchCurrentUser(usrObj));
  }

  async function handleUpdatePassword() {
    const usrObj = {
      usrUsername: user?.usrUsername,
      usrEncryptedPassword: passObj?.usrEncryptedPasswordOld,
    };
    const pasdData = {
      usrId: user?.usrId,
      usrEncryptedPassword: passObj?.usrEncryptedPassword,
    };

    if (!passObj?.usrEncryptedPasswordOld) {
      return message.info("Enter your old password");
    }

    if (!passObj?.usrEncryptedPassword || !passObj?.usrEncryptedPasswordAlt) {
      return message.info("Password cannot be blank");
    }
    if (passObj?.usrEncryptedPassword !== passObj?.usrEncryptedPasswordAlt) {
      return message.info("Passwords do not match");
    }
    if (passObj?.usrEncryptedPassword?.length < 6) {
      return message.info("Password should be atleast 6 characters long");
    }

    const res = await dispatch(verifyPassword(usrObj));

    console.log(res);

    if (res?.payload?.usrId) {
      const resp = await dispatch(updateUserPassword(pasdData));
      if (resp?.payload?.success) {
        message.success(
          resp?.messages?.message
            ? resp?.messages?.message
            : "Password updated successfully"
        );
      } else {
        message.error(
          resp?.messages?.message
            ? resp?.messages?.message
            : "Could not update password"
        );
      }
    } else {
      message.error("Old passwords does not match our records");
    }
  }

  useEffect(() => {}, [data, passObj, user]);


  useEffect(() => {
      
  }, []);

  return (
    <>
      <h3 className="text-[24px] text-[#1D191B] font-[600]">My Profile</h3>

      <div className="w-full h-[600px] bg-white mt-10 p-3">
        <Tabs
          defaultActiveKey={1}
          tabPosition={
            window.innerWidth >= 360 && window.innerWidth < 768 ? "top" : "left"
          }
          key={1}
          items={[
            {
              label: (
                <span className="text-[#1D191B] text-[15px] font-[600]">
                  Personal Details
                </span>
              ),
              key: 1,
              children: (
                <div className="bg-[#EFF3F6] w-full">
                  <div className="flex flex-col absolute top-[39%] left-[5%]">
                    <img
                      src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                      alt="user"
                      className="rounded-full w-[150px] h-[150px]"
                    />

                    <div className="w-fit">
                      <div className="flex items-center pb-4 border-b-2 border-[#F6F6F6]">
                        <h3 className="mt-3 text-[18px] font-[700] text-[#1D191B]">
                          {user?.usrFullNames} <br />{" "}
                          <span className="text-[#949494] text-[14px] font-[400]">
                            {Number(user?.usrRoleId) === 1 ? "Admin" : "Staff"}
                          </span>
                        </h3>
                        <EditIcon className="text-[24px] text-[#000] ml-[54px]" />
                      </div>

                      <h3 className="mt-3 text-[16px] font-[600] text-[#1D191B]">
                        You may update your photo and personal details
                      </h3>

                      <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                        <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                          First Name
                        </p>
                        <Input
                          className="bg-transparent"
                          value={data?.usrFirstName}
                          onBlur={handleUpdateUserInfo}
                          bordered={false}
                          onChange={handleChange}
                          name="usrFirstName"
                        />
                      </div>

                      <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                        <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                          Last Name
                        </p>
                        <Input
                          className="bg-transparent"
                          value={data?.usrLastName}
                          bordered={false}
                          onChange={handleChange}
                          onBlur={handleUpdateUserInfo}
                          name="usrLastName"
                        />
                      </div>

                      <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                        <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                          National ID
                        </p>
                        <Input
                          className="bg-transparent"
                          value={
                            data?.usrNationalId
                              ? data?.usrNationalId
                              : "Not set"
                          }
                          bordered={false}
                          onChange={handleChange}
                          onBlur={handleUpdateUserInfo}
                          name="usrNationalId"
                        />
                      </div>

                      <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                        <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                          Phone No
                        </p>
                        <Input
                          className="bg-transparent"
                          value={data?.usrMobileNumber}
                          bordered={false}
                          onChange={handleChange}
                          onBlur={handleUpdateUserInfo}
                          name="usrMobileNumber"
                        />
                      </div>

                      <p className="text-[12px] text-[#949494] font-[400] my-10">
                        User since:{" "}
                        {moment(user?.usrCreratedAt).format(
                          "MMM DD YYYY, h:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: (
                <span className="text-[#1D191B] text-[15px] font-[600]">
                  Password & Security
                </span>
              ),
              key: 2,
              children: (
                <>
                  <div className="w-fit">
                    <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                      <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                        Old Password
                      </p>
                      <Input.Password
                        className="bg-transparent"
                        value={passObj?.usrEncryptedPasswordOld}
                        bordered={true}
                        onChange={handlePasswordChange}
                        name="usrEncryptedPasswordOld"
                      />
                    </div>

                    <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                      <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                        New Password
                      </p>
                      <Input.Password
                        className="bg-transparent"
                        value={passObj?.usrEncryptedPassword}
                        bordered={true}
                        onChange={handlePasswordChange}
                        name="usrEncryptedPassword"
                      />
                    </div>

                    <div className="flex items-center w-full py-2 border-b-2 border-[#F5F5F5]">
                      <p className="mr-10 w-full text-[#222] font-[500] text-[14px]">
                        Confirm Password
                      </p>
                      <Input.Password
                        className="bg-transparent"
                        value={passObj?.usrEncryptedPasswordAlt}
                        bordered={true}
                        onChange={handlePasswordChange}
                        name="usrEncryptedPasswordAlt"
                      />
                    </div>

                    <div className="mt-5 flex items-center justify-between">
                      <button
                        disabled={authLoading}
                        onClick={handleUpdatePassword}
                        className="btn btn-sm bg-black text-white p-2 rounded-[6px]"
                        type="button"
                      >
                        {authLoading ? <Spin /> : "Change password"}
                      </button>

                      {/* <button
												className='btn btn-sm bg-[#f32013] text-white p-2 rounded-[6px]'
												type='button'
											>
												Delete Account
											</button> */}
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

export default Profile;
