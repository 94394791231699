import React, { useEffect, useState } from 'react';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	BarChart,
	Legend,
	Bar,
} from 'recharts';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const url = process.env.REACT_APP_API_BASE_URL;

const data = [
	{
		name: 'B1',
		uv: 4000,
		amt: 2400,
	},
	{
		name: 'B2',
		uv: 3000,
		amt: 2210,
	},
	{
		name: 'B3',
		uv: 2000,
		amt: 2290,
	},
	{
		name: 'B4',
		uv: 2780,
		amt: 2000,
	},
	{
		name: 'B5',
		uv: 1890,
		amt: 2181,
	},
];

export default function DashChart() {
	const { branches } = useSelector((state) => state.branch);
	const { user } = useSelector((state) => state.auth);

	const [brSUm, setbrSUm] = useState([]);
	const [loading, setloading] = useState(false);

	async function handleFetchBranchlySummary() {
		if (user?.usrId) {
			setloading(true);
			const fetchPromises = await branches?.map((item) =>
				fetch(
					`${url}/web/fetchMpesaIncomingSummary.action?usrId=${user?.usrId}&payBrnId=${item?.brnId}&payMethod=MPESA&period=MONTH&payType=CREDIT`
				)
					.then((res) => res.json())
					.then((data) => ({
						branch: item?.brnName,
						amount: data?.data?.result?.reduce(
							(accumulator, currentValue) =>
								accumulator + currentValue.totalAmount,
							0
						),
					}))
			);

			Promise.all(fetchPromises)
				.then((branchSums) => {
					setbrSUm(branchSums);
					setloading(false);
				})
				.catch((error) => {
					// console.error('An error occurred:', error);
					setloading(false);
				});
		}
	}

	useEffect(() => {
		handleFetchBranchlySummary();
	}, []);

	useEffect(() => {}, [brSUm, loading, user]);

	return (
		<>
			<div className='flex flex-col border-2 border-[#EBEBEB] bg-white rounded-[14px] p-4 mt-10'>
				<div className='flex items-center justify-between mb-3'>
					<h3 className='text-[14px] font-[600] text-[#0D163A]'>
						Collection by branch
					</h3>
				</div>

				{loading ? (
					<Spin size='large' className='text-6xl' />
				) : (
					<div>
						<ResponsiveContainer width='100%' height={300}>
							<BarChart
								width={380}
								height={300}
								data={brSUm}
								margin={{
									top: 20,
									right: 30,
									left: 0,
									bottom: 5,
								}}>
								<CartesianGrid strokeDasharray='3 3' />
								<XAxis dataKey='branch' />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar dataKey='amount' stackId='a' fill='#82ca9d' />
							</BarChart>
						</ResponsiveContainer>
					</div>
				)}
			</div>
		</>
	);
}
