import { Select, Tabs, message } from 'antd';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchOrganizationSysParams,
	saveOrganizationSysParams,
} from '../../features/organization/organizationSlice';
import { useEffect } from 'react';

const { Option } = Select;

export default function Config() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { orgSysParams, organizations } = useSelector((state) => state.org);
	const { globalBrnId } = useSelector((state) => state.global);

	const usrObj = {
		usrId: user?.usrId,
		brnId: globalBrnId ?? user?.brnId,
	};

	const levels = [0, 1, 2, 3];

	const org = organizations[0].orgId;

	const colParams = orgSysParams?.find((item) => item.prName === 'COLLECTION');
	const payParams = orgSysParams?.find((item) => item.prName === 'PAYMENT');

	async function handleApprovalChange(val) {
		const sysObj = {
			prId: colParams?.prId ? colParams?.prId : null,
			prApprovalLevels: val,
			prName: 'COLLECTION',
			prOrgId: org,
			prBrnId: globalBrnId ?? user?.brnId,
			prValue: String(val),
		};

		const res = await dispatch(saveOrganizationSysParams(sysObj));

		if (res?.payload?.success) {
			await handleFetchSysParams()
			message.success('Approval level updated');
		} else {
			await message.info('We cannot update approval levels at the moment');
		}
	}

	async function handleApprovalChangePay(val) {
		const sysObj = {
			prId: payParams?.prId ? payParams?.prId : null,
			prApprovalLevels: val,
			prName: 'PAYMENT',
			prOrgId: org,
			prBrnId:globalBrnId ?? user?.brnId,
			prValue: String(val),
		};
		const res = await dispatch(saveOrganizationSysParams(sysObj));

		if (res?.payload?.success) {
			await handleFetchSysParams()
			message.success('Approval level updated');

		} else {
			await message.info('We cannot update approval levels at the moment');
		}
	}

	async function handleFetchSysParams() {
		await dispatch(fetchOrganizationSysParams(usrObj));
	}

	useEffect(() => {
		handleFetchSysParams();
	}, [globalBrnId, user]);

	useEffect(() => {}, [orgSysParams]);

	return (
		<>
			<BreadCrumb
				root={'Configurations'}
				current={'Configs'}
				url={'configurations'}
			/>
			<h3 className='table-heads mt-3'>Configurations</h3>

			<div className='w-full h-[600px] bg-white mt-10 p-3'>
				<Tabs
					defaultActiveKey={1}
					tabPosition={'left'}
					key={1}
					items={[
						{
							label: (
								<span className='text-[#1D191B] text-[15px] font-[600]'>
									Collections Settings
								</span>
							),
							key: 1,
							children: (
								<>
									<div className='w-full flex flex-col'>
										<label className='mb-2' for=''>
											Approval Levels
										</label>
										<Select
											onChange={handleApprovalChange}
											value={colParams?.prApprovalLevels}
											style={{
												width: '100%',
											}}>
											{levels.map((item) => {
												return (
													<>
														<Option value={item}>
															{item >= 2 ? item + ' Levels' : item + ' Level'}
														</Option>
													</>
												);
											})}
										</Select>
									</div>
								</>
							),
						},
						{
							label: (
								<span className='text-[#1D191B] text-[15px] font-[600]'>
									Payment Settings
								</span>
							),
							key: 2,
							children: (
								<>
									<div className='w-full flex flex-col'>
										<label className='mb-2' for=''>
											Approval Levels
										</label>
										<Select
											onChange={handleApprovalChangePay}
											value={payParams?.prApprovalLevels}
											style={{
												width: '100%',
											}}>
											{levels.map((item) => {
												return (
													<>
														<Option value={item}>
															{item >= 2 ? item + ' Levels' : item + ' Level'}
														</Option>
													</>
												);
											})}
										</Select>
									</div>
								</>
							),
						},
					]}
				/>
			</div>
		</>
	);
}
