import React, { useEffect, useRef, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	InputNumber,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPaymentTransfer,
	savePayment,
} from '../../../features/payment/paymentSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';

const { Option } = Select;

const PayFundModalTwo = ({ open2, closeModalOne, prefetchData }) => {
	const dispatch = useDispatch();
	const { disbursementWallets } = useSelector((state) => state.wallet);
	const { banks, payLoading } = useSelector((state) => state.payment);
	const { user } = useSelector((state) => state.auth);

	const formRef = useRef(null);
	const contentStyle = {};

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});
	const [otpVerified, setotpVerified] = useState('');

	const usrObj = {
		usrId: user?.usrId,
		// payType: 'DISBURSEMENT',
		blkuMethod: 'DIRECT',
	};

	function handleOtpVerified(val) {
		setotpVerified(val);
	}

	const next = async () => {
		if (current === 0) {
			const otpObj = {
				usrId: user?.usrId,
			};
			if (
				data?.bkluWalCode === undefined ||
				data?.blkBeneficiaryBank === undefined ||
				data?.blkBeneficiaryAccount === undefined ||
				data?.blkBeneficiaryDetails === undefined ||
				data?.blkPaymentAmount === undefined ||
				data?.blkBeneficiaryBranch === undefined
			) {
				message.info('Please fill in the required fields');
			} else {
				const res = await dispatch(sendTransOtp(otpObj));
				if (res?.payload?.success) {
					setCurrent(current + 1);
				} else {
					message.info('We cannot complete your request at the moment');
				}
			}
		} else if (current === 1) {
			const otpObj = {
				usrId: user?.usrId,
				usrSalt: otpVerified,
			};
			if (otpVerified?.length === 4) {
				const resp = await dispatch(verifyTransOtp(otpObj));
				if (resp?.payload?.success) {
					message.success('Verification successful');
					setCurrent(current + 1);
				} else {
					message.error('Could not verify code. Please try again');
				}
			} else {
				message.info('Enter a four digit code');
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
	}

	function handleSelectChange(value) {
		setdata((prevData) => ({
			...prevData,
			bkluWalCode: String(value),
		}));
	}

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			blkBeneficiaryBank: value,
		}));
	}

	function handleAmountChange(value) {
		setdata((prevData) => ({
			...prevData,
			blkPaymentAmount: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const onFinish = async () => {
		data.blkuCreatedBy = user?.usrId;
		data.blkuType = 'BANK';
		data.blkuMethod = 'DIRECT';
		data.blkuPaymentMode = 'PESALINK';
		data.blkuTotalamount = Number(data.blkPaymentAmount);
		data.blkuFileDescription = data.blkPaymentReason;
		data.blkPaymentDesc = data.blkPaymentReason;
		data.blkReceiverMobileNo = data.blkBeneficiaryAccount;

		const res = await dispatch(savePayment(data));
		if (res?.payload?.success) {
			message.success('Transaction complete. Awaiting approval.');
			const resp = await dispatch(fetchPaymentTransfer(usrObj));
			if (resp?.payload?.success) {
				prefetchData()
			}
			await setCurrent(3);
		} else {
			message.error('Unable to complete process');
		}
	};


	let srcName = disbursementWallets?.find((item) => item?.walCode == data?.bkluWalCode);


	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<div className='mb-4'>
						<label className='mb-2 flex items-center' for='blkBeneficiaryBank'>
						<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>*</span>
							Payment Source
						</label>
						<Select
							name={'bkluWalCode'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange(e)}
						>
							{disbursementWallets?.map((item) => {
								return (
									<>
										<Option
											value={item?.walCode}
										>{`${item?.walDesc} - ${item?.walType}`}</Option>
									</>
								);
							})}
						</Select>
					</div>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'blkPaymentAmount'}
						label='Amount'
					>
						<InputNumber
							onChange={handleAmountChange}
							name={'blkPaymentAmount'}
							type='number'
							className='rounded-[8px] h-[44px] w-full'
						/>
					</Form.Item>

					<div className='mb-4'>
						<label className='mb-2' for='blkBeneficiaryBank'>
							Select Bank
						</label>
						<Select
							name={'blkBeneficiaryBank'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange2(e)}
						>
							{banks?.map((item) => {
								return (
									<>
										<Option value={item?.bnkCode}>{item?.bnkName}</Option>
									</>
								);
							})}
						</Select>
					</div>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter branch',
							},
						]}
						name={'blkBeneficiaryBranch'}
						label='Bank Branch'
					>
						<Input
							name={'blkBeneficiaryBranch'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter receiver account number',
							},
						]}
						name={'blkBeneficiaryAccount'}
						label='Account No.'
					>
						<Input
							name={'blkBeneficiaryAccount'}
							onChange={handleChange}
							type='number'
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter receiver name',
							},
						]}
						name={'blkBeneficiaryDetails'}
						label='Account Name'
					>
						<Input
							name={'blkBeneficiaryDetails'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item name={'blkPaymentReason'} label='Reason for transfer'>
						<Input
							name={'blkPaymentReason'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Second',
			content: (
				<div className='flex flex-col justify-center mt-10'>
					<p className='text-center mb-10'>
						Enter the 4 digit code sent to you
					</p>
					<OTPForm handleOtpVerified={handleOtpVerified} />
				</div>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					<p className='text-[15px] text-[#000] font-[600] mb-8'>
						Would you like to make the following transfer?
					</p>

					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Source:{' '}
						<span className='font-[400]'>
							{' '}
							{srcName?.walDesc} - {srcName?.walType}
						</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Amount:{' '}
						<span className='font-[400]'> KES {data?.blkPaymentAmount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Bank Name:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryBank}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Branch:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryBranch}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Account No:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryAccount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Account Name:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryDetails}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Reason:{' '}
						<span className='font-[400]'> {data?.blkPaymentReason}</span>
					</p>
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='h-[179px]'>
					<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
						<div className='flex items-center'>
							<TaskAltIcon className='text-[#3BBB6E]' />
							<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
								Payment Queued for approval
							</p>
						</div>
						<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
							You will receive a notification once payment is approved
						</p>
					</div>

					<div className='flex justify-center mt-10'>
						<Button
							style={{
								background: '#10101E',
								color: '#fff',
								fontWeight: 500,
								fontSize: '14px',
								marginLeft: '10px',
							}}
							onClick={() => handleDone()}
						>
							Done
						</Button>
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [data]);

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Direct Payment'
						: current === 1
						? 'Verification'
						: 'Confirm'
				}
				centered
				footer={null}
				open={open2}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => closeModalOne()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}
									>
										Previous
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}
									>
										Next
									</Button>
								)}

								{current === 2 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										type='primary'
										htmlType='submit'
									>
										{payLoading ? <Spin /> : 'Transfer'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default PayFundModalTwo;
