import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import globalReducer from '../features/global/globalSlice';
import authReducer from '../features/auth/authSlice';
import drawerReducer from '../features/drawer/drawerSlice';
import collectionReducer from '../features/collections/collectionSlice';
import reportsReducer from '../features/reports/reportsSlice';
import organizationReducer from '../features/organization/organizationSlice';
import branchReducer from '../features/branch/branchSlice';
import walletReducer from '../features/wallet/walletSlice';
import approveReducer from '../features/approve/approveSlice';
import paymentReducer from '../features/payment/paymentSlice';
import navReducer from '../features/nav/navSlice';
import receiptReducer from '../features/receipt/receiptSlice';

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
	global: globalReducer,
	drawer: drawerReducer,
	collection: collectionReducer,
	reports: reportsReducer,
	org: organizationReducer,
	branch: branchReducer,
	wallet: walletReducer,
	approve: approveReducer,
	payment: paymentReducer,
	nav: navReducer,
	receipt: receiptReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export const persistor = persistStore(store);
