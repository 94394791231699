import { Box, Button, Grid, Input, Typography } from '@mui/joy';
import {
	Checkbox,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import { MailOutline, Lock } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import login from '../../assets/img/regImg.png';
import logo from '../../assets/web.svg';
import { resetPassword } from '../../features/auth/authSlice';
import { message } from 'antd';

const SendResetPassword = () => {
	const { authLoading } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [phoneInput, setPhoneInput] = useState('');

	const handleOnChange = (e) => {
		console.log(e.target.value);
		setPhoneInput(e.target.value);
	};

	const handleLogin = () => {
		window.location.href = "./login"
	}

	const handleOnSubmitLogin = async (e) => {
		e.preventDefault();
		if (!phoneInput?.length) {
			return await toast('Enter your email address');
		}
		const usrObj = {
			usrEmail: phoneInput,
		};
		const res = await dispatch(resetPassword(usrObj));
		if (res?.payload?.success) {
			message.success(
				'Your password was reset succesfully. You will receive your login credentials shortly.',
			);
			setTimeout(() => {
				window.location.href = "./login"
			}, 3000);
		} else {
			message.error(res?.payload?.messages?.message);
		}
	};

	const backgroundImageUrl = `url(${login})`;

	return (
		<>
			<Box
				className="flex lg:justify-between"
				spacing={2}
				sx={{
					width: '100%',
					height: '100vh',
					overflowX: 'hidden',
				}}
			>
				<Box sx={{ width: '100%', bgcolor: '#fff' }}>
					<Box sx={{ mt: '30px', ml: '33px' }}>
						<Link to='/home'>
						<img className='w-[214px] h-[77px]' src={logo} alt='logo' />
						</Link>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								height: '100%',
								mt: '17%'
							}}
						>
							<Box sx={{ textAlign: 'center' }}>
								<Typography sx={{ fontWeight: 600, fontSize: '28px' }}>
									Reset Password
								</Typography>
								<Typography sx={{ mt: 2, ml: 11, width: '80%' }}>
									If your email matches records on our system you will receive a
									notifcation on steps to reset your passsword
								</Typography>
							</Box>
							<form onSubmit={(e) => handleOnSubmitLogin(e)}>
								<Box sx={{ width: '70%', mx: 'auto' }}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography
												component='h6'
												sx={{ my: '.5rem', fontWeight: 'bold' }}
											>
												Email
											</Typography>
											<OutlinedInput
												label=''
												type='email'
												name='orgName'
												value={phoneInput}
												onChange={handleOnChange}
												size='lg'
												sx={{ width: '100%' }}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton edge='end'>
															<MailOutline />{' '}
															{/* Replace this with the icon you want to use */}
														</IconButton>
													</InputAdornment>
												}
											/>
										</Grid>
										<Grid xs={6} sx={{ mx: 'auto', mt: '71px' }}>
											<Button
												// onClick={onNextHandler}
												sx={{
													// mx: '20%',
													bgcolor: 'black',
													width: '188px',
													p: 2,
													borderRadius: 0,
													'&:hover': {
														bgcolor: 'gray',
													},
												}}
												// disabled={messagePhone == 'OK'}
												type='submit'
											>
												Submit
											</Button>

											<div className='ml-[15%] mt-[19px]'>
												<button
													onClick={handleLogin}
													className='text-[#0873B9]'
												>
													Back to login
												</button>
											</div>
										</Grid>
									</Grid>
								</Box>
							</form>
						</Box>
					</Box>
				</Box>
				<Box
					className='hidden lg:flex'
					sx={{
						width: '100%',
						// height: '100vh',
						bgcolor: 'black',
						backgroundImage: backgroundImageUrl,
						backgroundSize: 'cover',
						// backgroundPosition: "center",
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div
						className='text-[#fff] p-5 ml-[5%]'
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							// alignItems: 'center',
							width: '100%',
						}}
					>
						<h1 className='text-[#fff] text-5xl font-bold mt-5'>
							Seamless payment management
						</h1>
						<p className='text-[#fff] text-[18px] mt-2'>
							The fastest way to manage payments across your <br /> departments
						</p>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default SendResetPassword;
