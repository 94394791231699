import { React, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveUser } from '../../features/auth/authSlice';

const UserInfoTable = ({ handleEditUser }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState(1);
	const dispatch = useDispatch();
	const { user, activeUser } = useSelector((state) => state.auth);
	const { branches } = useSelector((state) => state.branch);

	const activeUserBranch = branches?.find(
		(item) => item?.brnId === activeUser?.usrJbrnId,
	);

	function handleUserManagement() {
		handleEditUser(true);
	}

	return (
		<div className='dash-inner-page mt-7'>
			<section>
				<Table responsive striped className='commonTable'>
					<thead>
						<tr>
							<th>Branch Name</th>
							<th>Location</th>
							<th>Role</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{activeUserBranch?.brnName}</td>
							<td>{activeUserBranch?.brnLocation}</td>
							<td>{Number(activeUser?.usrRoleId) === 1 ? "Admin" : "Staff"}</td>
							<td>
								<div>
									<IconButton onClick={handleUserManagement}>
										<MoreHorizIcon className='text-[#000] -mt-1' />
									</IconButton>
								</div>
							</td>
						</tr>
					</tbody>
				</Table>
			</section>
		</div>
	);
};
export default UserInfoTable;
