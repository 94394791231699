import React, { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

function BranchOverview() {
    const {activeBranch} = useSelector(state => state.branch)

    console.log("activeBranch", activeBranch);

    useEffect(() => {
        
    }, [activeBranch])  


	return (
		<>
			<div className='w-full'>
				<div className='bg-[#EFF3F6] w-full h-[129px]'>
					<div className='flex flex-col absolute top-[60%] left-[5%]'>
						<img
							src='https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png'
							alt='user'
							className='rounded-full w-[150px] h-[150px]'
						/>

                        <div className='w-fit'>
                            <div className='flex items-center'>
                                <h3 className='mt-3 text-[16px] font-[700] text-[#1D191B] mb-3'>Branch Info</h3>
                                {/* <EditIcon className='text-[24px] text-[#000] ml-[54px]' /> */}
                            </div>

                            <p className='org-info-text'>{activeBranch?.brnName}</p>

                            <h3 className='mt-3 text-[16px] font-[700] text-[#1D191B] mb-3'>Address and Contact Information</h3>
                            <p className='org-info-text mb-2'>County: {activeBranch?.brnCounty}</p>
                            <p className='org-info-text mb-2'>Area: {activeBranch?.brnLocation}</p>
                            <p className='org-info-text mb-2'>Email: {activeBranch?.brnEmail}</p>
                            <p className='org-info-text mb-4'>Phone: {activeBranch?.brnMobile}</p>
{/* 
                            <h3 className='mt-3 text-[16px] font-[700] text-[#1D191B] mb-3'>Payment Information</h3>
                            <p className='org-info-text mb-2'>Bank Name: Equity</p>
                            <p className='org-info-text mb-2'>Bank Branch: Tom Mboy</p>
                            <p className='org-info-text mb-2'>Account No: 0753627822</p>
                            <p className='org-info-text mb-4'>Account Name: Bewld Main</p>

                            <h3 className='mt-3 text-[16px] font-[700] text-[#1D191B] mb-3'>Permisions Setting</h3>
                            <p className='org-info-text mb-2'>Permission 1: </p>
                            <p className='org-info-text mb-2'>Permission 2: </p> */}
                        </div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BranchOverview;
