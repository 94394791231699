import { React, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Table } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useSelector } from 'react-redux';

const COlOutgoingTable = ({ data }) => {
	const { colLoading } = useSelector((state) => state.collection);

	const [filterData, setfilterData] = useState(data);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'payApprovalTime',
			render: (item) => <span>{moment(item).format('MMM DD YYYY, h:mm A')}</span>,
			width: '180px',
		},
		{
			title: 'Source Acc',
			dataIndex: 'payPaySourceAccNo',
			sorter: {
				compare: (a, b) => a.payPaySourceAccNo - b.payPaySourceAccNo,
				multiple: 2,
			},
		},
		{
			title: 'Source Name',
			dataIndex: 'paySourceAccName',
			sorter: {
				compare: (a, b) => a.paySourceAccName - b.paySourceAccName,
				multiple: 2,
			},
		},
		{
			title: 'Beneficiary Acc',
            dataIndex: 'payBeneficiaryAccNo',
			sorter: {
				compare: (a, b) => a.payBeneficiaryAccNo - b.payBeneficiaryAccNo,
				multiple: 1,
			},
		},
		{
			title: 'Transfer Reason',
			sorter: {
				compare: (a, b) => a.payRemarks - b.payRemarks,
				multiple: 1,
			},
			render: (item) => <span>{item?.payRemarks}</span>,
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'payAmount',
			sorter: {
				compare: (a, b) => a.payAmount - b.payAmount,
				multiple: 1,
			},
		},
		{
			title: 'Approved By',
			sorter: {
				compare: (a, b) => a.username - b.username,
				multiple: 1,
			},
			render: (item) => <span>{item?.username}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'payStatus',
			sorter: {
				compare: (a, b) => a.payStatus - b.payStatus,
				multiple: 1,
			},
		},
	];


	useEffect(() => {}, [filterData]);

	useEffect(() => {
		setfilterData(data);
	}, [data]);

	return (
		<div className='dash-inner-page max-w-full w-full overflow-x-auto'>
			<section>

				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%'
					}}
					columns={columns}
					dataSource={filterData}
					loading={colLoading}
					sortDirections={['ascend']}
				/>
			</section>
		</div>
	);
};
export default COlOutgoingTable;
