import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	addUserDrawer: false,
	drawerActive: false,
};

export const drawerSlice = createSlice({
	name: 'drawer',
	initialState,
	reducers: {
		openAddUserDrawer: (state) => {
			state.addUserDrawer = true;
			state.drawerActive = true
		},
		closeDrawers: (state) => {
			state.addUserDrawer = false;
			state.drawerActive = false
		},
	},
});

export default drawerSlice.reducer;
export const { openAddUserDrawer, closeDrawers } = drawerSlice.actions;
