import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	colLoading: false,
	colTransfers: [],
	colDailySummary: [],
	colDailyIncomingSummary: [],
	colMonthlySummary: [],
	colSummary: [],
	colReceipts: [],
	colOutgoingReceipts: [],
	disbTransfers: [],
	fundTransfers: [],
};

export const fetchCollections = createAsyncThunk(
	'collectionSlice/fetchCollections',
	() => {
		return axios
			.get(`${url}/usr/fetchCollections.action`)
			.then((res) => res.data);
	},
);

export const saveCollection = createAsyncThunk(
	'collectionSlice/saveCollection',
	async (data) => {
		const res = await axios.post(`${url}/web/saveWalletTransfer.action`, data);
		return res.data;
	},
);

export const fetchCollectionTransfer = createAsyncThunk(
	'collectionSlice/fetchCollectionTransfer',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchWalletTransfer.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}&waltType=${usrObj?.waltType}`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionPayTransfer = createAsyncThunk(
	'collectionSlice/fetchCollectionPayTransfer',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchWalletTransfer.action?usrId=${usrObj?.usrId}&waltType=DISBURSEMENT`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionDailySummary = createAsyncThunk(
	'collectionSlice/fetchCollectionDailySummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchMpesaIncomingSummary.action?usrId=${usrObj?.usrId}&payMethod=MPESA&payBrnId=${usrObj?.brnId}&period=DAY&payType=CREDIT`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionDailyInComingSummary = createAsyncThunk(
	'collectionSlice/fetchCollectionDailyInComingSummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchCollectionDailyInComingSummary.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionMonthlySummary = createAsyncThunk(
	'collectionSlice/fetchCollectionMonthlySummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchMpesaIncomingSummary.action?usrId=${usrObj?.usrId}&payMethod=MPESA&payBrnId=${usrObj?.brnId}&period=MONTH&payType=CREDIT`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionBranchlySummary = createAsyncThunk(
	'collectionSlice/fetchCollectionBranchlySummary',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchCollectionBranchlySummary.action?usrId=${usrObj?.usrId}`,
			)
			.then((res) => res.data);
	},
);

export const fetchCollectionSummary = createAsyncThunk(
	'collectionSlice/fetchCollectionSummary',
	(usrObj) => {
		return axios
			.get(`${url}/web/fetchCollectionSummary.action?usrId=${usrObj?.usrId}`)
			.then((res) => res.data);
	},
);

export const fetchColIncomingReceipts = createAsyncThunk(
	'collectionSlice/fetchColIncomingReceipts',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPaymentTransferReceipt.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}&payType=CREDIT`,
			)
			.then((res) => res.data);
	},
);

export const fetchColoutgoingReceipts = createAsyncThunk(
	'collectionSlice/fetchColoutgoingReceipts',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchPaymentTransferWalletReceipt.action?usrId=${usrObj?.usrId}&brnId=${usrObj?.brnId}&payType=COLLECTION&payMethod=OUTGOING`,
			)
			.then((res) => res.data);
	},
);

export const initiateMpesaSTK = createAsyncThunk(
	'collectionSlice/initiateMpesaSTK',
	(usrObj) => {
		return axios
			.get(
				`${url}/pngp/mpesaPush.action?phone=${usrObj?.phone}&amount=${usrObj?.amount}&&accountref=${usrObj?.accountref}&`,
			)
			.then((res) => res.data);
	},
);

export const collectionSlice = createSlice({
	name: 'collection',
	initialState,
	reducers: {
		collectionCleanUp: (state) => {
			state.colLoading = false;
			state.colTransfers = [];
			state.colDailySummary = [];
			state.colDailyIncomingSummary = [];
			state.colMonthlySummary = [];
			state.colSummary = [];
			state.colReceipts = [];
			state.colOutgoingReceipts = [];
			state.disbTransfers = [];
			state.fundTransfers = [];
		},
	},

	extraReducers: (builder) => {
		builder

			.addCase(fetchCollections.fulfilled, (state) => {
				state.colLoading = true;
			})

			.addCase(saveCollection.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(saveCollection.fulfilled, (state) => {
				state.colLoading = false;
			})
			.addCase(saveCollection.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionTransfer.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionTransfer.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colTransfers = action?.payload?.data?.result?.filter(
						(item) => item.waltType === 'COLLECTION',
					);
					state.disbTransfers = action?.payload?.data?.result?.filter(
						(item) => item.waltType === 'DISBURSEMENT',
					);
				} else {
					state.colTransfers = [];
					state.disbTransfers = [];
				}
			})
			.addCase(fetchCollectionTransfer.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionDailySummary.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionDailySummary.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colDailySummary = action?.payload?.data?.result;
				} else {
					state.colDailySummary = [];
				}
			})
			.addCase(fetchCollectionDailySummary.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionDailyInComingSummary.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(
				fetchCollectionDailyInComingSummary.fulfilled,
				(state, action) => {
					state.colLoading = false;
					if (action?.payload?.success) {
						state.colDailyIncomingSummary = action?.payload?.data?.result;
					} else {
						state.colDailyIncomingSummary = [];
					}
				},
			)
			.addCase(fetchCollectionDailyInComingSummary.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionMonthlySummary.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionMonthlySummary.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colMonthlySummary = action?.payload?.data?.result;
				} else {
					state.colMonthlySummary = [];
				}
			})
			.addCase(fetchCollectionMonthlySummary.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionBranchlySummary.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionBranchlySummary.fulfilled, (state, action) => {
				state.colLoading = false;
			})
			.addCase(fetchCollectionBranchlySummary.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionSummary.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionSummary.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colSummary = action?.payload?.data?.result;
				} else {
					state.colSummary = [];
				}
			})
			.addCase(fetchCollectionSummary.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchColIncomingReceipts.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchColIncomingReceipts.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colReceipts = action?.payload?.data?.result;
				} else {
					state.colReceipts = [];
				}
			})
			.addCase(fetchColIncomingReceipts.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchColoutgoingReceipts.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchColoutgoingReceipts.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.colOutgoingReceipts = action?.payload?.data?.result;
				} else {
					state.colOutgoingReceipts = [];
				}
			})
			.addCase(fetchColoutgoingReceipts.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(initiateMpesaSTK.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(initiateMpesaSTK.fulfilled, (state, action) => {
				state.colLoading = false;
			})
			.addCase(initiateMpesaSTK.rejected, (state) => {
				state.colLoading = false;
			})

			.addCase(fetchCollectionPayTransfer.pending, (state) => {
				state.colLoading = true;
			})
			.addCase(fetchCollectionPayTransfer.fulfilled, (state, action) => {
				state.colLoading = false;
				if (action?.payload?.success) {
					state.fundTransfers = action?.payload?.data?.result;
				} else {
					state.fundTransfers = [];
				}
			})
			.addCase(fetchCollectionPayTransfer.rejected, (state) => {
				state.colLoading = false;
			});
	},
});

export default collectionSlice.reducer;
export const { collectionCleanUp } = collectionSlice.actions;
