import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import UserTable from '../../components/global/UserTable';
import { useDispatch, useSelector } from 'react-redux';
import { openAddUserDrawer } from '../../features/drawer/drawerSlice';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { Tabs } from 'antd';
import Overview from './Overview';
import { useNavigate, useParams } from 'react-router-dom';
import BranchTable from '../../components/global/BranchTable';
import {
	fetchBranches,
	removeActiveBranch,
	removeEditBranch,
} from '../../features/branch/branchSlice';

function Branches() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { branches } = useSelector((state) => state.branch);
	const { organizations } = useSelector((state) => state.org);
	const navigate = useNavigate();

	let org = organizations[0];

	async function handleBranchData() {
		const usrObj = {
			usrId: user?.usrId,
			orgId: user?.orgId,
		};
		await dispatch(fetchBranches(usrObj));
	}

	function handleActiveBranch() {
		dispatch(removeActiveBranch());
	}

	async function handleCreateBrunch() {
		await dispatch(removeEditBranch());
		await navigate('/setup/branch/create');
	}

	useEffect(() => {}, [branches]);

	useEffect(() => {
		handleBranchData();
		handleActiveBranch();
	}, []);

	return (
		<>
			<BreadCrumb
				root={'Setup'}
				current={org?.orgName}
				others={['Organizations']}
				url={'/setup/organizations'}
			/>

			<div className='flex items-center justify-between mt-3'>
				<h3 className='table-heads'>{org?.orgName}</h3>

				{Number(user?.usrRoleId) === 1 ? (
					<button
						onClick={handleCreateBrunch}
						type='button'
						className='flex items-center border-2 border-[#222] rounded-[6px] p-1 text-white bg-[#212143]'
					>
						<AddIcon />
						<span className='ml-1 text-[14px]'>Add Branch</span>
					</button>
				) : null}
			</div>

			<div className='flex flex-col h-[600px] max-h-[700px] justify-between'>
				<div className='mt-4'>
					<Tabs
						defaultActiveKey={2}
						type='card'
						size={'Middle'}
						items={[
							{
								label: (
									<span className='text-[#000] text-[13px] font-[500]'>
										Overview
									</span>
								),
								key: 1,
								children: <Overview />,
							},
							{
								label: (
									<span className='text-[#000] text-[13px] font-[500]'>
										Branches
									</span>
								),
								key: 2,
								children: <BranchTable data={branches} organization={true} />,
							},
						]}
					/>
				</div>
			</div>
		</>
	);
}

export default Branches;
