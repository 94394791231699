import moment from 'moment';
import { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillers } from '../../../features/payment/paymentSlice';

export default function PaybillPaymentsTable() {
	const dispatch = useDispatch();

	const { payLoading, billers } = useSelector((state) => state.payment);

	const [filterData, setfilterData] = useState(billers);
	const [filter, setfilter] = useState('All');

	const pending = filterData?.filter((item) => item?.blkuStatus === 'ACTIVE');
	const approved = filterData?.filter(
		(item) => item?.blkuStatus === 'APPROVED'
	);

	const onChange = (pagination, filters, sorter, extra) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		console.log('params', pagination, filters, sorter, extra);
	};

	async function handleFetch() {
		await dispatch(fetchBillers());
	}

	useEffect(() => {}, [filterData]);

	useEffect(() => {
		if (filter === 'PENDING') {
			setfilterData(pending);
		} else if (filter === 'REJECTED') {
			setfilterData(approved);
		} else {
			setfilterData(filterData);
		}
	}, [filter]);

	useEffect(() => {
		setfilterData(billers);
	}, [billers]);

	useEffect(() => {
		handleFetch();
	}, []);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'pbCreatedDate',
			render: (item) => (
				<span>{moment(item).format('MMM DD YYYY, h:mm A')}</span>
			),
			width: '180px',
		},
		{
			title: 'Type',
			dataIndex: 'pbType',
		},
		{
			title: 'Name',
			dataIndex: 'pbName',
			key: 'pbName',
		},
		{
			title: 'Till/paybill number',
			dataIndex: 'pbPaybill',
			key: 'pbPaybill',
		},
		{
			title: 'pbStatus',
			dataIndex: 'pbStatus',
		},
	];

	return (
		<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
			<section>
				<div className='flex items-center mb-4 w-full max-w-full overflow-x-auto'>
					<div
						onClick={() => setfilter('All')}
						className={
							filter === 'All'
								? 'flex items-center p-2 border-2 border-[#146ADC] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#146ADC] !text-[12px]' />
						<span className='table-filter-txt ml-2'>All</span>
					</div>

					<div
						onClick={() => setfilter('APPROVED')}
						className={
							filter === 'COMPLETE'
								? 'flex items-center p-2 border-2 border-[#2DD683] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[#2DD683] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Whitelisted</span>
					</div>

					<div
						onClick={() => setfilter('ACTIVE')}
						className={
							filter === 'PENDING'
								? 'flex items-center p-2 border-2 border-[yellow] justify-center w-[158px] h-[39px] cursor-pointer'
								: 'flex items-center p-2 justify-center w-[158px] h-[39px] cursor-pointer'
						}>
						<FiberManualRecordIcon className='text-[yellow] !text-[12px]' />
						<span className='table-filter-txt ml-2'>Pending</span>
					</div>
				</div>

				<Table
					pagination={{
						defaultPageSize: 10,
						hideOnSinglePage: true,
						pageSizeOptions: [10, 20, 50, 100],
					}}
					scroll={{
						x: '100%',
					}}
					columns={columns}
					dataSource={filterData}
					onChange={onChange}
					loading={payLoading}
				/>
			</section>
		</div>
	);
}
