import { Input, Textarea } from '@mui/joy';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
// import FormPhoneError from '../../../components/FormPhoneError';
// import { searchPhoneNumber } from '../../../features/auth/authSlice';

function OrgDetails({ updateFormData, formData }) {
	const [data, setData] = useState(formData);
	const [error, setError] = useState(false);

	const handleChange = (e) => {
		setData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const handleChangePhone = (e) => {
		setData((prevData) => ({
			...prevData,
			usrMobileNumber: e,
		}));
	};

	useEffect(() => {}, [data]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography component='h6' sx={{ my: '.5rem', fontWeight: 'bold' }}>
						Name of Administator <span className='star'></span>
					</Typography>
					<div className='flex flex-col md:flex-row lg:items-center lg:justify-between'>
						<div className='w-full'>
							<input
								className='w-full'
								placeholder='First Name'
								value={data?.usrFirstName}
								name='usrFirstName'
								type='text'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
						<div className='w-full ml-0 mt-4 lg:!mt-0 lg:ml-7'>
							<input
								className='w-full'
								placeholder='Last Name'
								value={data?.usrLastName}
								name='usrLastName'
								type='text'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
					</div>
				</Grid>

				<Grid item xs={12}>
					<div className='flex flex-col md:flex-row lg:items-center lg:justify-between'>
						<div className='flex flex-col w-full'>
							<Typography
								component='h6'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Admin Email <span className='star'></span>
							</Typography>
							<input
								className='w-full'
								value={data?.usrEmail}
								name='usrEmail'
								type='text'
								onChange={handleChange}
								onBlur={() => updateFormData(data)}
								size='lg'
							/>
						</div>
						<div className='flex flex-col w-full ml-0 mt-3 lg:!mt-0 lg:ml-7'>
							<Typography
								component='h6'
								sx={{ my: '.5rem', fontWeight: 'bold' }}
							>
								Admin Phone <span className='star'></span>
							</Typography>
							<PhoneInput
								className='!w-full'
								defaultCountry='ke'
								country={'ke'}
								required
								searchclassName='search-class'
								searchStyle={{
									margin: '0',
									width: '100% !important',
									height: '100px !important',
								}}
								enableSearchField
								disableSearchIcon
								value={data?.usrMobileNumber}
								onBlur={() => updateFormData(data)}
								onChange={handleChangePhone}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Typography
						component='h6'
						className='fmw-checkbox-label'
						sx={{ my: '.5rem', fontWeight: 'bold' }}
					>
						Password
					</Typography>
					<input
						className='w-full fmw-input-joy'
						value={data.usrEncryptedPassword}
						name='usrEncryptedPassword'
						type='password'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						size='lg'
					/>
				</Grid>

				<Grid item xs={12} sx={{ mt: 2 }}>
					<Typography sx={{ my: '.5rem', fontWeight: 'bold' }}>
						Confirm password
					</Typography>
					<input
						className='w-full fmw-input-joy'
						value={data.usrEncryptedPasswordAlt}
						name='usrEncryptedPasswordAlt'
						type='password'
						onChange={handleChange}
						onBlur={() => updateFormData(data)}
						size='lg'
						error={error}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default OrgDetails;
