import { Tabs } from 'antd';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import FundApprovalTable from '../../components/global/FundApprovalTable';

export default function FundApprovals() {
	return (
		<>
			<BreadCrumb
				root={'Approvals'}
				current={'Wallet Approval Dashboard'}
				url={'approvals'}
			/>

			<div className='flex items-center justify-between mt-3 mb-5'>
				<h3 className='table-heads'>Wallet Transfers Approval Dashboard</h3>
			</div>

			<Tabs
				defaultActiveKey={1}
				type='card'
				size={'Middle'}
				items={[
					{
						label: (
							<span className='text-[#000] text-[13px] font-[500]'>
								Pending Wallet Transfer Requests
							</span>
						),
						key: 1,
						children: <FundApprovalTable show={true} />,
					},
				]}
			/>
		</>
	);
}
